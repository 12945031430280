// ЦВЕТА И ФОН

$white: #fff;
$white-important: #fff !important;
$black: #20303c; // 1-й ЧЕРНЫЙ ЦВЕТ
$black-important: #20303c !important;
$color-twenty-three: #43515c; // 2-ой ЧЕРНЫЙ ЦВЕТ — набранный текст в input & textarea
$color-twenty-nine: #66737c; // 3-ой ЧЕРНЫЙ ЦВЕТ
$color-twenty-nine-important: #66737c !important;
$color-thirty: #858f96; // 4-ой ЧЕРНЫЙ ЦВЕТ — внешние границы судоку
$color-thirty-important: #858f96 !important;
$color-three: #a3abb0; // 5-й ЧЕРНЫЙ ЦВЕТ — серый для хлебных крошек и тегов, подменю, сводной инфе о статье, подпись под картинкой и тестом, пояснение в калькуляторах, округениях, выпадающих списках, цитатах
$color-three-important: #a3abb0 !important;
$color-four: #c2c7cb; // 6-й ЧЕРНЫЙ ЦВЕТ — border для шапки и опроса в сайдбаре, внутренние границы судоку
$color-four-important: #c2c7cb !important;
$color-six: #e0e3e5; // 7-й ЧЕРНЫЙ ЦВЕТ — серый для border тегов и пагинатора
$color-six-important: #e0e3e5 !important;
$color-nine: #eff1f2; // 8-й ЧЕРНЫЙ ЦВЕТ — серый фон для блока скачивания и цитат на страницах 404 и 500 и для опросов и реультатов теста
$color-nine-important: #eff1f2 !important;

$color-one: #65c888; // для ссылок

$color-five: #e0e3e5; // светлый серый для border, подчеркивание у h5 и цитаты в статье
$color-five-important: #e0e3e5 !important;
$color-seven: #c2c7cb; // цвет border для input и textarea

$color-eight: #e0e3e5; // разделительный border между анонсами + бордер для текстов в рубриках
$color-ten: #c2c7cb; // border для калькуляторов, конвертеров, переводчика, календаря

$color-eleven: #c2c7cb; // цвет border для выпадающих списков
$color-eleven-important: #c2c7cb !important;
$color-twelve: #c2c7cb; // разделительный border для опросов, тестов
$color-fourteen: #e0e3e5; // у таблицы горизонтальная линия
$color-fifteen: #e0e3e5; // у таблицы вертикальная линия

$color-eighteen: #f2564d; // Красный
$color-eighteen-important: #f2564d !important;
$color-nineteen: #faad4d; // Сокращенный день, звездочки в рейтингах, желтый цвет
$color-nineteen-important: #faad4d !important;
$color-twenty: #28c75d; // Зеленый
$color-twenty-important: #28c75d !important;
$color-twenty-six: #c1e4fe;  // голубой
$color-twenty-seven: #f4fafe;  // бледно-голубой
$color-thirty-one: #733ca6; // фиолетовый
$color-thirty-two: #b13dac; // розово-фиолетовый
$color-twenty-one: #459fed; // светло-синий, голубой
$color-twenty-two: #3cc7c5; // бирюзовый/cyan
$color-two: #f27052; // морковный

$color-twenty-five: #fdfdfd !important; // серый фон hover у таблиц
$color-twenty-eight: #375b74; // для затемнения экрана, для tooltip, для lightbox

// От темного к светлому (черный — серый): #20303c, #43515c, #66737c, #858f96, #a3abb0, #c2c7cb, #e0e3e5, #eff1f2


// СУДОКУ

$color-thirty-three: #fef5e9; // бледно-желтый для судоку (похожие цифры и hover)
$color-thirty-three-important: #fef5e9 !important;
$color-thirty-four: #c9f1d7; // бледно-зеленый для судоку (выделенная ячейка с цифрой или без)
$color-thirty-four-important: #c9f1d7 !important;
$color-thirty-five: #f7cfd6; // бледно-розовый для судоку (ячейка с ошибкой)
$color-thirty-five-important: #f7cfd6 !important;


// КНОПКИ

$color-sixteen: #65c888; // основной цвет кнопки, салатовый, бледно-зеленый
$color-seventeen: #c2c7cb; // серая кнопка сброса, очистки
$color-twenty-four: #e0e3e5 !important; // серая кнопка + и - у калькуляторов — уже больше нет!


// ДРУГОЕ

$underline: 1px solid #e0e3e5; // подчеркивание у ссылок
$underline-important: 1px solid #e0e3e5 !important;
$underline-dark: 1px solid #c2c7cb; // подчеркивание у ссылок на темном фоне
$dropdown-shadow: .15rem .15rem 0 0 #eff1f2; // тень у выпадающих списков 0 0 1px 0 rgba(#20303c,.2);
$btn-shadow: none; // .15rem .15rem 0 0 #eff1f2; тень у кнопок

$animation-default: .5s ease;

