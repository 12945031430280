/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  .container {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .nav__pills > li > a {
    font-size: 1rem;
    padding: 0 0 .475rem;
  }

  .header {
    &__search {
      &.search {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px
      }
    }
    &__links {
      right: 10px;
    }
    &__toggle {
      margin: 0 0 0 10px;
      cursor: pointer;
      &-line {
        display: block;
        height: 2px;
        width: 20px;
        background-color: rgba(0,0,0,.54);
        margin: 0 0 4px;
        &:last-of-type {
          margin: 0;
        }
      }
    }
    &__logo {
      margin: 2.3rem 0 0;
      width: auto;
      img, svg {
        height: 2.4rem;
      }
    }
    &__nav {
      &-sub {
        a {
          font-size: 13px;
        }
      }
    }
  }
  .content {
    padding-top: 2rem;
    &__block {
      width: calc(100% - 330px);

      .article__figure {
        width: 100%;
        &-image {
          width: 100%;
          height: auto;
          &:before {
            max-width: 750px;
          }
        }
      }
    }
  }

  body.asidemenu-opened {
    overflow: hidden;
  }

  .asidemenu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -340px;
    z-index: 102;
    width: 320px;
    background-color: #fff;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    display: block;
    &__close {
      cursor: pointer;
    }
    &.show-asidemenu {
      right: 0;
      &:before {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    &:before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#000,.9);
      position: fixed;
      z-index: 3;
      user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: all;
      -webkit-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
    &__header {
      padding: 2rem;
      margin: 0 0 2rem;
      border-bottom: 1px solid #b2b2b2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      svg {
        max-width: 80px;
        height: auto;
      }
    }
    &__content {
      height: calc(100% - 140px);
      padding: 0 0 1rem;
      overflow-y: scroll;
    }
    &__wrap {
      height: 100%;
      background: #fff;
      position: relative;
      z-index: 5;
      box-shadow: -2px 0 5px rgba(0,0,0,.54);
      ul {
        list-style-type: none;
      }
    }
    &__list {
      padding: 0 2rem;
      margin: 0;
      list-style-type: none;
      li {
        margin: 0 0 30px;
        &.has-child {
          & > a {
            &:after {
              content: "\f107";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              line-height: 1;
              font-weight: 900;
              font-size: 16px;


              display: inline-block;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0%, -50%);
            }
          }
        }
        &.is-opened {
          & > a {
            &:after {
              transform: translate(0%, -50%) rotate(90deg);
            }
          }
          & > ul {
            display: block;
          }
        }
      }
      a {
        text-transform: uppercase;
        font-size: 12px;
        color: #000000;
        position: relative;
        display: block;
        font-weight: 600;
      }
      &-inner {
        display: none;
        margin: 2rem 0 0;

        a {
          padding: 0 15px;
        }
      }
    }
  }
  .featured {
    &__post {
      width: 50%;
      &_large {
        .featured__thumb {
          min-height: 100%;
          width: 100%;
        }
      }
    }
    &__list {
      width: 50%;
    }
    &__thumb {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
  .aside {
    display: none;
  }
  .content {
    padding-top: 2rem;
    &__block {
      width: 100%;
      float: none;
    }
  }
  .featured {
    margin: 0 -15px 1rem;
    &__list {
      display: none;
    }
    &__content {
      flex-direction: column;
    }
    &__thumb {
      max-height: 205px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    &__post {
      width: 50%;
      img {
        width: 100%;
      }

      .featured__thumb {
        width: 100%;
        height: auto;
        max-height: 205px;
      }

      &_large, &.feature__post_large {
        width: 100%;
        .featured__thumb {
          width: 100%;
          max-height: 420px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .header {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__logo {
      margin: 0;
      height: auto;
      position: static;
    }
    &__links {
      position: static;
    }
    &__nav {
      margin: 0;
      position: static;
      &-sub {
        display: none;
      }
    }
    &__currency {
      display: none;
    }
  }
  .footer {
    &__container {
      flex-direction: column;
    }
    &__nav {
      margin: 0 0 15px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
  .title {
    margin: 0 0 2rem;
  }
  .comments {
    width: 100%;
  }
  .header {
    height: 5rem!important;
    &__logo {
      svg, img {
        height: 2rem;
      }
    }
    &__nav {
      &-main {
        display: none;
      }
    }
  }
  .footer {
    padding: 1.5rem 0;
    &__nav {
      a {
        font-size: 0.8rem;
      }
      &-list {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        li {
          margin: 0 1rem 0.5rem 0;
        }
      }
    }
    &__copy {
      font-size: 0.8rem;
    }
  }
  .post {
    padding: 0 0 2rem;
    &__body {
      img {
        width: 100%;
        height: auto;
      }
    }
    &__thumb {
      float: none;
      width: 100%;
      max-height: 240px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 2rem;
    }
    &__tags {
      width: 100%;
      margin: 0 0 1rem;
    }
    &__header {
      margin: 0;
      width: 100%;
    }
    &__text {
      width: 100%;
      margin: 0;
    }
    &__title {
      font-size: 1.25rem;
    }
  }
  .featured {
    &__post {
      &_large {
        .featured__post-content_title {
          font-size: 0.9rem;
          line-height: 1.3rem;
        }
      }
      &-content {
        &_title {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }
  }
  .article {
    &__intro {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
      word-break: break-all;
    }
    li {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    h3 {
      font-size: 1.3rem;
      margin: 1rem 0;
    }
    figcaption {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    blockquote {
      font-size: 1rem !important;
      line-height: 1.625rem !important;
    }
    &__figure {
      width: 100%;

      &-image {
        width: 100%;
        height: auto;
        &:before {
          max-width: 750px;
        }
      }
    }
  }
  .text {
    p {
      font-size: 1rem;
      word-break: break-all;
    }
    li {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    h3 {
      font-size: 1.3rem;
      margin: 1rem 0;
    }
  }
  .additional__title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .test {
    &__figure {
      &-image {
        width: 100%;
        height: auto;
        &:before {
          max-width: 750px;
        }
      }
    }
  }
  .calc {
    &__content {
      padding: 1rem;
    }
  }
  .converter {
    &__content {
      padding: 1rem;
      &-list {
        &_items {
          display: block;

          .converter__content-group {
            width: 100%;
          }
        }
      }
    }
  }
  .translate {
    padding: 1rem;
    &__settings {
      line-height: 1.2;
    }
  }
  .sentence {
    &_answer {
      padding: 1rem 1rem 0.1rem
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
