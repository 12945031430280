@import 'variables';

/*
************
*** BASE ***
************
*/

body {
font: 16px "Open Sans", sans-serif, Arial;
color: $black;
position: relative;
margin: 0;
font-weight: 400;
line-height: 1}

a, a:focus, a:hover, a:visited {
  color: $color-one;
  cursor: pointer;
  text-decoration: none;
  outline: 0
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem 0;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1.5rem 0;
}

blockquote {
  padding: 0 2rem;
  margin: 2rem 0;
  p {
    font-weight:400;
    margin: 1rem 0 !important;
    font-size: 1.375rem !important;
    line-height: 1.875rem !important;
    color: $color-three;
  }
  &:before, &:after {
    display: block;
    width: 50%;
    height: 1px;
    background: $color-five;
    content: '';
  }
}

hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid $color-five;
  border-bottom: 1px solid $white;
}

select {
  border:0;
  padding: 4px 8px;
  margin:0 2px;
  color: $color-three;
  background: $white;
  line-height: 1;
}

input {
  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: $color-four;
  }
}

textarea::placeholder {
  color: $color-four;
}

strong, b {
  font-weight: 600;
}

ul, ol {
  padding: 0;
  margin: 2rem 0 0 2rem;
}

li {
  line-height: 1;
  &:last-of-type {
    margin-bottom: 0;
  }
}

sup {top: -.5rem}
sub {bottom:-.5rem;}

::selection {
  background: rgba(55,91,116,0.25);
}

img {
  max-width: 100%;
}

/* Table */

table {
  border-collapse:collapse;
  margin: 2rem 0;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  box-sizing: border-box;
  text-align: left;
  border-radius: 0;

  thead {
    tr:hover {
      outline: none;
    }
  }

  td {
    padding: .75rem 1rem;
    border-bottom: 1px solid $color-fourteen;
    border-right: 1px solid $color-fifteen;
    background: $white;
    vertical-align: initial;
    &:last-of-type {
      border-right: 0;
    }
  }

  tr {
    &:hover > td:nth-child(odd),
    &:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }
  }

  th {
    background: $white;
    border-top: 1px solid $color-fourteen;
    border-bottom: 1px solid $color-fourteen;
    padding: .75rem 1rem;
    font-weight: 600;
    color: $black;
    border: 1px solid $color-fourteen;
    border-left: 0;
    &:last-of-type {
      border-right: 0;
    }
  }

  &tfoot {
    td {
      border-bottom: 0;
    }
  }
}

/*
**************
*** HEADER ***
**************
*/

/* MENU */

.nav {margin:0}

.dropdown:hover > .dropdown-menu {
display: block;
margin:0;
border: 1px solid $color-five;
z-index: 1000}

/* SUBMENU */

.open {*z-index: 1000} .open > .dropdown-menu {display:block}


.header__logo {position: absolute;
  margin: 2rem 0 0;
  width: 1100px;
}
.header__logo img {height: 3rem}

.nav__pills {
  li {
    float: left;
    list-style: none;
    &:last-child > a {
      margin-right: 0;
    }
  }
  &:before, &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
  a {
    border-bottom: none;
  }
  & > li > a {
    display: block;
    padding: 0 0 1.25rem;
    color: $black;
    margin: 0 1rem 0 0;
    font-size: 1.25rem;
  }
}

.nav__pills .dropdown__menu {
padding: .75rem 0;
top: 2.25rem;
margin: 0;
border: 1px solid $color-five;
box-shadow: $dropdown-shadow;
}

.nav__pills .dropdown__menu > li {margin:0; padding: 0;width: 100%;}
.nav__pills .dropdown__menu > li a {padding: .25rem 1rem; line-height: 1.25rem; color: $black; display: block;}

.nav__pills .dropdown__menu > li a:active,
.nav__pills .dropdown__menu > li a:visited,
.nav__pills .dropdown__menu > li a:focus {background:transparent}

.nav__pills .dropdown__menu > li:last-child a {border-bottom: none}
.nav__pills .dropdown__menu > li:last-child a:hover {border-radius: 0 0 5px 5px;}
.nav__pills .dropdown__menu > li:last-child {border-bottom: none}

.dropdown:hover > .dropdown__menu {
display: block;
margin:0;
z-index: 1000;
}

.nav__pills .open .dropdown__toggle {color: $black; background-color: $white; border-color: $white;}
.nav > li > a:hover, .nav > li > a:focus {background: $white;}


.nav__tabs {
  border-bottom: 0;
  padding-top: 0;
  border-top: none;
  font-size: .875rem;
  margin: 0;
  & > li {
    list-style: none;
    a:hover {
      background: transparent;
    }
  }
  li {
    display: inline-block;
    &.active {
      a {
        span {
          background: transparent;
          color: $color-three;
          border: 0;
          border-bottom: 1px solid transparent;
          line-height: 1.375rem;
        }
      }
    }
    a {
      span {
        line-height: 1.375rem;
        border-bottom: $underline;
        margin: 0 .75rem 0 0;
      }
    }
  }

  & > .active > a,
  & > .active > a:hover,
  & > .active > a:focus {
    color: $color-three;
    border: 0;
    background: transparent;
    cursor: default;
  }

  .nav__tab_hidden {
    display: none;
  }
}

.nav__tabs>li {margin-bottom:1rem}

.tab__content {
  position: relative;
  li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    a {
      border-bottom: $underline;
    }
  }
}

/* SUBMENU */

.dropdown {
  position: relative;
}

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;

  & > li {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
    white-space: nowrap;
  }
}

.dropdown__menu > li > a:hover,
.dropdown__menu > li > a:focus,
.dropdown__submenu:hover > a,
.dropdown__submenu:focus > a {color: $black;background:transparent}

.dropdown__menu > .active > a,
.dropdown__menu > .active > a:hover,
.dropdown__menu> .active > a:focus {
color: $black;
outline:0;
background: $white;
}

.dropdown__menu > .disabled > a,
.dropdown__menu > .disabled > a:hover,
.dropdown__menu > .disabled > a:focus {
color: $color-three;
}

.dropdown__menu > .disabled > a:hover,
.dropdown__menu > .disabled > a:focus {
text-decoration: none;
background-color: transparent;
background-image:none;
broker-filter:progid:DXImageTransform.Microsoft.gradient(enabled = false);
cursor:default;
}

.open {*z-index: 1000} .open > .dropdown__menu {display:block}
.dropdown-submenu .nav__pills .open .dropdown__toggle {color: #212529}

/*
***************
*** CONTENT ***
***************
*/

.container {
  width: 1100px;
  margin: 0 auto;
  padding: 3rem 0 0;
  position: relative;
}

.content {
  width: 100%;
  min-height: auto;
  &__block {
    float: left;
    width: 750px;
    position: relative;
  }
  &:before, &:after {
    display: table;
    line-height: 0;
    content: "";
  }
  &:after {
    clear: both;
  }
}

.content [ng-cloak] {display:block !important;}

/* AFTER TEXT */

.download {
  padding: 2rem;
  background: $color-nine;
  margin: 2rem 0;

  a {
    border-bottom: $underline-dark;
  }

  &__title {
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &__list {
    margin: 0 0 0 2rem;
  }

  li {
    list-style-type: decimal;
    margin: 0 0 1rem;
    line-height: 1.5rem;
    &:last-of-type {
      margin: 0;
    }
  }
  &_top {
    padding: 0 0 1rem;
    color: $color-three;
    font-size: 1rem;
    line-height: 1.5rem;
    border-bottom: 1px solid $color-eight;
    background: $white;
  }
  &__link {
    margin-bottom: 2.5rem;
    &-text {
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  &-page {
    &__text {
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    a {
      border-bottom: $underline;
    }
  }
}

/*
**************
*** FOOTER ***
**************
*/

.footer {
  padding: 2rem 0;
  position: relative;
  background: $white;
  margin: 10rem 0 0;
  color: $black;
  border-top: 1px solid $color-twenty-nine;
  a {
    color: $black;
  }
  &__container {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between
  }
  &__nav {
    &-list {
      list-style: none;
      float: none;
      margin: 0;
      display: flex;
      align-items: center;
    }
    li {
      margin: 0 1rem 0 0;
    }
  }
  &__copy {
  }
}

.moderation {
  position: absolute;
  right: 2.5%;
  margin: 0;
  bottom: 7rem;
  a {
    color: $color-three;
    display: block;
    margin: .5rem 0 0;
  }
}

.header {
  width: 100%;
  position: relative;
  height: 7rem !important;
  background: $white;
  border-bottom: 1px solid $color-four;
  //box-shadow: 0.2rem 0.2rem 0 0 $color-nine;
  &__container {
    height: 100%;
    position: relative;
    padding: 0;
  }
  &__logo {
    position: absolute;
    margin: 2rem 0 0;
    width: 1100px;
    height: 6rem;
    img {
      height: 3rem;
    }
  }
  &__nav {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 10.4rem;
    height: calc(100% + 1.25rem);
    &-main {
      padding: 0;
      margin: 0;
      position: relative;
    }
    &-sub {
      margin: 0;
      list-style: none;
      font-size: .875rem;
      letter-spacing: .025rem;
      text-transform: lowercase;
      li {
        float: left;
        margin-right:1rem
      }
      a {
        color: $color-three;
      }
      .dropdown__menu {
        padding: 4.6px 0 6.6px;
        margin:0;
        top:29px;
        z-index: 10000;
        min-width: inherit;
        border: 1px solid $color-five;
        box-shadow:none;
        & > li {
          margin:0;
          float: none;
          a {
            padding: 2px 10px;
            color: $black;
            &:active, &:visited, &:focus {
              background: $white;
            }
          }
          & > a {
            display: block;
          }
          &:last-child {
            border-bottom: none;
            a {
              border-bottom: none;
              &:hover {
                border-radius: 0 0 5px 5px;
              }
            }
          }
          &:first-child a {
            &:hover {
              
            }
          }
        }
      }
    }
  }
  
  &__search {
    &.search {
      display: none;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 166.4px);
      height: 100%;
      background-color: $white;
      padding: 0 0 0 1rem;
      a {
        color: $black;
        font-size: 1.25rem;
      }
      &.is-opened {
        display: block;
        &:before {
          content: '';
          //position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          //background: rgba($color-twenty-eight, .7);
          display: block;
          z-index: 1001;
        }
        .header__search-close {
          //color: $white;
        }
      }
    }
    &-form {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1002;
    }
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    &-field {
      width: 100%;
      height: 38px;
      font-size: .875rem;
      flex: 1;
      padding: 0 0 0 1rem;
      border: 1px solid $color-seven;
      color: $color-twenty-three;
    }
    &-submit {
      padding: .6875rem 1rem;
      font-size: 1rem;
      border: 0;
      color: $color-thirty-important;
      background: $white;
      position: absolute;
      top: 1px;
      cursor: pointer;
      right: 30px;
      outline: none;
    }
    &-close {
      height: 40px;
      line-height: 40px;
      margin: 0 0 0 1rem;
      display: block;
      text-align: right;
      color: $white;
    }
  }

  &__trigger {
    color: $black;
  }

  &__links {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    a {
      color: $black;
      font-size: 1.25rem;
    }
  }

  &__account {
    &-menu {
      .dropdown__menu {
        top: 3rem;
        left: initial;
        right: 0;
      }
    }
    &-trigger {
      margin: 0 1rem 0 0;
    }
  }

  &__currency {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 25px 0 0;
    &-item {
      margin: 0 0 5px;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.breadcrumbs {
  display: inline-block;
  font-size: .875rem;
  margin: 0 0 1rem;
  color: $color-three;
  &__list {
    list-style: none;
    margin: 0;
  }
  li {
    float: left;
  }
  a {
    color: $color-three;
  }
  .fa-angle-right:before {
    margin: 0 .5rem;
  }
}

.title {
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0 0 2.25rem 0;
  font-weight: 700;
}

/* ARTICLE */

.article {
  margin: 0;
  li {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 1rem 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__intro {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 0 0 2rem;
  }

  a {
    border-bottom: $underline;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    img {
      margin: 0 0 -.375rem;
    }
  }

  h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    font-weight: 400;
    &:before {
      display: block;
      width: 50%;
      height: 1px;
      background: $color-five;
      content: '';
      margin-bottom: 1rem;
    }
  }

  h6 {
    font-size: .875rem;
    line-height: 1.375rem;
    color: $color-three;
    margin: 1rem 0 0;
    font-weight: 400;
  }

  &__figure {
    margin: 0;
    width: 750px;
    position: relative;
    line-height: 0;
    &-caption {
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100%;
      //height: 100%;
      //display: flex;
      //align-items: center;
      //flex-direction: column;
      //justify-content: center;
      //text-align: center;
      //color: rgb(255, 255, 255);
      //text-shadow: 0 2px 2px $black;
      //letter-spacing: .08em;
      //z-index: 3;
      //box-sizing: border-box;
      //text-transform: uppercase;
      //font-size: 1.5rem;
      //line-height: 2rem;
      //padding: 2rem;
      line-height: 1.375rem;
      font-size: .875rem;
      padding: 0;
      margin: 1rem 0 0;
      color: $color-three;
      span {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: 300;
      }
    }
    &-image {
      display: inline-block;
      //background: -webkit-linear-gradient(top right, #65c888, #733ca6);
      position: relative;
      width: 750px;
      height: 422px;
      &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(#000, .4);
        color: $white;
        padding: .2em;
        font-size: 14px;
      }
      img {
        border: 0;
        //mix-blend-mode: luminosity;
      }
    }

    &.test__figure {
      &:before {
        display: none;
      }
    }
  }
}

.article table {
border-collapse:collapse;
width: 750px;
font-size: 1rem;
z-index: 900000;
position: relative;
display: block;
overflow: auto;
}

.article table ul {margin: 1rem 0 1rem 2rem;}
.article table li {font-size: 1rem; line-height: 1.5rem; margin: .5rem 0;}

.article__info .tags {
  margin: .5rem 0 0;
}

.article__info {
  color: $color-three;
  padding: 1rem 0 0;
  margin: 2rem 0;
  border-top: 1px solid $color-eight;

  a {
    border-bottom: $underline;
  }

  &_franchises, &.article__info_franchises {
    padding: 0 0 1rem;
    border-bottom: 1px solid $color-eight;
    border-top: none;
    .tags {
      margin: .5rem 0 0;
    }
  }

  &-list {
    margin: 0;
    list-style: none;
  }

  li {
    margin: 0 0 .5rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    &:last-of-type {
      margin: 0;
    }
  }

  &_top {
    .article__info-list {
      margin: 0;
      list-style: none;
    }
  }
  &_bottom {
    margin: 2rem 0 2.5rem;
  }
  &-small {
    text-transform: lowercase;
  }
  &-tags {
    display: flex;
    align-items: flex-start;

    i {
      margin: .25rem .5rem 0 0;
    }

    &_list {
      margin: 0;
      text-transform: lowercase;
      li {
        display: inline-block;
        margin: 0 !important;
      }
    }
  }
}

.tooltip {
position: absolute;
z-index:99999;
display: block;
font-family: "Open Sans", Arial, sans-serif;
font-style:normal;
font-weight:normal;
letter-spacing:normal;
line-break:auto;
text-align: left;
text-align:start;
text-decoration: none;
text-shadow:none;
text-transform:none;
white-space:normal;
word-break:normal;
word-spacing:normal;
font-size: 1rem;
line-height: 1.5rem;
word-wrap:break-word;
opacity:0}

.tooltip.in {opacity:1}
.tooltip-inner {
  max-width: 250px;
  padding: 1rem;
  color: $white;
  font-weight: 400;
  text-align: left;
  background: rgba($color-twenty-eight, .8);
  margin:0;
}

 /*
.tooltip-inner:before {
 width:0;
 height: 0;
 border-left: 6px solid transparent;
 border-right: 6px solid transparent;
 border-top: 6px solid $color-three;
 content: '';
 position:absolute;
 left: 50%;
 bottom: 4px;
 margin: 0 0 0 -6px;
 }
 
.dropdown-menu:before {
 width:0;
 height: 0;
 border-left: 6px solid transparent;
 border-right: 6px solid transparent;
 border-bottom: 6px solid #e8eaeb;
 content: '';
 position:absolute;
 left: 20%;
 top: -6px;
 }

 .nav-pills .dropdown:last-of-type .dropdown-menu:before {
 left: 80%;
 }

.ns-popover-list-theme:before {
 width:0;
 height: 0;
 border-left: 5px solid transparent;
 border-right: 5px solid transparent;
 border-bottom: 5px solid #e8eaeb;
 content: '';
 position:absolute;
 left: 20%;
 top: -6px;
 }*/

.additional {
  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2rem 0;
    font-weight: 600;
  }
}

/* ASIDE */

.aside {
  float: right;
  width: 300px;

  &__widget {
    margin: 0 0 2.5rem
  }

  .aside__content {
    margin: -1rem 0 0;
  }
}

.asidemenu {display: none;}

.search {
  position: relative;
  right: 0;
  top: 0;
  margin: -1rem 0 2.25rem;

  &__field {
    font-family: "Open Sans";
    width: 300px;
    height: 2.5rem;
    box-sizing: border-box;
    background: $white;
    border: 1px solid $color-seven;
    padding: .25rem 2.5rem .25rem 1rem;
    color: $black;
    font-size: .875rem;
    &:focus {
      background: $white;
      cursor: text;
      outline: 0;
    }
  }

  &__submit {
    padding: .563rem 1rem;
    font-size: 1rem;
    margin: 0;
    background: none;
    color: $color-three-important;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none !important;
  }
}

.category {
  &__text {
    margin: 0 0 2.5rem;
    padding: 2rem 2rem .5rem;
    border: 1px solid $color-six;
    
    li {
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.625rem
    }
    p {
      margin: 0 0 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem
    }
    a {
      border-bottom: $underline;
    }
  }

  &__tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem;

    a {
      color: $color-three-important; 
      margin-right: .25rem;
    }
  }
}

.post__sticky {
  margin: 0 0 2.5rem;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #edeff0;
  padding: 0 0 2.5rem;
  &-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: block;
  }
  a {
    color: $black;
    border-bottom: 1px solid $color-six;
  }

  &-body {
    p {
      font-size: .875rem;
      line-height: 1.375rem;
      margin: 0 0 0 282px
    }
    a {
      color: $black;
    }
    img {
      float: left;
      margin: 0 2rem 0 0;
      width: 250px;
    }
  }

  &-text {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px;
  }
}

.post {
  margin: 0 0 2.5rem;
  width: 100%;
  position: relative;
  border-bottom: 1px solid $color-eight;
  padding: 0 0 2.5rem;

  &__header {
    width: calc(100% - 282px);
    margin: 0 0 0 282px;
  }

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: block;
    font-weight: 600;
  }

  &__tags {
    margin: 0 0 .5rem 282px;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.375rem;
    font-size: .875rem;
    a {
      color: $color-three-important; 
      margin: 0 .25rem 0 0;
    }
  }

  a {
    color: $black;
  }

  &__body, &__body_doc {
    p {
      font-size: .875rem;
      line-height: 1.375rem;
      margin:0 0 0 282px
    }
    img {
      width: 250px;
    }
    &_doc {
      p {
        margin: 0;
      }
      .post__header {
        width: 100%;
        margin: 0;
      }
      .post__text {
        margin: 0;
      }
      .post__tags {
        margin: 0 0 .5rem;
      }
    }

    &_test {
       a {
         border-bottom: none;
       }
    }
  }

  &__thumb {
    float: left;
    margin: 0 2rem 0 0;
    width: 250px;
    line-height: 0;
    //background: -webkit-linear-gradient(top right, #65c888, #733ca6);
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: rgba(0, 0, 0, .4);
      color: $white;
    }

    img {
      //mix-blend-mode: luminosity;
      padding: 0;
    }
  }

  &__text {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px;
  }

  &__list {
    margin: 1rem 0 0 297px;
    font-size: .875rem;

    li {
      line-height: 1;
    }
  }

  &_index {
    &:last-of-type {
      margin: 0;
    }
  }
}

.survey {
  &__results {
    margin: 0;

    &.has-selected {
      .survey__total {
        padding: 0 1rem;
      }
      &.survey__results_finish {
        .survey__option {
          cursor: default;
        }
      }
    }
  }

  &__total {
    border-bottom: 1px solid $color-twelve;
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: 100%;
    &:first-of-type {
      border-top: 1px solid $color-twelve;
    }
  }

  &__rate {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba($color-twenty-two, .2);
    &.full {
      width: 100%;
      right: 0;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;
    cursor: pointer;
    font-weight: 400;
    box-sizing: content-box;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .75rem 0;
    &_finish {
      cursor: default;
    }
  }

  &__percent {
    font-size: 1rem;
    margin: 0 0 0 1rem;
  }

  &__info {
    font-size: .875rem;
    margin: 1rem 0 0;
    &-text {
      line-height: 1.375rem;
      padding: 0;
      margin: 0;
      color: $color-three;
    }
  }

  &__wrap {
    padding: 2rem;
    border: 1px solid $color-four;
    font-weight: 300;
    .survey__info {
      text-align: right;
      line-height: 1.375rem;
      a {
        display: block;
      }
    }

    .survey__title_small {
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin: 0 0 1.375rem;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &.has-selected {
      .survey__list-item_content {
        padding: .5rem 0 .5rem .5rem;
      }
    }

    &-archive {
      margin: 0;
      list-style: none;
      li {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 2.5rem;
        border-bottom: 1px solid $color-eight;
        border-top: 0 !important;
        padding: 0 0 2.5rem;
        font-weight: 600;
      }

      a {
        color: $black;
      }

      .tags {
        margin-bottom: .5rem;
        overflow: hidden;
        text-transform: lowercase;
        line-height: 1.5rem;
        font-size: .875rem;
      }

      .tags a {
        color: $color-three-important;
        margin-right: .25rem
      }
    }

    &-item {
      font-size: .875rem;
      line-height: 1.375rem;
      box-sizing: content-box;
      cursor: pointer;
      border-bottom: 1px solid $color-twelve;
      position: relative;
      &:first-of-type {
        border-top: 1px solid $color-twelve;
      }
      .total {
        height: 100%;
      }
      &_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        height: 100%;
        padding: .5rem 0;
      }
      &_percent {
        margin: 0 .5rem 0 1rem;
      }
      &_rate {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: rgba($color-twenty-two, .2);
      }
      &_name {
        flex: 1;
      }

      &_name, &_percent {
        position: relative;
      }
    }
  }

  &__tmp {
    .survey__option {
    }
    .survey__percent {
      margin: 0 0 0 1rem;
    }
  }
}

.tags {
  list-style: none;
  margin: 0 0 2.25rem;
  li {
    display: inline
  }
  a {
    display: inline-block;
    color: $color-three;
    font-size: .875rem;
    padding: .5rem;
    margin: 0 0 .25rem;
    border: 1px solid $color-six;
  }
}

/* PAGINATOR */

.paginator {
  list-style: none;
  margin: 0 0 -.125rem;
  font-size: .875rem;
  display: inline-block;
  li {
    float: left;
  }
  a, a:hover, a:visited {
    display: block;
    color: $black;
    padding: .5rem .75rem;
    border: 1px solid transparent;
  }
  .current {
    color: $color-three;
    padding: .5rem;
    border: 1px solid $color-six;
  }
  .dots {
    color: $black;
    border: 1px solid transparent;
  }
}

/* FEATURED INDEX */

.featured {
  margin: 0 0 3rem;
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__post {
    position: relative;
    &:nth-child(3), &:nth-child(4) {
      margin-bottom: 0;
    }
    &_large {
      margin: 0;
      .featured__thumb {
        width: 550px;
        height: 310px;
      }
      .featured__post-content_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 2rem;
      }
    }
    &-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      color: $white;
      &_date {
        margin: 0 2rem 2rem;
        font-size: .875rem;
      }
      &_title {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 1rem;
        font-weight: 600;
        text-shadow: 0 2px 2px rgba(0,0,0,.5);
      }
    }

    &-link {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }

  &__thumb {
    width: 275px;
    height: 155px;
    overflow: hidden;
    //background: -webkit-linear-gradient(top right, #65c888, #733ca6);
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
      box-sizing: border-box;
      z-index: 2;
    }

    img {
      max-width: 100%;
      //mix-blend-mode: luminosity;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.img__desc {
  padding: 0;
  float: left;
  margin: 0 25px 8px 0;
  width: 300px;
  border-radius: 0
}

.test {
  &__btn {
    margin: 2.5rem 0 0;
    padding: 2.5rem 0 0;
    border-top: 1px solid $color-eight;
    text-align: center;
    .btn {
      padding: .6875rem 1rem;
      text-shadow: none;
      line-height: 1;
      box-shadow: none;
    }
  }
  a {
    border-bottom: $underline;
  }

  &__header {
    margin: 0;
    line-height: 0;
    img {
      margin: 0 0 2rem;
    }
  }

  &__description {
    p {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin: 2rem 0 0;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 2rem;
  }

  &__subtitle {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2rem 0;
    font-weight: 600;
  }

  &__list {
    margin: 0 0 1rem;
    font-size: .875rem;
    list-style-type: none;
    &_archive {
    }
  }

  &__total {
    //border-bottom: 1px solid #edeff0;
  }

  &__rate {
    //height: 2.375rem;
    width: 0;
    //background: $color-nine;
  }

  &__option {
    //position: absolute;
    //padding: 0 .5rem;
    //line-height: 2.375rem;
    color: $color-three;
    display: flex;
  }

  &__form {
    &-list {
      margin: 0 0 2rem;
      list-style: none;
      li {
        padding: 0 1rem;
        border-top: 1px solid $color-twelve;
        font-size: 1rem;
        line-height: 1.5rem;
        &:last-of-type {
          border-bottom: 1px solid $color-twelve;
        }
        label {
          padding: .75rem 0 .75rem 1.5rem !important;
        }
        .radio:not(checked) + label:before {
          top: 1rem;
        }
        .radio:not(checked) + label:after {
          top: 1rem;
        }
      }
    }
  }

  &__figure {
    display: block;
    margin: 0;
    line-height: 0;
    &:before {
      display: none;
    }
    &-caption {
      line-height: 1.375rem;
      font-size: .875rem;
      padding: 0;
      margin: 1rem 0 0;
      color: $color-three;
    }
    &-image {
      display: inline-block;
      //background: -webkit-linear-gradient(top right, #65c888, #733ca6);
      position: relative;
      width: 750px;
      height: 422px;
      &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, .4);
        color: $white;
      }
      img {
        margin: 0;
        //mix-blend-mode: luminosity;
      }
    }
  }

  //figcaption {
    //position: absolute;
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
    //display: flex;
    //align-items: center;
    //flex-direction: column;
    //justify-content: center;
    //text-align: center;
    //color: rgb(255, 255, 255);
    //text-shadow: 0 2px 2px $black;
    //letter-spacing: .08em;
    //z-index: 3;
    //box-sizing: border-box;
    //text-transform: uppercase;
    //font-size: 1.5rem;
    //line-height: 2rem;
    //padding: 2rem;
  //}

  .survey__results_finish {
    margin: 0 0 2.5rem;
  }
}

/* RELAP */

.relap-default__item-thumbnail {
  //background: -webkit-linear-gradient(top right, #65c888, #733ca6);
  position: relative;
  display: block;

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, .4);
    color: $white;
    padding: .2em;
  }

  img {
    border-radius: 0 !important;
    float: inherit;
    //mix-blend-mode: luminosity;
    width: 100%;
  }
}

.relap-default__view-strings.relap-default__with-brand .relap-default__items-container {padding-bottom:0 !important}
.relap-default__top-container.relap-default__view-strings {padding-top:0 !important}
.relap-default__top-container {padding: 0 !important}
.relap-default__view-strings .relap-default__item, .relap-default__view-strings .relap-default__fake-item {min-width: 290px !important}
.relap-default__logo, .relap-default__logo__icon svg {display:none !important}
.relap-default__view-strings .relap-default__item-thumbnail {margin:5px 20px 0 0 !important}
.relap-default__full-item-link .relap-default__item-inner:hover {background: none !important}

.relap-default__item-title {color: $black-important;}

.search {
  &-results {
    margin: 0 0 2.5rem;
    position: relative;

    &__field {
      font-family: "Open Sans";
      width: 100%;
      box-sizing: border-box;
      height: 2.5rem;
      background: $white;
      border: 1px solid $color-seven;
      padding: .25rem 2.5rem .25rem 1rem;
      color: $color-twenty-three;
      font-size: .875rem;
      text-overflow: ellipsis;
    }

    .submit {
      padding: .6875rem 1rem;
      font-size: 1rem;
      border: 0;
      background: none;
      color: $color-thirty-important;
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      outline: none;
    }

    &__group {
      position: relative;
    }

    &__list {
      padding: 0;
      margin: 0;
      &-item {
        margin: 2.5rem 0;
        border-bottom: 1px solid $color-eight;
        padding: 0 0 2.5rem;
        font-size: .875rem;
        line-height: 1.375rem;
      }
      hlword {
        font-weight: 600;
        background: rgba($color-twenty-two, .2);
      }

      &-title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 1.5rem;
        display: block;
        font-weight: 600;

        a, a:hover, a:visited {
          color: $black;
        }
  
        hlword {
          font-weight: 600;
          background: none;
        }
      }

      &-date {
        margin-top: .5rem;
        overflow: hidden;
        line-height: 1.375rem;
        font-size: .875rem;
        color: $color-three; 
      }
    }
  }

  &__submit {
    padding: .563rem 1rem;
    font-size: 1rem;
    margin: 0;
    background: none;
    color: $color-three-important;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none !important;
  }
}

.error {
  font-size: 1rem;
  line-height: 1.5rem;
  &-page {
    &__text {
      line-height: 1.5rem;
  a {
    border-bottom: $underline;
  }
    }
  }
}

.sentence {
  margin: 0 0 2.5rem;
  border-bottom: 1px solid $color-eight;
  padding: 0 0 2.5rem;

  a {
    border-bottom: $underline;
  }

  &__tags {
    color: $color-three;
    line-height: 1.375rem;
    font-size: .875rem;
    text-transform: lowercase;
    &_top {
      margin: 1rem 0 0;
    }
    &_dark {
      text-transform: initial;
      a {
        border-bottom: $underline-dark;
        text-transform: lowercase;
      }
    }
  }

  &__body {
    p {
      font-size: 1rem;
      margin: 0 0 1rem;
      line-height: 1.5rem;
    }
    ul, ol {
      margin: 0 0 1rem 2rem;
    }
    li {
      margin: 0 0 .5rem;
      line-height: 1.5rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__answer {
    background: $color-nine;
    padding: 2rem 2rem 1rem;
    margin: 1.5rem 0 0;
    .sentence__body {
      display: flex;
      .far {
        line-height: 1.5rem;
      }
    }
  }

  &__show-answer {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 -.5rem;
    a {
      border-bottom: 0;
      color: $color-three;
      span {
        border-bottom: $underline-important;
        color: $color-one;
      }
    }
  }

  &__author {
    margin: 0 0 .5rem;
    color: $color-three;
    line-height: 1.375rem;
    font-size: .875rem;
  }

  &__quote {
    margin: 2.5rem 0 0;
    border: 0;
    padding: 2rem;
    background: $color-nine;
    p {
      margin: 0;
    }
    .sentence__author {
      margin: 1rem 0 0;
    }
    a {
      border-bottom: $underline-dark;
    }
  }
}


.calc {
  border: none;
  padding: 0;
  margin: 0 0 2.5rem;
  a {
    border-bottom: $underline;
  }

  .popover {
    color: $color-one;
    border-bottom: .75rem solid transparent;
  }

  &__content {
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid $color-ten;
    padding: 1rem 2rem;
    &-dash {
      margin: 0 .25rem 0 0;
      &.calc__content-dash_medium, &_medium {
        margin: 0 .5rem 0 0;
      }
      &.calc__content-dash_large, &_large {
        margin: 0 .75rem 0 .5rem;
      }
    }

    &-title {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin: 1rem 0;
      font-weight: 600
    }

    &-popover {
      color: $color-sixteen;
      border-bottom: .5rem solid transparent;
      display: initial;
    }

    &-col {
      &_medium {
        input, .calc__content-group_input {
          width: 75px;
        }
      }
    }

    &-group {
      padding: 1rem 0;
      .checkbox:not(checked) + label:before {
        top: 0;
      }
      .checkbox:not(checked) + label:after {
        top: 0;
      }
      &_line_height {
        line-height: 0;
      }

      &_change {
        position: absolute;
        right: 70%;
      }
      &_textarea {
        position: relative;
        z-index: 10;
        min-height: 10rem !important;
        margin: 0;
        width: 100% !important;
        box-sizing: border-box;
        font-family: "Open Sans";
        font-weight: 400;
        border: 1px solid $color-seven;
        outline: 0;
        padding: 1rem;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $color-twenty-three;
        &_small {
          min-height: 5rem !important;
        }
      }
      &_img, &.calc__content-group_img {
        vertical-align: top;
        &:first-of-type {
          margin: 0 2rem 0 0;
        }
        &:nth-child(2) {
          margin: 0 2rem 0 0;
        }
        &:nth-child(3) {
          margin: 2rem 2rem 0 0;
        }
        &:nth-child(4) {
          margin: 2rem 2rem 0 0;
        }
      }

      &_settings, &.calc__content-group_settings {
        font-size: .875rem;
        color: $color-three;
      }

      &_bottom_small, &.calc__content-group_bottom_small {
        padding: 1rem 0 .5rem;
      }
      &_top, &.calc__content-group_top {
        padding: 1rem 0 0;
        &_large {
          padding: 2rem 0 0;
        }
      }
      &_bottom, &.calc__content-group_bottom {
        padding: 0 0 1rem;
      }

      &_commision, &.calc__content-group_commision {
        padding: 0;
        margin: 1rem 0;
      }

      &_checkbox, &.calc__content-group_checkbox {
        input {
          border: 1px solid $color-five;
          padding: 4px 8px;
          font-size: .875rem;
          line-height: 1;
          color: $color-three;
          font-family: "Open Sans";
          margin: 0 2px;
        }
      }

      &_error {
      }
      
      &_compare {
        margin: -1rem 0;
        padding: 0;
      }

      &_capitalize {
        text-transform: capitalize;
      }

      &_input {
        border: 1px solid $color-seven;
        margin: 0 .25rem 0 0;
        font-family:"Open Sans";
        font-size: .875rem;
        height: 30px;
        width: 10rem;
        padding: .25rem 1rem;
        z-index: 1000;
        color: $color-twenty-three;

        &.calc__content-group_input_medium, &_medium {
          width: 75px;
        }
        &.calc__content-group_input_short, &_short {
          width: 5rem;
        }
        &_url {
          width: 100%;
          box-sizing: border-box;
          height: 40px;
        }
      }

      &_title {
        font-weight: 600;
        margin-bottom: 1rem;
        display: block;
        &_bottom {
          margin-bottom: .5rem;
          border-bottom: .5rem solid transparent;
        }
      }

      &_subtitle {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin: 1rem 0;
        font-style: italic;
        font-weight: 600;
      }

      &_description {
        display: block;
        padding: 0 0 1rem;
        margin-top: -.375rem;
        color: $color-three;
        font-size: .875rem;
        line-height: 1.375rem;
      }
    }

    &-results {
      margin: 2rem 0 1rem;
      &_line_height {
        line-height: 0;
      }

      &_top_small, &.calc__content-results_top_small {
        margin: 1rem 0 !important;
      }

      &_title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 600;
        margin: 0 0 1.5rem;
      }

      &_subtitle {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin: 1rem 0;
        font-style: italic;
        font-weight: 600
      }

      &_list {
        margin: 0;
        list-style: none;
        li {
          margin: .5rem 0 0;
          line-height: 1.5rem;
        }

        &.calc__content-results_reset, &_reset {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            &:first-of-type {
              margin-top: 5px;
            }
            &:last-of-type {
              margin-bottom: 0;
              input {
                margin-bottom: 0;
              }
            }
          }
        }

        &.calc__content-results_disc, &_disc {
          list-style: disc !important;
          margin: 1rem 0 0 2rem !important;
        }
      }

      &_small, &.calc__content-results_list_small {
        margin:1rem 0 !important
      }
    }

    &-list {
      margin: 0;
      list-style: none;
      .radio:not(checked) + label:before {
        margin-top: 0;
      }
      .radio:not(checked) + label:after {
        margin-top: 0;
      }

      &_reset {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          &:first-of-type {
            margin-bottom: 1rem;
          }
          &:last-of-type {
            margin-bottom: 0;
            input {
              margin-bottom: 0;
            }
          }
        }
        &_top, &.calc__content-list_reset_top {
          li:first-of-type {
            margin-top: 0;
          }
        }
        &_all, &.calc__content-list_reset_all {
          margin: 0;
        }
      }

      &_align {
        display: flex;
        flex-wrap: wrap;

        .calc__content-dash {
          margin: 0 .5rem 0 0;
        }
        li:first-of-type {
          margin: 0 0 .5rem;
        }
        li {
          margin: 0 0 .5rem;
        }
      }

      &_row {
        display: flex;
        input {
          display: block;
          margin: 0 .25rem 0 0;
        }
        .calc__content-group {
          margin: 0 .75rem 0 0;
          &_title {
            border-bottom: none;
            &_bottom {
              margin-bottom: .5rem;
              border-bottom: .5rem solid transparent;
            }
          }
          &:last-of-type {
            input {
              display: inline-block;
            }
          }
        }
        .commission {
          input {
            display: inline-block;
          }
        }
      }
    }

    &-table {
      &_both {
        margin: 1rem 0;
      }
      &_margin_bottom {
        margin-bottom: 0;
      }
      .radio:not(checked) + label:before {
        margin-top: 0;
      }
      .radio:not(checked) + label:after {
        margin-top: 0;
      }
    }

    .btn {
      padding: .5rem 1rem;
      font-size: 1rem;
      vertical-align: top;
      margin: 0 .75rem 0 0;
      border-bottom: 1px solid;
      &:focus {
        outline: none;
      }
    }
  }

  &__list {
    margin: 0;
    list-style-type: none;
    &-body {
      border-bottom: 1px solid #edeff0;
      padding-bottom: 2.5rem;
      margin: 0 0 2.5rem;
      &_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0;
        padding: 0;
        a {
          color: $black;
        }
      }
    }
  }

  &__tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem;

    a {
      color: $color-three; 
      margin-right: .25rem;
    }
  }
}

/* CALC 15 & 16 */

.inputDisplay {
border: 1px solid $color-five-important;
min-height: 10rem !important;
margin: 0 0 2rem !important;
padding: 1rem !important;
color: $black-important;
}

.calcBtn {
width: 116.8px !important;
margin: 0 1rem 1rem 0 !important;
font-size: 1rem !important;
}

.calcBtn:active {top: 0 !important}
.calcBtn:nth-child(6n) {
margin: 0 0 1rem 0 !important;
}

.calc .enterBtn {
 height: 96px;
  margin: 0 0 -40px 0 !important;
}

.calc .gray, .calc .red {
 color: $black-important;
 border: 1px solid #eff3f6 !important;
 background: $white;
}

.calc .white {
 color: $black-important;
 border: 1px solid #edeff0 !important;
}

.calculator {
 margin-bottom: -1rem !important;
}

.outOld {
 padding: .5rem 0 !important;
 margin-bottom: .5rem !important;
 border-bottom: 1px solid #edeff0 !important;
}
.inOld {padding-top: 0 !important;}
.mathquill-rendered-math {font-size: 1rem !important;}
.lineId { color: $color-sixteen;}

.calcBtn[disabled] {opacity: .1 !important;}

.btn {
  text-align: center;
  font-family: "Open Sans";
  cursor: pointer;
  border: 1px solid $color-sixteen;
  color: $color-sixteen;
  background: $white;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: top;
  margin:0 1rem 0 0;
  display: inline-block;
  outline:0;
  box-shadow: $btn-shadow;
  &.btn_fire {
    font-size: 1rem;
  }
  &.btn_clean {
    color: $color-three-important;
    padding: .5rem 0 0;
    border: 0;
    font-size: 1rem;
    box-shadow: none;
    &:hover, &:focus {
      background: $white;
    }
  }
  &_org, &.btn_org {
    border: 1px solid;
    padding: .4375rem 1rem;
    margin-top: 1rem;
    margin-right: .75rem;
    display: inline-block;
    &:hover {
      //background: $color-sixteen;
    }
  }
  &.btn_auto {
    width: auto;
  }
  &_left_small {
    margin: 0 !important;
    margin-left: .25rem !important;
    background: $white-important;
    color: $black-important;
    box-shadow: none;
    padding: 0 !important;
    border: 0 !important;
    letter-spacing: 0;
    &:hover {
      background: $white-important;
    }
  }
  &_right_small {
    margin-right: -.25rem !important;
    background: $white-important;
    color: $black-important;
    margin-left: .5rem !important;
    box-shadow: none;
    line-height: 40px;
    padding: 0 !important;
    border: 0 !important;
    letter-spacing: 0;
    &:hover {
      background: $white-important;
    }
  }
  &_top_large {
    padding: .6875rem 1rem;
  }
}

.stars {
  color: $color-nineteen;
}

.star {
  &_names {
    color: $white;
    margin: 0 0 0 .25rem;
    font-weight: 300;
    text-transform: lowercase;
    font-size: .875rem;
    line-height: .875rem
  }
  &_empty {
    color: $color-four-important;
  }
  &__name {
    display: none;
    &.active {
      display: inline-block;
    }
  }
}

// calc content table

.calc {
  &__content {
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-nine;
    }

    &-table_top {
      margin: 2rem 0 1rem !important;
    }

    &-table_top_small {
      margin: 1rem 0 !important;
    }

    &-results_top_small {
      margin: 1rem 0 !important;
    }

    &-table {
      padding: 0;
      line-height: 1.5rem;

      &_border, &.calc__content-table_border {
        margin: 0 !important;
        width: 0;

        th {
          border: 0 !important;
          padding: 0 0 1.5rem;
          line-height: 0;
        }
        td {
          padding: 0;
          border-bottom: 0;
          border-right: 0;
        }
        tr:last-of-type td {
          border-bottom: 0 !important;
        }
        tr:last-of-type td input {
          margin: 0 !important;
        }
        input {
          margin: 0 1rem 1rem 0 !important;
        }
        tr:hover > td:nth-child(odd), 
        tr:hover > td:nth-child(even) {
          background: $white-important;
        }
      }
      &_hover, &.calc__content-table_hover {
        margin: 0 0 2rem !important;
        tr:first-of-type td {
          border-top: 1px solid #edeff0;
        }
        tr:hover > td:nth-child(odd), 
        tr:hover > td:nth-child(even) {
          background: $white-important;
        }
      }
      &_border_bottom, &.calc__content-table_border_bottom {
        tr:last-of-type td {
          border-bottom: 0 !important
        }
        tr:last-of-type td:first-of-type {
          border-radius: 0 0 0 3px
        }
        tr:last-of-type td:last-of-type {
          border-radius: 0 0 3px 0;
        }
      }
      &_full, &.calc__content-table_full {
        width: 100%;
        .cap {
          text-transform: capitalize;
        }
        input {
          margin: 0 !important
        }
      }
      &_auto, &.calc__content-table_auto {
        width: 100%;
        overflow: auto;
        display: block;
        cursor: ew-resize;
      }
      &_top, &.calc__content-table_top {
        margin: 2rem 0 1rem !important;
      }
      &_translit {
        tr:hover > td:nth-child(odd),
        tr:hover > td:nth-child(even) {
          background: $white-important;
        }
      }
      &_margin_bottom, &.calc__content-table_margin_bottom {
        margin-bottom: 0 !important;
      }
      &_white_space {
        white-space: nowrap;
      }
    }
  }
  &-list {
    margin: 0;
    list-style-type: none;
    &__body {
      border-bottom: 1px solid $color-eight;
      padding: 0 0 2.5rem;
      margin: 0 0 2.5rem;
    }
    &__title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0;
      padding: 0;
      display: block;
      font-weight: 600;
      a {
        color: $black;
      }
    }
    &__tags {
      margin: 0 0 .5rem;
      overflow: hidden;
      text-transform: lowercase;
      line-height: 1.375rem;
      font-size: .875rem;

      a {
        color: $color-three-important; 
        margin: 0 .25rem 0 0;
      }
    }
  }
}


.compare {
  margin: 0;
  list-style: none;
  position: static;
  font-weight: 400;
  right: initial;

  &__review {
    &-count {
      a:first-of-type {
        margin-right: .75rem;
        color: #28c75d;
      }
      a:last-of-type {
        color: $color-eighteen;
      }
    }
  }
  a {
    margin: 0;
  }

  &__block {
    line-height: 1.5rem;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
  &__error {
    margin: 1.5rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    a {
      border-bottom: $underline;
    }
  }

  &__table {
    td:first-of-type {
      font-weight: 600;
      width: 15rem;
    }
    .btn {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid;
      &.btn_auto {
        width: auto;
      }
    }
    th a {
      color: $black;
    }
    td a {
      border-bottom: $underline;
    }
  }
}

.broker, .insurance, .bank {
  &__data {
    position: relative
  }
  &__info {
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
    padding: 2rem;
    border: 1px solid $color-fourteen;
    color: $color-three;
    a {
      border-bottom: $underline;
    }
  }

  &__table {
    margin: 2rem 0 0;
    line-height: 1.5rem;

    td:last-of-type {
      text-align: left
    }
    td:first-of-type {
      vertical-align: top;
      width: 42%;
    }
    th:last-of-type {
      text-align: left;
    }
    &-image {
    }
    a {
      border-bottom: $underline;
    }
  }

  &__content_overflow {
    overflow: auto;
    margin: 0 0 2.5rem;
  }

  &__group {
    margin: 0 0 2.5rem;
    tr:hover span {
      //color: $color-three;
    }
    &-title {
      margin: 0 0 2rem;
    }
  }

  &__compare {
    margin: 0;
    list-style: none;
    position: absolute;
    right: 1rem;
    line-height: 49px;
    font-size: .875rem;
    li {
      float: left;
    }
    a {
      margin: 0 0 0 1rem; 
      color: $color-one;
    }
    &-add {
      float: right
    }
    &-main {
      a {
        font-size: .875rem;
        font-weight: 300;
        display: inline-block;
        margin-top: .25rem;
        color: $color-three;
      }
    }
  }

  &__invoices {
    float: left;
    position: relative;
    &-title {
      margin: 0 0 2rem;
    }
    .broker__table {
      margin: 0 0 2.5rem !important;
    }
  }

  &__invoice {
    &-add {
      margin: 0;
      list-style: none;
      right: 1rem;
      line-height: 49px;
      position: absolute;
      font-size: .875rem;
      a {
        margin: 0 0 0 1rem;
      }
    }
  }

  &__review {
    &-title {
      margin-top: 0
    }
    &-list {
      list-style: none;
      margin: 0 0 45px;
      display: inline-block;
    }
    li {
      float: left;
      margin:0 5px 0 0 
    }
    .comments ul.root li {
      margin: 0 0 2rem;
    }
  }

  &-title {
    &_top {
      margin-top: 0;
    }
  }

  &__list {
    margin: 0;
    list-style: none;
    &-title, li, h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid $color-eight;
      padding-bottom: 2.5rem;
      font-weight: 600;
    }
    a {
      color: $black;
      border-bottom: 0;
    }

    .tags {
      margin-bottom: .5rem;
      overflow: hidden;
      text-transform: lowercase;
      line-height: 1.5rem;
      font-size: .875rem;
      a {
        color: $color-three-important;
        margin-right: .25rem;
      }
    }
  }

  &__review {
    width: 750px;
    float: left;
    font-size: 1rem;
    padding: 1rem 2rem;
    margin: 0;
    box-sizing: border-box;
    ul {
      list-style: none;
      margin: 0;
    }
    small {
      display: block;
      text-transform:none;
      padding: 0 0 .5rem;
      font-weight:300;
      color: $color-three;
      font-size: .875rem;
      line-height: 1.375rem;
    }

    &-count {
      a:first-of-type {
        margin-right: .75rem;
        color: #28c75d;
      }
      a:last-of-type {
        color: $color-eighteen;
      }
    }
  }

  &__rating {
    &-table {
      cursor: ew-resize;
      overflow: auto;
      margin: 0;
      line-height: 1.25rem;
      td {
        vertical-align: middle;
        &:nth-of-type(5) {
          white-space: nowrap;
        }
      }
      img {
        //width: 1rem;
      }
      th a {
        color: $black;
      }
    }
    &_underline {
      border-bottom: $underline;
    }
    &_white_space {
      white-space: nowrap;
    }
  }
}

.bank, .insurance {
  &__rating {
    &-table {
      td:nth-of-type(4) {
        white-space: nowrap;
      }
    }
  }
}

.broker {
  &__box {
    width: 320px;
    display: inline-block;
    margin: 0 0 20px;

    &-title {
      display: block;
      margin: 0 0 5px 2px;
    }

    &-select {
      select {
        width: 31rem;
      }
    }

    &-item {
      box-sizing: border-box;
      &:first-of-type {
        margin: 0 0 2rem 0;
      }
      label {
        color: $black;
        border-bottom: .5rem solid transparent;
        z-index: 100;
        position: relative;
        font-weight: 600;
      }
    }
  }
}

.text {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 2.5rem 0 0;
  &.text_border {
    border: 1px solid $color-eight;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem 2rem 2rem;
    p {
      margin: 1rem 0 0;
    }
    ul {
      margin: 1rem 0 0 2rem;
    }
    li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 0 .5rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &_top {
    margin: 2rem 0 0;
  }
  p {
    margin: 2rem 0 0;
  }

  li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  a {
    border-bottom: $underline;
  }
  tr:hover > td:nth-child(odd),
  tr:hover > td:nth-child(even) {
    background: $color-twenty-five;
  }

  table {
    display: block;
    overflow: auto;
    td {
      padding: .75rem 1rem;
      &:last-of-type {
        border-right: 0;
      }
    }
    th {
      font-weight: 600;
      &:nth-of-type(2),
      :nth-of-type(3) {
        width: 50%;
      }
    }
    ul {
      margin: 1rem 0 0 2rem;
    }
    li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: .5rem 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    font-weight: 400;
    &:before {
      display: block;
      width: 50%;
      height: 1px;
      background: $color-five;
      content: '';
      margin-bottom: 1rem;
    }
  }
}

.comment {
  &__logo {
    position: absolute;
    z-index: 10;
    img {
      
    }
  }
  &__message {
    box-sizing: border-box;
    position: relative;
    padding: 0 0 0  4.125rem;
    &-text {
      min-height: 2.5rem;
      margin-top: .5rem;
      font-size: .875rem;
      line-height: 1.375rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: .875rem;
      line-height: 1.375rem;
      margin: 0 0 1rem;
    }
    &-children {
      .text, .comment__message-text {
        margin-bottom: 0;
      }
    }
    &-left {
      float: left;
    }
    &-right {
      float: right;
    }
    &-vote {
      padding: 0 0 0  10px;
      padding-right: 10px;
      display: none;
      opacity: .35;
      &_up {
        font-size: 13px;
        color: $color-eighteen;
        border: 1px solid $color-five;
        padding: 0 5px;
      }
      &_number {
        padding: 0 0 0  0;
        padding-right: 0;
      }
      &_item {
        font-size: 13px;
        width: 70px;
        color: #28c75d;
        border: 1px solid $color-five;
        padding: 0 5px;
      }
    }

    &-edited {
      color: $color-three;
      margin: 0 0 0 6px;
    }
  }
  &__date {
    color: $color-three;
    margin: 0 0 0 .5rem;
    font-size: .875rem;
    font-weight: 300;
    &:active, &:hover, &:focus {
      color: #757575;
    }
  }

  &__form {
    textarea, &-message {
      width: 100%;
      min-height: 90px;
      border: 1px solid $color-seven;
      padding: 4px 8px;
      margin: 10px 0;
      outline: 0;
      font-size: .875rem;
      color: $color-three;
      box-sizing: border-box;
      font-family: "Open Sans";
    }
    &-button {
      display: inline-block;
      width: 100%;
      &_submit {
        z-index: 9999;
        position: relative;
        margin: 5px 0;
        border: 0;
        
        background: $color-sixteen;
        color: $white;
        font-family: "Open Sans";
        float: left;
        &:hover {
          background: $color-sixteen;
        }
      }
      &_cancel {
        margin: 6px 5px;
        float: left;
      }
      
      span {
        margin:6px 0 0 8px;
        float: left;
      }
    }
  }
  &__reply {
    font-size: 13px;
    color: $color-three;
    &:hover {
      color: #757575;
    }
  }
}

.insurance {
  &__content_overflow {
    overflow: auto;
  }
  &__list {
    margin: 0;
    list-style: none;
    &-title, li, h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid #edeff0;
      padding-bottom: 2.5rem;
    }
    a {
      color: $black;
    }

    .tags {
      margin-bottom: .5rem;
      overflow: hidden;
      text-transform: lowercase;
      line-height: 1.5rem;
      font-size: .875rem;
      a {
        color: $color-three-important;
        margin: 0 .25rem 0 0;
      }
    }
  }
  &__info {
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
  &__table {
    margin: 2rem 0 0;
    line-height: 1.5rem;

    td:last-of-type {
      text-align: left
    }
    td:first-of-type {
      vertical-align: top;
      width: 42%;
    }
    th:last-of-type {
      text-align: left;
    }
  }
  &__text {
    font-size: 1.125rem;
    &_top {
      margin: 2.5rem 0 0;
    }
    p {
      &:last-of-type {
        margin: 2rem 0 0;
      }
    }

    li {
      margin: 1rem 0;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
    p a, li a {
      border-bottom: $underline;
    }
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }

    table {
      text-align: left;
      td {
        padding: .5rem 1rem;
        background: $white;
        &:last-of-type {border-right: 0}
      }
      th {
        padding: .5rem 1rem;
        color: inherit;
        text-transform: inherit;
        &:nth-of-type(2),
        :nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }
  &__group {
    margin: 0 0 2.5rem;
    tr:hover span {
      color: $color-three;
    }
  }
}

.calendar {
  &__content {
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid $color-ten;
    padding: 2rem;
    margin: 0 0 2.5rem;
    box-sizing: border-box;
    .ui-datepicker-title {
      display: none;
    }
    .ui-datepicker-calendar {
      border: 0 !important;
      th {
        border-left: 0 !important;
        &:last-of-type {
          border-right: 0 !important;
        }
      }
    }
    .calendar__date {
      line-height: 1rem;
    }
  }
  &__date {
    font-size: 1rem;
    margin: 1rem 0 0;
    display: block;
    &-title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 2rem 0 1rem;
      display: block;
    }
  }

  &__year_prev {
    margin: 0 1rem 0 0;
    &_small {
      margin: 0 .75rem 0 0;
    }
  }

  &__controls {
    margin: 0 0 2rem;
    span {
      border-bottom: $underline;
    }
  }
  
  &__detail {
    margin: 2rem 0 0;
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2rem 0;
    font-weight: 600;
    &_top {
      margin: 0 0 2rem;
    }
  }

  p {
    margin: 2rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__total {
    margin: 0 0 2.5rem;
    &-table {
      text-align: left !important;
      td, th {
        padding: .75rem 1rem !important;
      }
      .half-year {
        color: $color-eighteen;
      }
    }
    &-title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 2rem;
      font-weight: 600;
    }
  }

  &__table {
    text-align: left !important;
    font-size: 1rem;
    &_top {
      &_small {
        margin: 1rem 0 0;
      }
    }
    td:first-of-type {
      width: 50%;
    }
    td, th {
      padding: .75rem 1rem !important;
    }
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }
  }
  &__text {
    font-size: 1.125rem;
    &_top {
      margin: 2.5rem 0 0;
    }
    p {
      &:last-of-type {
        margin: 2rem 0 0;
      }
    }

    li {
      margin: 1rem 0;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
    p a, li a {
      border-bottom: 1px solid $color-six;
    }
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }

    table {
      text-align: left;
      td {
        padding: .5rem 1rem;
        vertical-align: top;
        background: $white;
        &:last-of-type {
          border-right: 0;
        }
      }
      th {
        padding: .5rem 1rem;
        color: inherit;
        text-transform: inherit;
        &:nth-of-type(2),
        :nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }
  &__quarter {
    &-title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 2rem;
      font-weight: 600;
    }
  }

  .ui-datepicker-calendar {
    text-align: center;
    margin: 0;
    th {
      padding: .75rem .5rem;
    }
    td {
      padding: .75rem .5rem;
      background: #fff;
    }
  }

.calendar__total-table tr:hover > td:nth-child(odd), 
.calendar__total-table tr:hover > td:nth-child(even) {
  background: $color-twenty-five;
}

  tr:hover > td:nth-child(odd), tr:hover > td:nth-child(even) {background:#fff}

  .ui-datepicker-calendar tr:hover > td:nth-child(odd),
  .ui-datepicker-calendar tr:hover > td:nth-child(even) {
    background:#fff!important;
  }

  .ui-datepicker-calendar td.ui-datepicker-week-end a,
  .ui-datepicker-calendar td.ui-datepicker-week-end span {color: $color-eighteen;}

  .ui-datepicker-inline {
    overflow:hidden;
    width:100%!important;
  }

  .ui-datepicker-year {display: none !important}

  .ui-datepicker-group {
    display: inline-block;
    width: 30%;
    margin: 0 0 2.5rem;
    &-middle {
      padding: 0 2.5rem;
    }
  }

  .ui-datepicker-prev, .ui-datepicker-next {display:none}

  .ui-datepicker-calendar td.ui-datepicker-week-col {color: $color-three; opacity: .5;}
  .ui-datepicker-other-month span {background: #fff!important; opacity: .1;}
  .russian-holiday a, .russian-holiday span {cursor: default; color: $color-eighteen-important}
  .russian-nalog a, .russian-nalog span {cursor: pointer; color: #009075!important}

  .ui-datepicker-calendar td.russian-short span {
    cursor:default;
    color: $color-nineteen-important;;
    color:inherit;
  }

  .ui-datepicker-calendar td.ui-datepicker-today span {color:#28c75d!important}
  .ui-datepicker-calendar td.ui-datepicker-today a {color:#28c75d}

  .ui-datepicker-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    a {
      border-bottom: $underline;
    }
  }
  

}

// Дизайн ячеек календаря refactor

.content {
  .ui-datepicker-calendar {
    font-size: 1rem !important;
    width: 100% !important;
    border: 1px solid $color-fourteen;
    a {
      color: $color-one;
    }
  }
  table.ui-datepicker-calendar td.russian-short span,
  table.ui-datepicker-calendar td.russian-short a {
    //color: $white-important;
  }
}

.ui-datepicker-week-end {
  color: $color-eighteen;
}

table.ui-datepicker-calendar tr:hover {
  td.ui-datepicker-week-end, td.russian-holiday {
    //background: $color-eighteen-important;
  }
  td.russian-short {
    //obackground: $color-nineteen-important;;
  }
  td.ui-datepicker-other-month {
    background: $white-important;
    &.russian-holiday, &.russian-short, &.ui-datepicker-week-end, &.ui-datepicker-today {
      background: $white-important;
    }
  }
  td.ui-datepicker-today {
    //background: $color-twenty-important;
    span, a {
      //color: $white-important;
    }
  }
}

table.ui-datepicker-calendar td.russian-short {
  //background: $color-nineteen-important;;
}

table.ui-datepicker-calendar td.ui-datepicker-other-month {
  opacity: .1;
  background: $white-important;
  span, a {
    color: $black-important;
  }
  &.russian-holiday, &.russian-short, &.ui-datepicker-week-end, &.ui-datepicker-today {
    background: $white-important;
    span, a {
      color: $black-important;
    }
  }
}

.ui-datepicker-calendar .ui-datepicker-other-month span {
  background: transparent !important;
  opacity: 1 !important;
  color: $black-important;
}

table.ui-datepicker-calendar td.ui-datepicker-week-end a, table.ui-datepicker-calendar td.ui-datepicker-week-end span, table.ui-datepicker-calendar td.russian-holiday span {
  //color: $white-important;
}
table.ui-datepicker-calendar td.ui-datepicker-week-end, table.ui-datepicker-calendar td.russian-holiday  {
  //background: $color-eighteen;
}

.content {
  table.ui-datepicker-calendar td.ui-datepicker-today {
    //background: $color-twenty-important;
    span, a {
      //color: $white-important;
    }
    &.russian-holiday, &.russian-short, &.ui-datepicker-week-end {
      //background: $color-twenty-important;
      span, a {
        //color: $white-important;
      }
    }
    &.ui-datepicker-other-month {
      //background-color: $white-important;
      span, a {
        color: $black-important;
      }
    }
  }
}

.currency {
  &__table {
    border-collapse:collapse;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    margin: 2rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;

    .header {
      width: auto;
      height: auto !important;
      box-shadow: none;
      border-bottom: 1px solid $color-fourteen;
      cursor: pointer;
    }
    
    &_full {
      line-height: 1.5rem;
      margin: 0;
      display: block;
      overflow: auto;
      a {
        border-bottom: $underline;
      }
    }
    &_white_space {
      td:last-of-type {
        white-space: nowrap;
      }
    }
  }
  &__content-small {
    text-transform: lowercase;
  }
  &__title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin: 0 0 2rem;
    &_top {
      margin: 2rem 0;
    }
  }
  &__description {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.5rem 0;
    font-weight: 600;
  }
  &__text {
    font-size: 1.125rem;
    p {
      &:last-of-type {
        margin: 2rem 0 0;
      }
    }

    li {
      margin: 1rem 0;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
    p a, li a {
      border-bottom: 1px solid $color-six;
    }
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }

    table {
      text-align: left;
      td {
        padding: .5rem 1rem;
        vertical-align: top;
        background: $white;
        &:last-of-type {border-right: 0}
      }
      th {
        padding: .5rem 1rem;
        color: inherit;
        text-transform: inherit;
        &:nth-of-type(2),
        :nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }
  &__group {
    padding: 1rem 0;

    &_bottom_small, &.currency__group_bottom_small {
      padding: 1rem 0 .5rem;
    }
    &_top, &.currency__group_top {
      padding: 1rem 0 0;
      &_large {
        padding: 2rem 0 0;
      }
    }
    &_bottom, &.currency__group_bottom {
      padding: 0 0 1rem;
    }
  }

  &__popup {
    width: 250px;
    padding: .125rem 1rem;
    height: 350px;
    overflow: auto;
    &-search {
      padding: .59375rem 1rem;
      font-weight: 300;
      color: $color-three;
      font-size: .875rem;
      font-family: "Open Sans";
      width: 100%;
      margin: .5rem 0 .75rem;
      box-sizing: border-box;
      border: 1px solid $color-seven;
      
    }
    ul {
      padding: 0 !important;
      li {
        list-style-type:none;
        a {
          padding: .125rem 0 !important;
          line-height: 1.25rem;
        }
      }
    }
  }

  &__converter {
    font-size: 1rem;
    padding: 1rem 2rem;
    margin: 0 0 2.5rem;
    border: 1px solid $color-six;

    label {
      display: block;
      font-weight: 600;
      border-bottom: .75rem solid transparent;
      margin-bottom: .25rem;
      line-height: 1.5rem;
    }

    .currency__group {
      padding: 1rem 0;
      float: left;
      margin: 0 .75rem 0 0;
    }

    .currency__group_top {
      padding: 1rem 0 2.5rem;
      float: left;
      margin: 0 .75rem 0 0;
    }

    &-input, input {
      margin: 0 .25rem 0 0;
      font-family: "Open Sans";
      width: 10rem;
      height: 30px;
      border: 1px solid $color-seven;
      padding: .25rem 1rem;
      color: $black;
    }

    table, .currency__table {
      margin: 0;
      padding: 0;
      border: 0;
      display: table;
      th {
        background: $white;
      }
      tr:last-of-type td {
        border-bottom: 0;

        &:first-of-type {
          border-radius: 0 0 0 3px;
        }
        &:last-of-type {
          border-radius: 0 0 3px 0;
        }
      }
    }
  }

  &__item {
    &-reset {
      .positive {
        font-weight:normal !important;
        padding: 0 !important;
        color: $black-important;
        background-color: $white-important;
      }
      .negative {
        font-weight:normal !important;
        padding: 0 !important;
        color: $black-important;
        background-color: $white-important;
      }
    }
  }
  &__chart {
    margin: 2rem 0 !important;
    &-description {
      font-size: .875rem;
      line-height: 1.375rem;
      color: $color-three;
      margin: -1rem 0 0;
    }
  }
}

.popover_translate {
  color: $black;
  line-height: 1.5rem;
  z-index: 100;
  position: relative;
  font-weight: 600;
  float: left;
  display: block;
  border-bottom: .75rem solid transparent;
}

.translate {
  border: 1px solid $color-ten;
  padding: 2rem;
  margin: 0 0 2.5rem;
  a {
    border-bottom: $underline;
  }
  &__item {
    box-sizing: border-box;
    line-height: 0;
    &:first-of-type {
      margin: 0 0 2rem 0;
    }
    &-popover {
      color: $black;
      border-bottom: .5rem solid transparent;
      z-index: 100;
      position: relative;
      font-weight: 600;
    }
  }

  &__size {
    font-size: .875rem;
    line-height: 1.375rem;
    color: $color-three;
    float: right;
  }

  &__textarea {
    border: 1px solid $color-seven;
    position: relative;
    z-index: 10;
    width: 100% !important;
    min-height: 10rem !important;
    margin: .25rem 0 0 !important;
    outline:0;
    box-sizing: border-box;
    padding: 1rem;
    font-family:"Open Sans";
    font-size: .875rem;
    line-height: 1.375rem;
    color: $color-twenty-three;
  }

  &__settings {
    font-size: .875rem;
    color: $color-three;
    margin-top: 2rem;
  }

  &__text {
    font-size: 1.125rem;
    p {
      &:last-of-type {
        margin: 2rem 0 0;
      }
    }

    li {
      margin: 1rem 0;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
    p a, li a {
      border-bottom: 1px solid $color-six;
    }
    tr:hover > td:nth-child(odd),
    tr:hover > td:nth-child(even) {
      background: $color-twenty-five;
    }

    table {
      text-align: left;
      td {
        padding: .5rem 1rem;
        vertical-align: top;
        background: $white;
        &:last-of-type {border-right: 0}
      }
      th {
        padding: .5rem 1rem;
        color: inherit;
        text-transform: inherit;
        &:nth-of-type(2),
        :nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }
}

.commission {
  margin: 1rem 0;
  &__short {
    width: 5rem;
  }
}

.attention {
  width: 300px;
  float: right;
  box-sizing: border-box;
  ul, ol {
    margin: 0 0 0 2rem;
  }
  li {
    line-height: 1.5rem;
    margin: 0 0 1rem;
  }
}

.review {
  width: 750px;
  float: left;
  font-size: 1rem;
  padding: 2rem;
  margin: 0;
  border: 1px solid $color-ten;
  box-sizing: border-box;
  &__form {
    &-stars {
      display: inline-block;
      li {
        display: inline-block;
        margin-bottom: 0 !important;
      }
    }

    &-description {
      text-transform: lowercase;
      display: block;
      text-transform: none;
      padding: 0 0 .5rem;
      font-weight: 300;
      color: $color-three;
      font-size: .875rem;
      line-height: 1.375rem;
    }
    &-item {
      margin-bottom: .5rem;
      &_list {
        margin: 0 0 2rem;
      }
      &_title {
        padding: 0;
        display: block;
        font-weight: 600;
        margin: 0 0 .5rem;
      }
    }
    &-list {
      list-style: none;
      margin: 0 0 2rem !important;
      
      li {
        margin-bottom: .5rem;
      }
    }
    &-criteria {
      margin-bottom: .5rem;
    }
    &-title {
      padding: 0;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      display: block;
      margin: 0 0 1.5rem;
    }
    &-buttons {
      list-style: none;
      display: inline-block;
      padding: 0 0 0 5px;

      &_item {
        display: inline-block;
        padding: 0;
        margin: 0;
        button {
          border-left: 1px solid #e8eaeb;
          color: $color-three-important;
          background: $color-six;
          box-shadow: none;
          margin: 0;
          outline: 0;
          &:last-child {
            border-right: none;
          }
          &:hover, &.active {
            background-color: #e8eaeb;
          }
        }

        &:first-child button {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          border-left: none;
        }

        &:last-child button {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
    &-message {
      textarea {
        width: 100% !important;
        min-height: 90px;
        border: 1px solid $color-seven;
        padding: 4px 8px;
        margin: 10px 0;
        font-size: .875rem;
        line-height: 1;
        color: $color-three;
        box-sizing: border-box;
        font-family: "Open Sans";
        outline: 0;
      }
    }
    &-btn {
      position: relative;
      margin: 2rem 0 0;
    }
  }
  &__list {
    &-item {
      width: 750px;
      float: left;
      font-size: 1rem;
      box-shadow: inset 0 0 0 1px $color-six;
      padding: 1rem 2rem;
      margin: 0 0 2.5rem;
      box-sizing: border-box;
      ul {
        list-style: none;
        margin: 0;
      }
    }
  }
}

/* READER */

.reader {
  .search__results-submit {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }

  .dropdown__menu {
    a {
      color: $color-twenty;
      &:hover {
        color: $color-three-important;
      }
    }
  }

  & > ul {
    margin: 0 0 -5px 0;
    list-style: none;
    display: inline-block;
  }

  ul {
    .dropdown__menu {
      padding: .75rem 0 !important;
      margin: 0;
      top: 100%;
      z-index: 10000;
      min-width: inherit;
      border: 1px solid $color-eleven;
      box-shadow: $dropdown-shadow;
      & > li {
        margin: 0;
        float: none;
        display: block;
        padding: 0;
        a {
          padding: 2px 10px;
          color: #757575;

          &:active, &:focus, &:visited {
            background-color: $white;
          }

          &:hover {
            background: $white;
            color: #757575;
          }
        }

        &:first-child {
          a {
            &:hover {
              border-radius: 0 5px 0 0;
            }
          }
        }

        &:last-child {
          border-bottom: none;
          a {
            border-bottom: none;
            &:hover {
              border-radius: 0 0 5px 5px;
            }
          }
        }
      }
    }

    & > li {
      & > a {
        display: block;
      }
    }
  }

  li {
    display: inline-block;
    margin: 0 11px 0 0;
  }

  &__list {
    background: $color-nine;
    padding: 10px;
    list-style: none;
    margin: 0 0 20px;
    height: 250px;
    overflow: auto;
  }

  &__source {
    color: $color-three;
    margin-bottom: .5rem;
    text-transform: lowercase;
    line-height: 1.375rem;
    font-size: .875rem;
    a {
      color: $color-three;
    }
  }

  &__date {
    color: $color-three;
    margin: 0 1rem 0 0;
    font-size: .875rem;
    line-height: 1.375rem;
  }

  &__views {
    color: $color-three;
    font-size: .875rem;
    line-height: 1.375rem;
  }

  table, &__table {
    td a:visited {
      color: #80CBC4
    }
  }

  &__header {
    margin: 1rem auto 0;
    position: relative;
    width: 1100px;
  }

  &__share {
    top: 0;
    position: fixed;
    margin: 230px 0 0 5%;
    .ya-share2__list_direction_horizontal > .ya-share2__item {
      display: block !important;
      margin-bottom: .5rem !important;
      opacity: 1
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    &_right, &_left {
      display: flex;
      align-items: center;
    }

    .search__remove {
      border-bottom: .5rem solid transparent;
    }

    ul {
      margin: 0;
      .dropdown {
        padding-bottom: 0;
        border-bottom: none;
        &__toggle {
          line-height: 1.375rem;
          border-bottom: .75rem solid transparent;
          padding-bottom: 0;
        }

        li {
          border-bottom: none;
        }
      }
    }

  }

  &__content {
    &-header {
      margin: 0 0 2.5rem;
      border-bottom: 1px solid $color-eight;
      padding: 0 0 1rem;

      &_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_right {
        display: flex;
        align-items: center;
      }

      &_group {
        margin: 0 15px 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }

    &-item {
      border-bottom: 1px solid $color-eight;
      padding: 0 0 2.5rem;
      margin: 0 0 2.5rem;
      &_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 1.5rem;
        display: block;
        font-weight: 600;
        a {
          color: $black;
        }
      }
      &_text {
        font-size: .875rem;
        line-height: 1.375rem;
      }
      &_bottom {
        margin: .5rem 0 0;
      }
    }
  }

  &__tn {
    overflow: hidden;
    .wrapper__fixed .header {
      width: 1100px;
      margin: 0 0 0 auto;
      margin-right: auto;
      position: relative;
    }
    #link {
      margin-top: 90px;
      border-top: 1px solid #e0e0e0;
    }
  }
}

.wrapper__fixed {
  position: fixed;
  width: 100%;
  margin: auto;
  z-index: 15;
}

.business {
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid $color-ten;
  position: relative;
  padding: 2rem;
  margin: 0 0 2.5rem;
  &__kind {
    margin-bottom: 1rem;
    &-title {
      color: $color-sixteen;
      display: initial !important;
      border-bottom: .8125rem solid transparent;
      span {
        text-transform: lowercase;
        font-size: inherit;
      }
    }
  }

  ul, &__list {
    margin: 0;
    display: inline;

    li {
      list-style: none;
      display: inline-block;
    }
    
    &-title {
      margin: 0 .75rem 0 0;
    }
  }

  .btn {
    &:hover, &:active, &:focus {
      //background: $color-sixteen;
    }
  }

  &__result {
    padding: 10px 0;
    margin: 10px 0 30px;

    input, &-input {
      margin: 0 5px 0 0;
      width: inherit;
    }
  }

  &__popup {
    width: 250px;
    padding: 0 1rem .25rem;
    overflow: auto;
    height: 350px;
    li {
      padding-bottom: .25rem;
      &:last-of-type {
        padding-bottom: 0 !important;
      }
    }
    &-all {
      text-transform: none;
    }
    &-search {
      padding: .59375rem 1rem;
      font-weight: 300;
      font-size: .875rem;
      border: 1px solid $color-seven;
      box-sizing: border-box;
      font-family:"Open Sans";
      color: $color-three;
      margin:-.5rem 0 0;
    }
    &-list {
      margin: 0 !important;
      padding: 0 !important;
      li label {
        line-height: 1.25rem;
      }
    }
    .business__popup-search {
      width: 100%;
      margin: .5rem 0 1rem;
      box-sizing: border-box;
      border: 1px solid $color-seven;
    }
  }

  &__sort {
    right: 2rem;
    bottom: 2.625rem;
    position: absolute;
    font-size: .875rem;
    line-height: 1.375rem;
    color: $color-three;
  }

  &__input {
    border: 1px solid $color-seven;
    margin: 0 .25rem 0 0;
    font-weight: 300;
    font-family: "Open Sans";
    height: 30px;
    width: 10rem;
    padding: .25rem 1rem;
    z-index: 1000;
    color: $black;
    &[type=radio] {
      margin: -2px 2px 0 2px;
      width: initial;
    }
    &[type=checkbox] {
      float: left;
      width: 1rem;
      height: 1rem;
      margin: 0;
    }
  }
  &__group {
    padding: 1rem 0;
    &_top, &.business__group_top {
      padding: 1rem 0 0;
    }
    &-title {
      font-weight: 600;
      margin-bottom: 1rem;
      display: block;
    }
    &-dash {
      margin: 0 .25rem 0 0;
      &.business__group-dash_medium, &_medium {
        margin: 0 .5rem 0 0;
      }
      &.business__group-dash_large, &_large {
        margin: 0 .75rem 0 .5rem;
      }
    }
    &-input {
      border: 1px solid $color-seven;
      margin: 0 .25rem 0 0;
      font-weight: 300;
      font-family: "Open Sans";
      font-size: .875rem;
      height: 30px;
      width: 10rem;
      padding: .25rem 1rem;
      z-index: 1000;
      color: $black;
  
      &[type=radio] {
        margin: -2px 2px 0 2px;
        width: initial;
      }
      &[type=checkbox] {
        float: left;
        width: 1rem;
        height: 1rem;
        margin: 0;
      }
      &_medium, &.business__group-input_medium {
        width: 75px;
      }
      &_short, &.business__group-input_short {
        width: 5rem;
      }
    }
  }
}

.franchises {
  &__title {
  }
  &__list {
    li {
    }
  }
  .article__info_bottom {
    margin: 2rem 0 2.5rem;
  }
}


.converter {
  &__group {
    &-list {
      &_item {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 2.5rem;
        border-bottom: 1px solid #edeff0;
        padding-bottom: 2.5rem;
        a {
          color: $black;
        }
      }
    }
    &-title {
      margin: 2.5rem 0 2rem;
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 1.5rem 0;
      font-weight: 600;
      padding: 0;
    }
  }

  &__list {
    margin: 0;
    list-style: none;
    &-title, li {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid $color-eight;
      padding: 0 0 2.5rem;
      font-weight: 600;
    }
    a {
      color: $black;
    }

    .tags {
      margin-bottom: .5rem;
      overflow: hidden;
      text-transform: lowercase;
      line-height: 1.5rem;
      font-size: .875rem;
      a {
        color: $color-three-important;
        margin: 0 .25rem 0 0;
      }
    }
  }

  .ns-popover-list-theme ul {
    padding: 0 !important;
    margin: .75rem 0;
    display: block;
    list-style: none
  }

  &__content {
    font-size: 1rem;
    border: 1px solid $color-ten;
    padding: 1rem 2rem;
    margin: 0 0 2.5rem;
    &-popover {
      color: $color-one;
      border-bottom: .75rem solid transparent;
      display: initial;
    }
    &-list {
      padding: 0;
      margin: 0;
      list-style: none;
      &_items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .converter__content-group {
          width: 50%;
        }
        .converter__content-group_full {
          width: 100%;
        }
      }
    }
    &-title {
      font-size: 1.375rem;
      line-height: 1.875rem;
      font-weight: 600;
      width: 100%;
    }
    &-group {
      padding: 1rem 0;
      list-style: none;
      &_inline {
        input {
          display: inline-block;
        }
      }
      &_settings, &.converter__content-group_settings {
        font-size: .875rem;
        line-height: 1.375rem;
        color: $color-three;
      }
      &_title {
        display: inline-block;
        font-weight: 600;
        margin: 0 0 1rem;
        line-height: 1.5rem;
        a {
          display: inline-block;
        }
        &::first-letter {
          text-transform: capitalize;
        }
      }
      &_input {
        border: 1px solid $color-seven;
        margin: 0 .25rem 0 0;
        font-family: "Open Sans";
        height: 30px;
        width: 10rem;
        font-size: .875rem;
        padding: .25rem 1rem;
        z-index: 1000;
        display: block;
        color: $black;
      }
      &_text {
        margin: 1rem 0 0;
        font-size: .875rem;
        line-height: 1.375rem;
        color: $color-three;
      }
    }
    &-results {
      margin: 1rem 0;
      &_top_small, &.converter__content-results_top_small {
        margin: 1rem 0 !important;
      }

      &_title {
        font-size: 1.375rem;
        line-height: 1.875rem;
        font-weight: 600;
      }

      &_subtitle {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 1rem 0;
        font-weight: 600
      }

      &_list, ul {
        margin: 0;
        list-style: none;

        &.converter__content-results_reset, &_reset {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            &:first-of-type {
              margin-top: 5px;
            }
            &:last-of-type {
              margin-bottom: 0;
              input {
                margin-bottom: 0;
              }
            }
          }
        }

        &.converter__content-results_disc, &_disc {
          list-style: disc !important;
          margin: 1rem 0 0 2rem !important;
        }
      }

      li, .converter__content-result {
        line-height: 1.5rem;
        margin: .5rem 0;
      }

      &_small, &.converter__content-results_list_small {
        margin: 1rem 0 !important;
      }
    }
  }
}

/*
************
*** TABS ***
************
*/

.tab-content {
  position: relative;
  overflow:auto;

  a {
    border-bottom: $underline;
  }

  li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height:1.625rem;
  }
}

.tab-content>.tab-pane,.pill-content>.pill-pane{display:none;}
.tab-content>.active,.pill-content>.active{display:block;}


.nav-tabs > li {
list-style: none}

.nav-tabs li a {
text-transform: uppercase;
line-height: 1;
border: 1px solid transparent;
border-left: 0;
border-right: 0;
margin: 0 1rem 0 0;
padding: 0;
font-weight: 300}

.nav-tabs li.active a {
background:transparent;
color: $color-three;
border: 1px solid transparent;
border-left: 0;
border-right: 0;
font-weight: 300}

.nav-tabs > li a:hover {background: transparent;}

.nav-tabs {
border-bottom: 0;
padding-top:0;
border-top:none;
font-size:.875rem;
margin:0}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
color: $color-three;
border: 1px solid transparent;
border-left: 0;
border-right: 0;
background:transparent;
border-bottom-color: transparent;
cursor:default}

/*
***********************
*** GALLERY OVERLAY ***
***********************
*/

img[data-gallery] {cursor:pointer;}

#galleryOverlay {
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
opacity: 0;
z-index: 100000;
background: rgba($color-twenty-eight, .9);
overflow: hidden;
display: none;
transition: opacity .4s ease}

/* This class will trigger the animation */

#galleryOverlay.visible {opacity:1}

#gallerySlider {
height:100%;
left:0;
top:0;
width: 100%;
white-space:nowrap;
position: absolute;
transition:left .4s ease;
}

#gallerySlider .placeholder {
background:url(/static/images/preloader.gif) no-repeat center center;
height:100%;
line-height:1px;
text-align:center;
width: 100%;
display: inline-block}

/* The before element moves the image halfway from the top */

#gallerySlider .placeholder:before {
content:"";
display: inline-block;
height:50%;
width: 1px;
margin-right:-1px}

#gallerySlider .placeholder img {
display: inline-block;
max-height:100%;
max-width: 100%;
vertical-align:middle;
opacity:1;
}

#gallerySlider.rightSpring {
-moz-animation:rightSpring .3s;
-webkit-animation:rightSpring .3s}

#gallerySlider.leftSpring {
-moz-animation:leftSpring .3s;
-webkit-animation:leftSpring .3s}

/* Firefox Keyframe Animations */

@-moz-keyframes rightSpring {
0% {margin-left:0}
50% {margin-left:-30px}
100% {margin-left:0}}

@-moz-keyframes leftSpring {
0% {margin-left:0}
50% {margin-left:30px}
100% {margin-left:0}}

/* Safari and Chrome Keyframe Animations */

@-webkit-keyframes rightSpring{
0% {margin-left:0}
50% {margin-left:-30px}
100% {margin-left:0}}

@-webkit-keyframes leftSpring{
0% {margin-left:0}
50% {margin-left:30px}
100% {margin-left:0}}

/* Arrows */

#prevArrow,#nextArrow {
border:none;
text-decoration: none;
background:url(/static/images/arrows.png) no-repeat;
opacity:.85;
cursor:pointer;
position: absolute;
width: 43px;
height:58px;
top:50%;
margin-top:-29px}

#prevArrow:hover, #nextArrow:hover {opacity:.85}
#prevArrow {background-position: left top;left:5%}
#nextArrow {background-position: right top;right:5%}

/*
**************
*** ADVERT ***
**************
*/

.ad {
  &__header {

  }

  &__category {
    border-bottom: 1px solid $color-eight;
    margin: 0 0 2.5rem;
    padding: 0 0 2.5rem;
  }

  &__aside {
    text-align: center;
  }

  &__sticky {
    background: $white;
    box-sizing: border-box;
    margin:0;
    padding: 0 auto;
    text-align: center;
    width: 300px;
  }

  &__chart {
    text-align:center;
    padding: 2.5rem 0 0;
  }

  &__calendar {
    margin: 0 0 2.5rem;
  }

  &__converter {
    margin: 0 0 2.5rem;
  }

  &__article {
    margin: 0 0 2.5rem;
  }

  &__calculator {
    margin: 0 0 2.5rem;
  }

  &__word {
    margin: 0 0 2.5rem;
  }

  &__translate {
    margin: 0 0 2.5rem;
  }

  &__test {
    margin: 0 0 2.5rem;
  }

  &__currency {
    margin: 0 0 2.5rem;
  }
  &__download {
    margin: 2.5rem 0 0;
  }
  &__index {
    margin: 2.5rem 0;
    text-align: center;
  }
}

.ad__category,
.ad__article,
.ad__translate,
.ad__calculator,
.ad__currency,
.ad__test,
.ad__calendar,
.ad__word,
.ad__download,
.ad__converter {
  tr:hover > td:nth-child(odd), tr:hover > td:nth-child(even) {
    background: $white-important;
  }
}

.ad__rating,
.ad__rating td {
  //background-color: rgba($color-nineteen, .02);
}

.ad__rating {
  &:nth-child(1) {
    td {
      //background-color: rgba($color-nineteen, .08);
    }
  }
  &:nth-child(2) {
    td {
      //background-color: rgba($color-nineteen, .06);
    }
  }
  &:nth-child(3) {
    td {
      //background-color: rgba($color-nineteen, .04);
    }
  }
}

.ad__rating, .ad__rating tr:hover td {
  background-color: transparent !important;
}

.a-cat {margin: 0 0 2.5rem;border-bottom: 1px solid #edeff0;padding-bottom: 2.5rem;}
.a-ndx {margin:0 0 2.5rem;border-bottom: 1px solid #edeff0;padding-bottom:2.5rem;text-align: center;}
.a-art {margin:0 0 0 -11px;position: relative}
.a-header {float: right;width: 728px;height: 90px;display: none;}
.a-chart a {display: inline-block;width: 100%}

/*
****************
*** COMMENTS ***
****************
*/

.auth {
padding: 10px;
margin:0;
text-align:center;
background-color: #fffde7;

}

.a-cat .auth {
padding: 10px;
margin:25px 0 25px 11px;
text-align:center;
background-color: $color-nine;}

.comment-form textarea {
width: 100%;
border: 1px solid $color-seven;
resize:vertical;
min-height:90px;
padding: 5px}
.comment-form .button-wrap {
display: inline-block;
width: 100%}
.comment-form .button-wrap .submit {
z-index:9999;
position: relative;
float: right;
margin-top:5px;
margin-left:5px;
margin-bottom:5px}
ul.social {list-style: none}
ul.social li {display: inline}
ul.children {margin-left:50px}
ul.children li {
box-sizing:border-box}
.comments ul.root {
list-style: none;
margin:0;
margin-bottom:1rem}
.comments ul.root li {display: block;width: 100%;margin:25px 0;font-size: 1rem;line-height: 1.5rem}
.org-review .comments ul.root li {margin:0 0 2rem}
.comments ul.root li ul.children li:last-of-type {margin:0}
.comments ul.root li .msg {
box-sizing: border-box;
position: relative;
padding: 0 0 0  4.125rem}
.comments ul.root li .msg .text {min-height:2.5rem;margin-top: .5rem;font-size:.875rem;line-height: 1.375rem;margin-bottom: 1rem}
.comments ul.root li .msg p {font-size:.875rem;line-height: 1.375rem;margin: 0 0 1rem}
.comments ul.root li .replay {font-size: 13px;color: $color-three;}
.comments ul.root li .replay:hover {color: #757575}
.comments ul.root li .complaint {font-size: 13px;opacity:0.35;display:none}
.comments ul.root li .vote-container {padding: 0 0 0 10px;padding-right:10px}
.comments ul.root li .vote {
font-size: 13px;
width: 70px;
color: #28c75d;
border: 1px solid $color-five;
padding: 0 5px}
.comments ul.root li .vote-up {
font-size: 13px;
color: $color-eighteen;
border: 1px solid $color-five;
padding: 0 5px}
.comments ul.root li .number {
padding: 0 0 0  0;
padding-right: 0}
.comments ul.root li .comment-logo {
position: absolute;
z-index: 10}
.complaintPopover {
padding: 10px}
.complaintPopover h3 {
margin-bottom:10px}
.complaintPopover textarea {
margin-top:10px;
width: 100%;
height:60px}

.comments {position: relative;width: 750px}

.comment-form textarea {
width: 100%;
min-height:90px;
border: 1px solid $color-seven;
padding: 4px 8px;
margin:10px 0;
outline:0;
font-size:.875rem;
color: $color-three;
box-sizing: border-box;
font-family:"Open Sans"}

.comment-form .button-wrap {
display: inline-block;
width: 100%}

.comment-form .button-wrap .submit {
z-index:9999;
position: relative;
margin:5px 0;
border:0;
background: $color-sixteen;
color: $white;
font-family:"Open Sans";
float: left;
}

.comment-form .button-wrap .submit:hover {
background: $color-sixteen;}

.comment-form .button-wrap .cancel {
margin:6px 5px;
float: left}

.comment-form .button-wrap span {
margin:6px 0 0 8px;
float: left}

.vote-container {
display:none;
opacity:0.35}

.input-p {
width: 100%;
border: 1px solid $color-seven;
padding: 4px 8px !important;
margin:10px 0;
font-size:.875rem;
line-height:1;
color: $color-three;
box-sizing: border-box;
font-family:"Open Sans";
display: inline-block;
white-space:pre-line}

ul.social {
list-style: none}
ul.social li {
display: inline}
ul.children {margin: 2rem 0 0 2.0625rem}
ul.children li {
box-sizing:border-box}

.comments ul.root li ul.children .msg .text {margin-bottom: 0}
.complaintPopover {padding: 10px}
.complaintPopover h3 {margin-bottom:10px}

.complaintPopover textarea {
margin-top:10px;
height:60px;
padding: 4px 8px;
line-height:1;
border: 1px solid $color-seven;
width: 100%;
box-sizing: border-box;
font-family:"Open Sans"}

.complaintPopover input[type="submit"] {
z-index:9999;
position: relative;
margin:5px 0;
border:0;
background: $color-sixteen;
color: $white;
font-family:"Open Sans"}

.complaintPopover input[type="submit"]:hover {
background: $color-sixteen;}

.was-edit {
color: $color-three;
margin-left:6px}

.number.negative {
color: $color-eighteen;
font-weight:normal}

.number {color: #28c75d;font-weight:normal}

.comment-date, .user-status {
color: $color-three;
margin-left:.5rem;
font-size:.875rem;
}

.user-status a {color: $color-three;}
.user-status a:hover {color: #757575}

.comment-date:active,
.comment-date:hover,
.comment-date:focus {
color: #757575}

/*
*************
*** FORUM ***
*************
*/

.forum {position: relative;display: block;margin: 0 0 2.5rem}
.forum-stats {margin: 0;list-style: none}
.forum-stats li:first-of-type {margin-bottom: .5rem;}

.forum td, .forum-topics td {vertical-align: top;padding: 1rem; color: $color-three;}
.forum th, .forum-topics th {padding: 1rem}
.forum .text p, .forum-topics .text p {font-size: 1rem;line-height: 1.5rem; margin: 1.5rem 0}
.forum .text ul {font-size: 1rem;line-height: 1.5rem; list-style: none;margin:0}
.forum .text li {line-height: 1.5rem}
.forum .text strong { font-size: 1.375rem;
line-height: 1.875rem;
margin: 0 0 1.375rem;font-weight: 400}

.forum td:first-of-type {
text-align: left;
width: 55%;
box-sizing:border-box}

.forum td:last-of-type, .forum-topics td:last-of-type {text-align: left;width: 25%;line-height: 1.5rem}

.forum-topics .text strong {
font-size: 1.125rem;
line-height: 1.625rem;
margin: 0 0 1.625rem;
font-weight: 400}

.forum-topics td:nth-of-type(2) {
text-align: left;
box-sizing: border-box;
width: 55%}

.forum-topics td:last-of-type {text-align: left;width: 25%}

.forum-topics td {vertical-align:top}

.forum-topics .info {color: $color-three;}
.forum-topics .info a {color: $color-three;}
.forum-topics .info a:hover {color: #757575}
.forum-topics {margin:0 0 2.5rem}

.highlight td {background: #fffde7;}

.forum-filter {
display: block;
list-style: none;
margin: 0 0 2.5rem;
color: $color-three;}

.forum-filter li {display: inline}

.forum-filter a {
display: inline-block;
color: $color-three;
font-size: .875rem;
padding: .5rem;
border: 1px solid $color-six;
}

.user-name {text-transform:capitalize}

.forum-avatar {max-width: 2.5rem}

.add-topic {margin: .5rem 0 0 .75rem}

.add-topic a {
background: $color-sixteen;
padding: .5625rem 1rem !important;
border: 0;
font-weight: 400;
color: $white;}

.add-topic a:hover {background: $color-sixteen;}

.edit-topic {
margin:10px 0 30px;
right:0;
top:0;
position: absolute}

.edit-topic a {
padding: 2px 4px;

color: $white;
background: $color-sixteen;
}

.edit-topic a:hover {background: $color-sixteen;}
.topic-finish { color: $color-eighteen;}
.topic-highlight {color: #8a6d3b}
.form-group {width: 100%}

.form-group label {
display: inline-block;
max-width: 100%;
margin-bottom:5px;
font-weight: 600}

.form-group textarea {
width: 100%;
min-height:90px;
border: 1px solid $color-seven;
padding: 4px 8px;
margin:10px 0;
font-size:.875rem;
line-height:1;
color: $color-three;
box-sizing: border-box;
font-family:"Open Sans"}

.form-group input {
width: 100%;
border: 1px solid $color-seven;
padding: 4px 8px;
margin:10px 0;
font-size:.875rem;
line-height:1;
color: $color-three;
height:29px !important;
box-sizing: border-box;
font-family:"Open Sans"}

.form-group select {
display: block;
border: 1px solid $color-five;
padding: 4px 8px;
margin:10px 0;
font-size:.875rem;
line-height:1;
color: $color-three;
box-sizing: border-box;
font-family:"Open Sans"}

.form-submit input {
position: relative;
margin:5px 0;
border:0;
background: $color-sixteen;
color: $white;
font-family:"Open Sans"}

.form-submit input:hover {background: $color-sixteen;}

/*
***************
*** PROFILE ***
***************
*/

.user {
width: 100%}

.user h2 {
margin:17px 0}

.user h2:first-of-type {
margin:0 0 17px}

.user h3 {
margin:17px 0}

.user ul {
margin:0;
list-style: none}

.user ul ul {
margin:10px 0 10px 25px;
list-style:initial}

.user .left {
width: 250px;
float: left;
margin:0 30px 0 0;
position: relative}

.user .date {
font-size:.875rem;
color: $color-three;
padding: 5px 5px 0;
box-sizing: border-box;
margin:0 0 1rem}

.user .date .online {
font-size: 12px;
color: $color-eighteen;
font-weight: 600;
padding: 5px 5px 0;
box-sizing: border-box;
margin:0 0 1rem}

.user .right {
width: 430px;
float: right}

.user .status {
width: 100%;
margin:20px 0 5px;
padding: 2px 5px 3px;
text-align:center;
font-size:.875rem;
box-sizing: border-box;
background: $color-sixteen;
color: $white;


}

.user .status:after {
border:solid;
border-color: #28c75d transparent;
border-width: 0 6px 6px 6px;
content:"";
top:214px;
left:96px;
position: absolute;
z-index:99}

.msg {
/*word-break:break-all;*/}

.user .note {
margin:17px 0}

.user .note input {
border: 1px solid $color-seven;
background: $white;
cursor:pointer;
font-size:.875rem;
line-height:1;
position: relative;
width: 100%;
box-sizing: border-box;
padding: 4px 8px;

font-family:"Open Sans"}

/* REG & AUTH */

.access {}
.password {display: block;margin-top: 2rem}
.access p {margin:0 0 2rem}

.access label {font-weight: 600;margin-bottom: 1rem;display: block;font-size: 1rem;line-height: 1;}

.access input {
 border: 1px solid $color-seven;
 margin: 0 .25rem 0 0;
 font-weight: 300;
 font-family: "Open Sans";
 height: 30px;
 width: 10rem;
 padding: .25rem 1rem;
 color: $black;}

.access .helptext {
display: block;
 text-transform: none;
 padding: 0 0 1rem;
 margin-top: -.5rem;
 font-weight: 300;
 color: $color-three;
 font-size: .875rem;
 line-height: 1.375rem;}

.access input[type="submit"] {
background: $color-sixteen;
 color: $white;
 border: 0;
 
 padding: .5rem 1rem;
 font-size: 1.125rem;
 vertical-align: top;
 margin: 0 .75rem 0 0;
font-weight: 400;
 height: 2.5rem;
 width: auto;}

.access input[type="submit"]:hover {background: $color-sixteen;}

.errorlist {color: $color-eighteen;}

.recaptchatable input {border: 1px solid $color-five-important;width: 10rem !important;}

.access .recaptchatable {border: 1px solid #eff3f6 !important}

.access-info {font-size: 1rem;line-height: 1.5rem;margin: 0}

/* USER SEARCH */

.search-users {
width: 100%}

.search-users .left {
background-color: $color-nine;
padding: 10px;
width: 200px;
float: left;
margin:0;
position: relative}

.search-users .right {
box-sizing: border-box;
width: 470px;
float: right}

.search-users .search-item {
padding-bottom:10px}

.search-users .search-item[disabled] {
opacity:0.4;
pointer-events:none}

.search-selected {
padding-bottom:10px}

.search-selected span {
display: inline-block;
width: 100%}
.user-result {
list-style: none;
margin:0}

.user-result .user-result-item p {
display: inline-block;
position: relative}

.select-multiply {
width: 250px;
padding: 10px;
height:350px;
overflow:auto}

.select-multiply input {
padding: 4px 8px;
border: 1px solid $color-seven;
box-sizing: border-box;
font-family:"Open Sans";
color: $color-three;
line-height:1;
margin:-3px 3px 0}

.select-multiply .search {
width: 100%;
margin:0}

.select-multiply ul {
margin:10px 0 0 !important;
padding: 0 !important}

.select-multiply2 input {
padding: 4px 8px;
border: 1px solid $color-seven;
box-sizing: border-box;
font-family:"Open Sans";
color: $color-three;
line-height:1;
margin:-5px 5px 0 0}

.wdd_mark {
box-sizing:content-box;
width: 6px;
height:6px;
border: 1px solid $color-five;
background: #8FA9C7;
position: absolute !important;
bottom:1px;
right:1px}

.deactive {color: $color-five-important;cursor: default}

.dropdown-menu .fa {margin-right: .5rem}

.table-rating-pamm {cursor:ew-resize}

.pamm {
background: $color-nine;
padding: 20px 1rem;
margin:0 0 25px}

.pamm .input {
padding: 4px 8px;
border: 1px solid $color-five;
box-sizing: border-box;
font-family:"Open Sans";
color: $color-three;
margin:0 2px;
width: 150px}

.pamm .sources {
background: $white;
padding: 10px 5px 10px 1rem;
margin:0 0 20px;
display: inline-block}

.pamm .sources ul {
list-style: none;
margin:0}

.pamm .sources li {
float: left;
padding-right:10px}

.pamm .btn {
background: $color-sixteen;
color: $white;
border:0;
padding: 5px 10px;
font-size:.875rem;
vertical-align: top;
margin:0 5px 0 0}

.pamm .btn:hover, .pamm .btn:active, .pamm .btn:focus {background: $color-sixteen;}
.pamm .row {padding: 10px 0}

details {margin:0}
summary {cursor:pointer;font-size:.875rem;color: $color-three;}
summary::-webkit-details-marker {display:none}
summary:focus {outline:0}

.ns-popover-tooltip-theme .ns-popover-tooltip {
 box-shadow: none !important;
 border: 1px solid $color-five-important;
 margin: .5rem 0 0;
 font-size: 1rem;
 padding: 0;
 width: 250px;
 height: 350px;
 overflow: auto;
}
.ns-popover-tooltip-theme .ns-popover-tooltip ul {padding: 0;margin: .75rem 0}
.ns-popover-tooltip-theme .ns-popover-tooltip li { line-height: 1.25rem}
.ns-popover-tooltip-theme .ns-popover-tooltip li a { padding: .25rem 1rem;display: block;}
.ns-popover-tooltip-theme .ns-popover-tooltip li a:hover {background-color: $white;}

.ns-popover-list-theme li a:hover,
.ns-popover-list-theme .list-item a:hover {background-color: $white;border-radius: 0}
.ns-popover-list-theme ul, .ns-popover-list-theme .list {padding: .75rem 0 !important;margin: 0;display: block}
.ns-popover-list-theme li a, .ns-popover-list-theme .list-item a {padding: .25rem 1rem !important;margin: 0 0 0 0 !important;display:block}
.ns-popover-list-theme li.active {font-weight:normal}
.ns-popover-list-theme li.active a {color: $color-three;}

.ns-popover-list-theme {
box-sizing:border-box !important;
border: 1px solid $color-five-important;
z-index: 1001 !important;
background-color: $white;
box-shadow: $dropdown-shadow;
  .business__popup-all {
    a {
      padding: .25rem 0 .75rem !important;
    }
  }
}

.selectKind li {list-style-type:none;margin-bottom: .5rem}
.selectKind li a {
padding: 0 1rem !important;
line-height: 1.25rem;
text-transform: none;
margin: .5rem 0 0 -1rem !important;
}

.table-compare td:first-of-type {font-weight: 600;width: 15rem}

.table-compare .btn {
display: inline-block;
width: 100%;
box-sizing:border-box}

/*
*************
*** OTHER ***
*************
*/

.no {
  &-margin {
    margin: 0 !important;
  }
  &-padding {
    padding: 0;
  }
  &-border {
    tr:last-of-type td {
      border-bottom: 0;
    }
  }
  &-radius {
    border-radius: 0;
  }
  &-height {
    margin: 0 0 -.375rem;
  }
}

.underline {
  border-bottom: none !important;
  span {
    border-bottom: $underline;
  }
}

.positive {
  color: #28c75d;
}

.negative {
  color: $color-eighteen;
}

.bbf {
  &-book {
    display: inline-block;
    border: 0;
    box-sizing: border-box;
    text-align: center;
    font-family: "Open Sans";
    cursor: pointer;
    background: $color-sixteen;
    color: $white-important;
    border-bottom: 0 !important;
    padding: .5rem 1rem;
    font-size: 1.125rem;
  }
}

#chart-container {
  margin: 0;
  padding: 2rem;
  border: 1px solid $color-fourteen;
}

/* Font awesome */

.fa {
  &_right {
    margin-right: .5rem;
    &_small {
      margin-right: .25rem;
    }
  }
  &_left {
    margin-left: .5rem;
    &_small {
      margin-left: .25rem;
    }
    &_medium {
      margin-left: .75rem;
    }
  }
  &_both {
    margin: 0 .75rem 0 .5rem;
    &_large {
      margin: 0 .5rem;
    }
    &_number {
      margin: 0 .25rem;
    }
  }
}

/* CHECKBOX & RADIO */

/* Cначала обозначаем стили для IE8 и более старых версий
т.е. здесь мы немного облагораживаем стандартный чекбокс. */
.checkbox {
vertical-align: top;
margin: 0;
width: 1rem;
height: 1rem;
}
/* Это для всех браузеров, кроме совсем старых, которые не поддерживают
селекторы с плюсом. Показываем, что label кликабелен. */
.checkbox + label {
cursor: pointer}

/* Далее идет оформление чекбокса в современных браузерах, а также IE9 и выше.
Благодаря тому, что старые браузеры не поддерживают селекторы :not и :checked,
в них все нижеследующие стили не сработают. */

/* Прячем оригинальный чекбокс. */
.checkbox:not(checked) {position: absolute;opacity: 0;outline: 0}
.checkbox:not(checked) + label {
position: relative; /* будем позиционировать псевдочекбокс относительно label */
padding: 0 0 0 1.5rem; /* оставляем слева от label место под псевдочекбокс */
display: block;
}

.checkbox_small:not(checked) + label {
padding: 0 0 0 1.375rem !important;
}

/* Оформление первой части чекбокса в выключенном состоянии (фон). */
.checkbox:not(checked) + label:before {
  position: absolute;
  left: 0;
  content: '\f0c8';
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.checkbox_small:not(checked) + label:before {
width: .875rem !important;
height: .875rem !important;
top: 0;
}
/* Оформление второй части чекбокса в выключенном состоянии (переключатель). */
.checkbox:not(checked) + label:after {
content: '';
position: absolute;
left: 0;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.checkbox_small:not(checked) + label:after {
width: .875rem !important;
height: .875rem !important;
top: 0;
}
/* Меняем фон чекбокса, когда он включен. */
.checkbox:checked + label:before {}
/* Сдвигаем переключатель чекбокса, когда он включен. */
.checkbox:checked + label:after {
  content: '\f14a';
}
/* Показываем получение фокуса. */
.checkbox:focus + label:before {}

.radio {
vertical-align: top;
width: 1rem;
height: 1rem;
margin: 0 5px 0 0}
.radio + label {cursor: pointer}
.radio:not(checked) {position: absolute;opacity: 0}
.radio:not(checked) + label {position: relative;padding: 0 0 0 1.5rem;display: block}
.radio:not(checked) + label:before {
  position: absolute;
  margin-top: -.25rem;
  left: 0;
  content: '\f111';
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.radio:not(checked) + label:after {
  position: absolute;
  margin-top: -.25rem;
  left: 0;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.radio:not(checked) + label:hover:after {background: none;}
.radio:checked + label:hover:after, .radio:checked + label:after {
  content: '\f058';
}
.radio:focus + label:before {}

th.header.headerSortDown:after {
  content: '\f33d';
  display: inline-block;
  margin-left: .5rem;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th.header.headerSortUp:after {
  content: '\f341';
  display: inline-block;
  margin-left: .5rem;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.words {
  &__results {
    &-form {
      background: $color-nine;
      padding: 2rem;
      &_content {
        border: 1px solid $color-seven;
        display: flex;
        box-sizing: border-box;
        height: 2.5rem;
      }
      input {
        border: none;
        font-family: "Open Sans";
        width: 100%;
        box-sizing: border-box;
        height: 2.375rem;
        padding: .25rem 2.5rem .25rem 1rem;
        color: $color-twenty-three;
        font-size: .875rem;
      }
      &_tips {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        margin: .5rem 0 0;
        color: $color-three;
        a {
          border-bottom: $underline-dark;
        }
      }
      &_tip {
        font-size: .875rem;
        line-height: 1.375rem;
        &_mask {
          flex-basis: 33%;
        }
        &_def {
          flex-basis: 33%;
        }
        &_img {
          flex-basis: 33%;
          input {
            width: auto !important;
          }
        }
      }
    }
    &-input {
      &_mask {
        flex-basis: 35%;
        border-right: 1px solid $color-six-important;
      }
      &_def {
        flex-basis: calc(70% - 45px);
      }
    }
    &-btn {
      font-size: 1rem;
      padding: .6875rem 1rem;
      color: $color-thirty-important;
      border: none;
      background: $white;
      margin: 0;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    &-content {
      &_title {
        font-size: 1rem;
        margin: 2.5rem 0;
        background: $color-nine;
        padding: 1rem;
      }
    }
    &-info {
      color: $color-three;
      margin: .5rem 0 2.5rem;
      font-size: .875rem;
      line-height: 1.375rem;
    }
  }
  &__result {
    margin: 0 0 2.5rem;
    padding: 0 0 2.5rem;
    border-bottom: 1px solid $color-eight;
    position: relative;
    &_bottom {
      margin: 0;
      padding: 0;
      border-bottom: none;
    }
    &-title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 1.5rem;
      display: inline-block;
      font-weight: 600;
      a {
        color: $black;
      }
      &_top {
        margin-top: 2rem;
      }
      &_capitalize {
        text-transform: capitalize;
      }
      &_moderation {
        font-weight: 400;
        font-size: .875rem;
        color: $color-nine-important;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    &-content {
      &_list {
        margin: 0 0 0 2rem;
        li {
          margin: 0 0 0.5rem;
          line-height: 1.5rem;
          &:last-of-type {
            margin: 0;
          }
          a {
            border-bottom: $underline;
          }
        }
      }
      &_error {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 2.5rem 0 0;
        a {
          border-bottom: $underline;
        }
      }
    }
    &-images {
      margin: 2rem 0 0;
      line-height: 0;
      &_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 .5rem;
        display: inline-block;
        font-weight: 600;
      }
      &_list {
        margin: 0 0 0 1rem;
        &_left {
          margin: -1rem 0 0 2rem;
        }
      }
      &_item {
        margin: 1rem .75rem 0 0;
      }
    }
  }
  &__content {
    margin: 2.5rem 0 0;
    display: flex;
    justify-content: space-between;
    &-block {
      width: calc(33.333% - 10px);
      max-width: calc(33.333% - 10px);
      &_title {
        color: $black;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0 0 1rem !important;
        display: block;
        font-weight: 600;
      }
      &_list {
        list-style-type: none;
        padding: 0;
        margin: 0 !important;
        li {
          font-size: 1rem;
          line-height: 1.5rem;
          margin: 0 0 .5rem;
          &:last-of-type {
            margin: 0;
          }
        }
        a {
          border-bottom: $underline;
        }
      }
    }
  }
}

.content {
    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin-bottom: 2.5rem;
      &.content__container_masonry {
        max-height: 3400px;
        flex-direction: column;
        flex-wrap: wrap;
        width: calc(100% + 32px);
        .content__col {
          width: calc(50% - 32px);
          &:first-of-type, &:nth-child(2) {
            margin: 2.5rem 0 0;
          }
        }
      }
      &_bottom {
        margin-bottom: 0;
      }
    }
    &__col {
      box-sizing: border-box;
      width: calc(50% - 16px);
      margin: 2.5rem 0 0;
      &:first-of-type, &:nth-child(2) {
        margin: 0;
      }
    }
    &__index {
      margin: 2.5rem 0 0;
      padding: 2rem;
      border: 1px solid $color-ten;
      a {
        border-bottom: $underline;
      }
      &_title {
        font-size: 1.375rem;
        line-height: 1.875rem;
        margin: 0 0 1.375rem;
      }
      &_text {
        line-height: 1.375;
        font-size: .875rem;
        color: $color-three;
        border-top: 1px solid $color-ten;
        padding-top: 1rem;
        margin-top: 2rem;
        .content__index_tags {
          a {
            color: $color-three;
          } 
        }
      }
      &_list {
        margin: 0;
        list-style: none;
        li {
          font-size: 1rem;
          line-height: 1.5rem;
          margin: .5rem 0;
          i {
            color: $color-three;
          }
        }
      }
      &_date {
        color: $color-three;
        text-transform: lowercase;
        white-space: nowrap;
      }
      &_tags {
        list-style-type: none;
        margin: 0;
        color: $color-three;
        line-height: 1.375rem;
        font-size: .875rem;
        text-transform: lowercase;
        li {
          display: inline-block;
        }
        &_top {
          margin-top: .75rem;
        }
        &_large {
          line-height: 1.5rem;
          font-size: 1rem;
        }
        &_mag {
          line-height: 1.5rem;
          font-size: 1rem;
          &:first-of-type {
            margin-bottom: 1rem;
            a {
              border-bottom: $underline;
            }
          }
          .tags { 
            display: initial;
            li a {
            line-height: 1.5;
            font-size: 1rem;
            display: initial;
            border-bottom: $underline-important;
            }
          }
        }
        .tags {
          margin: 0;
          li {
            display: inline-block;
            line-height: 1.5;
            a {
              padding: 0;
              border: none;
              color: $color-one;
            }
          }
        }
      }
      &-sentence {
        &_body {
          p {
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0 0 1rem;
          }
          ul, ol {
            margin: 0 0 1rem 2rem;
          }
          li {
            line-height: 1.5rem;
            margin-bottom: .5rem;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
          &_answer {
            margin: 1rem 0 0;
          }
        }
        &_answer {
          color: $color-three-important;
          font-size: .875rem;
          border-bottom: none !important;
          span {
            border-bottom: $underline-important;
            color: $color-one;
          }
        }
      }
    }
}

.share {
  position: fixed;
  display: none;
  top: 5rem;
  width: 2.5rem;
  margin: 0 0 0 -5.5rem;
  li {
    margin: 0 0 1rem 0 !important;
  }
  .ya-share2__container_size_m .ya-share2__icon {
    height: 1.5rem;
    width: 2rem;
    padding: .5rem;
    background: rgba($color-nine, .5) !important;
  }
}

.ya-share2__item_service_vkontakte .ya-share2__icon {
  background: none !important;
}
.ya-share2__item_service_pinterest .ya-share2__icon {
  background: none !important;
}
.ya-share2__item_service_facebook .ya-share2__icon {
  background: none !important;
}
.ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background: none !important;
}

.ya-share2__item_service_vkontakte .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center;
}
.ya-share2__item_service_pinterest .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center;
}
.ya-share2__item_service_facebook .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center;
}
.ya-share2__item_service_odnoklassniki .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center;
}

.ya-share2__item_service_vkontakte .ya-share2__icon::after {
  content: "\f189";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: $black;
}

.ya-share2__item_service_pinterest .ya-share2__icon::after {
  content: "\f231";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: $black;
}

.ya-share2__item_service_facebook .ya-share2__icon::after {
  content: "\f39e";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: $black;
}

.ya-share2__item_service_odnoklassniki .ya-share2__icon::after {
  content: "\f263";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: $black;
}

@import 'sudoku';
@import 'media';