@charset "UTF-8";
/*
************
*** BASE ***
************
*/
body {
  font: 16px "Open Sans", sans-serif, Arial;
  color: #20303c;
  position: relative;
  margin: 0;
  font-weight: 400;
  line-height: 1; }

a, a:focus, a:hover, a:visited {
  color: #65c888;
  cursor: pointer;
  text-decoration: none;
  outline: 0; }

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  font-weight: 600; }

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem 0; }

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0; }

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1.5rem 0; }

blockquote {
  padding: 0 2rem;
  margin: 2rem 0; }
  blockquote p {
    font-weight: 400;
    margin: 1rem 0 !important;
    font-size: 1.375rem !important;
    line-height: 1.875rem !important;
    color: #a3abb0; }
  blockquote:before, blockquote:after {
    display: block;
    width: 50%;
    height: 1px;
    background: #e0e3e5;
    content: ''; }

hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #e0e3e5;
  border-bottom: 1px solid #fff; }

select {
  border: 0;
  padding: 4px 8px;
  margin: 0 2px;
  color: #a3abb0;
  background: #fff;
  line-height: 1; }

input:focus {
  outline: 0; }

input::placeholder {
  color: #c2c7cb; }

textarea::placeholder {
  color: #c2c7cb; }

strong, b {
  font-weight: 600; }

ul, ol {
  padding: 0;
  margin: 2rem 0 0 2rem; }

li {
  line-height: 1; }
  li:last-of-type {
    margin-bottom: 0; }

sup {
  top: -.5rem; }

sub {
  bottom: -.5rem; }

::selection {
  background: rgba(55, 91, 116, 0.25); }

img {
  max-width: 100%; }

/* Table */
table {
  border-collapse: collapse;
  margin: 2rem 0;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  box-sizing: border-box;
  text-align: left;
  border-radius: 0; }
  table thead tr:hover {
    outline: none; }
  table td {
    padding: .75rem 1rem;
    border-bottom: 1px solid #e0e3e5;
    border-right: 1px solid #e0e3e5;
    background: #fff;
    vertical-align: initial; }
    table td:last-of-type {
      border-right: 0; }
  table tr:hover > td:nth-child(odd),
  table tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }
  table th {
    background: #fff;
    border-top: 1px solid #e0e3e5;
    border-bottom: 1px solid #e0e3e5;
    padding: .75rem 1rem;
    font-weight: 600;
    color: #20303c;
    border: 1px solid #e0e3e5;
    border-left: 0; }
    table th:last-of-type {
      border-right: 0; }
  tabletfoot td {
    border-bottom: 0; }

/*
**************
*** HEADER ***
**************
*/
/* MENU */
.nav {
  margin: 0; }

.dropdown:hover > .dropdown-menu {
  display: block;
  margin: 0;
  border: 1px solid #e0e3e5;
  z-index: 1000; }

/* SUBMENU */
.open {
  *z-index: 1000; }

.open > .dropdown-menu {
  display: block; }

.header__logo {
  position: absolute;
  margin: 2rem 0 0;
  width: 1100px; }

.header__logo img {
  height: 3rem; }

.nav__pills li {
  float: left;
  list-style: none; }
  .nav__pills li:last-child > a {
    margin-right: 0; }

.nav__pills:before, .nav__pills:after {
  display: table;
  content: "";
  line-height: 0; }

.nav__pills:after {
  clear: both; }

.nav__pills a {
  border-bottom: none; }

.nav__pills > li > a {
  display: block;
  padding: 0 0 1.25rem;
  color: #20303c;
  margin: 0 1rem 0 0;
  font-size: 1.25rem; }

.nav__pills .dropdown__menu {
  padding: .75rem 0;
  top: 2.25rem;
  margin: 0;
  border: 1px solid #e0e3e5;
  box-shadow: 0.15rem 0.15rem 0 0 #eff1f2; }

.nav__pills .dropdown__menu > li {
  margin: 0;
  padding: 0;
  width: 100%; }

.nav__pills .dropdown__menu > li a {
  padding: .25rem 1rem;
  line-height: 1.25rem;
  color: #20303c;
  display: block; }

.nav__pills .dropdown__menu > li a:active,
.nav__pills .dropdown__menu > li a:visited,
.nav__pills .dropdown__menu > li a:focus {
  background: transparent; }

.nav__pills .dropdown__menu > li:last-child a {
  border-bottom: none; }

.nav__pills .dropdown__menu > li:last-child a:hover {
  border-radius: 0 0 5px 5px; }

.nav__pills .dropdown__menu > li:last-child {
  border-bottom: none; }

.dropdown:hover > .dropdown__menu {
  display: block;
  margin: 0;
  z-index: 1000; }

.nav__pills .open .dropdown__toggle {
  color: #20303c;
  background-color: #fff;
  border-color: #fff; }

.nav > li > a:hover, .nav > li > a:focus {
  background: #fff; }

.nav__tabs {
  border-bottom: 0;
  padding-top: 0;
  border-top: none;
  font-size: .875rem;
  margin: 0; }
  .nav__tabs > li {
    list-style: none; }
    .nav__tabs > li a:hover {
      background: transparent; }
  .nav__tabs li {
    display: inline-block; }
    .nav__tabs li.active a span {
      background: transparent;
      color: #a3abb0;
      border: 0;
      border-bottom: 1px solid transparent;
      line-height: 1.375rem; }
    .nav__tabs li a span {
      line-height: 1.375rem;
      border-bottom: 1px solid #e0e3e5;
      margin: 0 .75rem 0 0; }
  .nav__tabs > .active > a,
  .nav__tabs > .active > a:hover,
  .nav__tabs > .active > a:focus {
    color: #a3abb0;
    border: 0;
    background: transparent;
    cursor: default; }
  .nav__tabs .nav__tab_hidden {
    display: none; }

.nav__tabs > li {
  margin-bottom: 1rem; }

.tab__content {
  position: relative; }
  .tab__content li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }
  .tab__content p {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0; }
    .tab__content p a {
      border-bottom: 1px solid #e0e3e5; }

/* SUBMENU */
.dropdown {
  position: relative; }

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box; }
  .dropdown__menu > li {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
    white-space: nowrap; }

.dropdown__menu > li > a:hover,
.dropdown__menu > li > a:focus,
.dropdown__submenu:hover > a,
.dropdown__submenu:focus > a {
  color: #20303c;
  background: transparent; }

.dropdown__menu > .active > a,
.dropdown__menu > .active > a:hover,
.dropdown__menu > .active > a:focus {
  color: #20303c;
  outline: 0;
  background: #fff; }

.dropdown__menu > .disabled > a,
.dropdown__menu > .disabled > a:hover,
.dropdown__menu > .disabled > a:focus {
  color: #a3abb0; }

.dropdown__menu > .disabled > a:hover,
.dropdown__menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  broker-filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default; }

.open {
  *z-index: 1000; }

.open > .dropdown__menu {
  display: block; }

.dropdown-submenu .nav__pills .open .dropdown__toggle {
  color: #212529; }

/*
***************
*** CONTENT ***
***************
*/
.container {
  width: 1100px;
  margin: 0 auto;
  padding: 3rem 0 0;
  position: relative; }

.content {
  width: 100%;
  min-height: auto; }
  .content__block {
    float: left;
    width: 750px;
    position: relative; }
  .content:before, .content:after {
    display: table;
    line-height: 0;
    content: ""; }
  .content:after {
    clear: both; }

.content [ng-cloak] {
  display: block !important; }

/* AFTER TEXT */
.download {
  padding: 2rem;
  background: #eff1f2;
  margin: 2rem 0; }
  .download a {
    border-bottom: 1px solid #c2c7cb; }
  .download__title {
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
    line-height: 2rem; }
  .download__list {
    margin: 0 0 0 2rem; }
  .download li {
    list-style-type: decimal;
    margin: 0 0 1rem;
    line-height: 1.5rem; }
    .download li:last-of-type {
      margin: 0; }
  .download_top {
    padding: 0 0 1rem;
    color: #a3abb0;
    font-size: 1rem;
    line-height: 1.5rem;
    border-bottom: 1px solid #e0e3e5;
    background: #fff; }
  .download__link {
    margin-bottom: 2.5rem; }
    .download__link-text {
      margin: 1rem 0;
      font-size: 1rem;
      line-height: 1.5rem; }
  .download-page__text {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.5rem; }
  .download-page a {
    border-bottom: 1px solid #e0e3e5; }

/*
**************
*** FOOTER ***
**************
*/
.footer {
  padding: 2rem 0;
  position: relative;
  background: #fff;
  margin: 10rem 0 0;
  color: #20303c;
  border-top: 1px solid #66737c; }
  .footer a {
    color: #20303c; }
  .footer__container {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .footer__nav-list {
    list-style: none;
    float: none;
    margin: 0;
    display: flex;
    align-items: center; }
  .footer__nav li {
    margin: 0 1rem 0 0; }

.moderation {
  position: absolute;
  right: 2.5%;
  margin: 0;
  bottom: 7rem; }
  .moderation a {
    color: #a3abb0;
    display: block;
    margin: .5rem 0 0; }

.header {
  width: 100%;
  position: relative;
  height: 7rem !important;
  background: #fff;
  border-bottom: 1px solid #c2c7cb; }
  .header__container {
    height: 100%;
    position: relative;
    padding: 0; }
  .header__logo {
    position: absolute;
    margin: 2rem 0 0;
    width: 1100px;
    height: 6rem; }
    .header__logo img {
      height: 3rem; }
  .header__nav {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 10.4rem;
    height: calc(100% + 1.25rem); }
    .header__nav-main {
      padding: 0;
      margin: 0;
      position: relative; }
    .header__nav-sub {
      margin: 0;
      list-style: none;
      font-size: .875rem;
      letter-spacing: .025rem;
      text-transform: lowercase; }
      .header__nav-sub li {
        float: left;
        margin-right: 1rem; }
      .header__nav-sub a {
        color: #a3abb0; }
      .header__nav-sub .dropdown__menu {
        padding: 4.6px 0 6.6px;
        margin: 0;
        top: 29px;
        z-index: 10000;
        min-width: inherit;
        border: 1px solid #e0e3e5;
        box-shadow: none; }
        .header__nav-sub .dropdown__menu > li {
          margin: 0;
          float: none; }
          .header__nav-sub .dropdown__menu > li a {
            padding: 2px 10px;
            color: #20303c; }
            .header__nav-sub .dropdown__menu > li a:active, .header__nav-sub .dropdown__menu > li a:visited, .header__nav-sub .dropdown__menu > li a:focus {
              background: #fff; }
          .header__nav-sub .dropdown__menu > li > a {
            display: block; }
          .header__nav-sub .dropdown__menu > li:last-child {
            border-bottom: none; }
            .header__nav-sub .dropdown__menu > li:last-child a {
              border-bottom: none; }
              .header__nav-sub .dropdown__menu > li:last-child a:hover {
                border-radius: 0 0 5px 5px; }
  .header__search.search {
    display: none;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 166.4px);
    height: 100%;
    background-color: #fff;
    padding: 0 0 0 1rem; }
    .header__search.search a {
      color: #20303c;
      font-size: 1.25rem; }
    .header__search.search.is-opened {
      display: block; }
      .header__search.search.is-opened:before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 1001; }
  .header__search-form {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1002; }
  .header__search-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
  .header__search-field {
    width: 100%;
    height: 38px;
    font-size: .875rem;
    flex: 1;
    padding: 0 0 0 1rem;
    border: 1px solid #c2c7cb;
    color: #43515c; }
  .header__search-submit {
    padding: .6875rem 1rem;
    font-size: 1rem;
    border: 0;
    color: #858f96 !important;
    background: #fff;
    position: absolute;
    top: 1px;
    cursor: pointer;
    right: 30px;
    outline: none; }
  .header__search-close {
    height: 40px;
    line-height: 40px;
    margin: 0 0 0 1rem;
    display: block;
    text-align: right;
    color: #fff; }
  .header__trigger {
    color: #20303c; }
  .header__links {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    .header__links a {
      color: #20303c;
      font-size: 1.25rem; }
  .header__account-menu .dropdown__menu {
    top: 3rem;
    left: initial;
    right: 0; }
  .header__account-trigger {
    margin: 0 1rem 0 0; }
  .header__currency {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 25px 0 0; }
    .header__currency-item {
      margin: 0 0 5px; }
      .header__currency-item:last-child {
        margin: 0; }

.breadcrumbs {
  display: inline-block;
  font-size: .875rem;
  margin: 0 0 1rem;
  color: #a3abb0; }
  .breadcrumbs__list {
    list-style: none;
    margin: 0; }
  .breadcrumbs li {
    float: left; }
  .breadcrumbs a {
    color: #a3abb0; }
  .breadcrumbs .fa-angle-right:before {
    margin: 0 .5rem; }

.title {
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0 0 2.25rem 0;
  font-weight: 700; }

/* ARTICLE */
.article {
  margin: 0; }
  .article li {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 1rem 0; }
    .article li:last-of-type {
      margin-bottom: 0; }
  .article__intro {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 0 0 2rem; }
  .article a {
    border-bottom: 1px solid #e0e3e5; }
  .article p {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0; }
    .article p img {
      margin: 0 0 -.375rem; }
  .article h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    font-weight: 400; }
    .article h5:before {
      display: block;
      width: 50%;
      height: 1px;
      background: #e0e3e5;
      content: '';
      margin-bottom: 1rem; }
  .article h6 {
    font-size: .875rem;
    line-height: 1.375rem;
    color: #a3abb0;
    margin: 1rem 0 0;
    font-weight: 400; }
  .article__figure {
    margin: 0;
    width: 750px;
    position: relative;
    line-height: 0; }
    .article__figure-caption {
      line-height: 1.375rem;
      font-size: .875rem;
      padding: 0;
      margin: 1rem 0 0;
      color: #a3abb0; }
      .article__figure-caption span {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: 300; }
    .article__figure-image {
      display: inline-block;
      position: relative;
      width: 750px;
      height: 422px; }
      .article__figure-image:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: .2em;
        font-size: 14px; }
      .article__figure-image img {
        border: 0; }
    .article__figure.test__figure:before {
      display: none; }

.article table {
  border-collapse: collapse;
  width: 750px;
  font-size: 1rem;
  z-index: 900000;
  position: relative;
  display: block;
  overflow: auto; }

.article table ul {
  margin: 1rem 0 1rem 2rem; }

.article table li {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: .5rem 0; }

.article__info .tags {
  margin: .5rem 0 0; }

.article__info {
  color: #a3abb0;
  padding: 1rem 0 0;
  margin: 2rem 0;
  border-top: 1px solid #e0e3e5; }
  .article__info a {
    border-bottom: 1px solid #e0e3e5; }
  .article__info_franchises, .article__info.article__info_franchises {
    padding: 0 0 1rem;
    border-bottom: 1px solid #e0e3e5;
    border-top: none; }
    .article__info_franchises .tags, .article__info.article__info_franchises .tags {
      margin: .5rem 0 0; }
  .article__info-list {
    margin: 0;
    list-style: none; }
  .article__info li {
    margin: 0 0 .5rem 0;
    font-size: 1rem;
    line-height: 1.5rem; }
    .article__info li:last-of-type {
      margin: 0; }
  .article__info_top .article__info-list {
    margin: 0;
    list-style: none; }
  .article__info_bottom {
    margin: 2rem 0 2.5rem; }
  .article__info-small {
    text-transform: lowercase; }
  .article__info-tags {
    display: flex;
    align-items: flex-start; }
    .article__info-tags i {
      margin: .25rem .5rem 0 0; }
    .article__info-tags_list {
      margin: 0;
      text-transform: lowercase; }
      .article__info-tags_list li {
        display: inline-block;
        margin: 0 !important; }

.tooltip {
  position: absolute;
  z-index: 99999;
  display: block;
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.in {
  opacity: 1; }

.tooltip-inner {
  max-width: 250px;
  padding: 1rem;
  color: #fff;
  font-weight: 400;
  text-align: left;
  background: rgba(55, 91, 116, 0.8);
  margin: 0; }

/*
.tooltip-inner:before {
 width:0;
 height: 0;
 border-left: 6px solid transparent;
 border-right: 6px solid transparent;
 border-top: 6px solid $color-three;
 content: '';
 position:absolute;
 left: 50%;
 bottom: 4px;
 margin: 0 0 0 -6px;
 }
 
.dropdown-menu:before {
 width:0;
 height: 0;
 border-left: 6px solid transparent;
 border-right: 6px solid transparent;
 border-bottom: 6px solid #e8eaeb;
 content: '';
 position:absolute;
 left: 20%;
 top: -6px;
 }

 .nav-pills .dropdown:last-of-type .dropdown-menu:before {
 left: 80%;
 }

.ns-popover-list-theme:before {
 width:0;
 height: 0;
 border-left: 5px solid transparent;
 border-right: 5px solid transparent;
 border-bottom: 5px solid #e8eaeb;
 content: '';
 position:absolute;
 left: 20%;
 top: -6px;
 }*/
.additional__title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem 0;
  font-weight: 600; }

/* ASIDE */
.aside {
  float: right;
  width: 300px; }
  .aside__widget {
    margin: 0 0 2.5rem; }
  .aside .aside__content {
    margin: -1rem 0 0; }

.asidemenu {
  display: none; }

.search {
  position: relative;
  right: 0;
  top: 0;
  margin: -1rem 0 2.25rem; }
  .search__field {
    font-family: "Open Sans";
    width: 300px;
    height: 2.5rem;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #c2c7cb;
    padding: .25rem 2.5rem .25rem 1rem;
    color: #20303c;
    font-size: .875rem; }
    .search__field:focus {
      background: #fff;
      cursor: text;
      outline: 0; }
  .search__submit {
    padding: .563rem 1rem;
    font-size: 1rem;
    margin: 0;
    background: none;
    color: #a3abb0 !important;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none !important; }

.category__text {
  margin: 0 0 2.5rem;
  padding: 2rem 2rem .5rem;
  border: 1px solid #e0e3e5; }
  .category__text li {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.625rem; }
  .category__text p {
    margin: 0 0 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem; }
  .category__text a {
    border-bottom: 1px solid #e0e3e5; }

.category__tags {
  margin-bottom: .5rem;
  overflow: hidden;
  text-transform: lowercase;
  line-height: 1.5rem;
  font-size: .875rem; }
  .category__tags a {
    color: #a3abb0 !important;
    margin-right: .25rem; }

.post__sticky {
  margin: 0 0 2.5rem;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #edeff0;
  padding: 0 0 2.5rem; }
  .post__sticky-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: block; }
  .post__sticky a {
    color: #20303c;
    border-bottom: 1px solid #e0e3e5; }
  .post__sticky-body p {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px; }
  .post__sticky-body a {
    color: #20303c; }
  .post__sticky-body img {
    float: left;
    margin: 0 2rem 0 0;
    width: 250px; }
  .post__sticky-text {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px; }

.post {
  margin: 0 0 2.5rem;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0e3e5;
  padding: 0 0 2.5rem; }
  .post__header {
    width: calc(100% - 282px);
    margin: 0 0 0 282px; }
  .post__title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: block;
    font-weight: 600; }
  .post__tags {
    margin: 0 0 .5rem 282px;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.375rem;
    font-size: .875rem; }
    .post__tags a {
      color: #a3abb0 !important;
      margin: 0 .25rem 0 0; }
  .post a {
    color: #20303c; }
  .post__body p, .post__body_doc p {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px; }
  .post__body img, .post__body_doc img {
    width: 250px; }
  .post__body_doc p, .post__body_doc_doc p {
    margin: 0; }
  .post__body_doc .post__header, .post__body_doc_doc .post__header {
    width: 100%;
    margin: 0; }
  .post__body_doc .post__text, .post__body_doc_doc .post__text {
    margin: 0; }
  .post__body_doc .post__tags, .post__body_doc_doc .post__tags {
    margin: 0 0 .5rem; }
  .post__body_test a, .post__body_doc_test a {
    border-bottom: none; }
  .post__thumb {
    float: left;
    margin: 0 2rem 0 0;
    width: 250px;
    line-height: 0;
    position: relative;
    display: block; }
    .post__thumb:before {
      content: '';
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: rgba(0, 0, 0, 0.4);
      color: #fff; }
    .post__thumb img {
      padding: 0; }
  .post__text {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 0 282px; }
  .post__list {
    margin: 1rem 0 0 297px;
    font-size: .875rem; }
    .post__list li {
      line-height: 1; }
  .post_index:last-of-type {
    margin: 0; }

.survey__results {
  margin: 0; }
  .survey__results.has-selected .survey__total {
    padding: 0 1rem; }
  .survey__results.has-selected.survey__results_finish .survey__option {
    cursor: default; }

.survey__total {
  border-bottom: 1px solid #c2c7cb;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  width: 100%; }
  .survey__total:first-of-type {
    border-top: 1px solid #c2c7cb; }

.survey__rate {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(60, 199, 197, 0.2); }
  .survey__rate.full {
    width: 100%;
    right: 0; }

.survey__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 5;
  cursor: pointer;
  font-weight: 400;
  box-sizing: content-box;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .75rem 0; }
  .survey__option_finish {
    cursor: default; }

.survey__percent {
  font-size: 1rem;
  margin: 0 0 0 1rem; }

.survey__info {
  font-size: .875rem;
  margin: 1rem 0 0; }
  .survey__info-text {
    line-height: 1.375rem;
    padding: 0;
    margin: 0;
    color: #a3abb0; }

.survey__wrap {
  padding: 2rem;
  border: 1px solid #c2c7cb;
  font-weight: 300; }
  .survey__wrap .survey__info {
    text-align: right;
    line-height: 1.375rem; }
    .survey__wrap .survey__info a {
      display: block; }
  .survey__wrap .survey__title_small {
    font-size: 1.375rem;
    line-height: 1.875rem;
    margin: 0 0 1.375rem; }

.survey__list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .survey__list.has-selected .survey__list-item_content {
    padding: .5rem 0 .5rem .5rem; }
  .survey__list-archive {
    margin: 0;
    list-style: none; }
    .survey__list-archive li {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid #e0e3e5;
      border-top: 0 !important;
      padding: 0 0 2.5rem;
      font-weight: 600; }
    .survey__list-archive a {
      color: #20303c; }
    .survey__list-archive .tags {
      margin-bottom: .5rem;
      overflow: hidden;
      text-transform: lowercase;
      line-height: 1.5rem;
      font-size: .875rem; }
    .survey__list-archive .tags a {
      color: #a3abb0 !important;
      margin-right: .25rem; }
  .survey__list-item {
    font-size: .875rem;
    line-height: 1.375rem;
    box-sizing: content-box;
    cursor: pointer;
    border-bottom: 1px solid #c2c7cb;
    position: relative; }
    .survey__list-item:first-of-type {
      border-top: 1px solid #c2c7cb; }
    .survey__list-item .total {
      height: 100%; }
    .survey__list-item_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 300;
      height: 100%;
      padding: .5rem 0; }
    .survey__list-item_percent {
      margin: 0 .5rem 0 1rem; }
    .survey__list-item_rate {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: rgba(60, 199, 197, 0.2); }
    .survey__list-item_name {
      flex: 1; }
    .survey__list-item_name, .survey__list-item_percent {
      position: relative; }

.survey__tmp .survey__percent {
  margin: 0 0 0 1rem; }

.tags {
  list-style: none;
  margin: 0 0 2.25rem; }
  .tags li {
    display: inline; }
  .tags a {
    display: inline-block;
    color: #a3abb0;
    font-size: .875rem;
    padding: .5rem;
    margin: 0 0 .25rem;
    border: 1px solid #e0e3e5; }

/* PAGINATOR */
.paginator {
  list-style: none;
  margin: 0 0 -.125rem;
  font-size: .875rem;
  display: inline-block; }
  .paginator li {
    float: left; }
  .paginator a, .paginator a:hover, .paginator a:visited {
    display: block;
    color: #20303c;
    padding: .5rem .75rem;
    border: 1px solid transparent; }
  .paginator .current {
    color: #a3abb0;
    padding: .5rem;
    border: 1px solid #e0e3e5; }
  .paginator .dots {
    color: #20303c;
    border: 1px solid transparent; }

/* FEATURED INDEX */
.featured {
  margin: 0 0 3rem; }
  .featured__content {
    display: flex;
    justify-content: space-between; }
  .featured__post {
    position: relative; }
    .featured__post:nth-child(3), .featured__post:nth-child(4) {
      margin-bottom: 0; }
    .featured__post_large {
      margin: 0; }
      .featured__post_large .featured__thumb {
        width: 550px;
        height: 310px; }
      .featured__post_large .featured__post-content_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 2rem; }
    .featured__post-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      color: #fff; }
      .featured__post-content_date {
        margin: 0 2rem 2rem;
        font-size: .875rem; }
      .featured__post-content_title {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 1rem;
        font-weight: 600;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); }
    .featured__post-link {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 5; }
  .featured__thumb {
    width: 275px;
    height: 155px;
    overflow: hidden; }
    .featured__thumb:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      z-index: 2; }
    .featured__thumb img {
      max-width: 100%; }
  .featured__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.img__desc {
  padding: 0;
  float: left;
  margin: 0 25px 8px 0;
  width: 300px;
  border-radius: 0; }

.test__btn {
  margin: 2.5rem 0 0;
  padding: 2.5rem 0 0;
  border-top: 1px solid #e0e3e5;
  text-align: center; }
  .test__btn .btn {
    padding: .6875rem 1rem;
    text-shadow: none;
    line-height: 1;
    box-shadow: none; }

.test a {
  border-bottom: 1px solid #e0e3e5; }

.test__header {
  margin: 0;
  line-height: 0; }
  .test__header img {
    margin: 0 0 2rem; }

.test__description p {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 2rem 0 0; }

.test__title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0 0 2rem; }

.test__subtitle {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem 0;
  font-weight: 600; }

.test__list {
  margin: 0 0 1rem;
  font-size: .875rem;
  list-style-type: none; }

.test__rate {
  width: 0; }

.test__option {
  color: #a3abb0;
  display: flex; }

.test__form-list {
  margin: 0 0 2rem;
  list-style: none; }
  .test__form-list li {
    padding: 0 1rem;
    border-top: 1px solid #c2c7cb;
    font-size: 1rem;
    line-height: 1.5rem; }
    .test__form-list li:last-of-type {
      border-bottom: 1px solid #c2c7cb; }
    .test__form-list li label {
      padding: .75rem 0 .75rem 1.5rem !important; }
    .test__form-list li .radio:not(checked) + label:before {
      top: 1rem; }
    .test__form-list li .radio:not(checked) + label:after {
      top: 1rem; }

.test__figure {
  display: block;
  margin: 0;
  line-height: 0; }
  .test__figure:before {
    display: none; }
  .test__figure-caption {
    line-height: 1.375rem;
    font-size: .875rem;
    padding: 0;
    margin: 1rem 0 0;
    color: #a3abb0; }
  .test__figure-image {
    display: inline-block;
    position: relative;
    width: 750px;
    height: 422px; }
    .test__figure-image:before {
      content: '';
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: rgba(0, 0, 0, 0.4);
      color: #fff; }
    .test__figure-image img {
      margin: 0; }

.test .survey__results_finish {
  margin: 0 0 2.5rem; }

/* RELAP */
.relap-default__item-thumbnail {
  position: relative;
  display: block; }
  .relap-default__item-thumbnail:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: .2em; }
  .relap-default__item-thumbnail img {
    border-radius: 0 !important;
    float: inherit;
    width: 100%; }

.relap-default__view-strings.relap-default__with-brand .relap-default__items-container {
  padding-bottom: 0 !important; }

.relap-default__top-container.relap-default__view-strings {
  padding-top: 0 !important; }

.relap-default__top-container {
  padding: 0 !important; }

.relap-default__view-strings .relap-default__item, .relap-default__view-strings .relap-default__fake-item {
  min-width: 290px !important; }

.relap-default__logo, .relap-default__logo__icon svg {
  display: none !important; }

.relap-default__view-strings .relap-default__item-thumbnail {
  margin: 5px 20px 0 0 !important; }

.relap-default__full-item-link .relap-default__item-inner:hover {
  background: none !important; }

.relap-default__item-title {
  color: #20303c !important; }

.search-results {
  margin: 0 0 2.5rem;
  position: relative; }
  .search-results__field {
    font-family: "Open Sans";
    width: 100%;
    box-sizing: border-box;
    height: 2.5rem;
    background: #fff;
    border: 1px solid #c2c7cb;
    padding: .25rem 2.5rem .25rem 1rem;
    color: #43515c;
    font-size: .875rem;
    text-overflow: ellipsis; }
  .search-results .submit {
    padding: .6875rem 1rem;
    font-size: 1rem;
    border: 0;
    background: none;
    color: #858f96 !important;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    outline: none; }
  .search-results__group {
    position: relative; }
  .search-results__list {
    padding: 0;
    margin: 0; }
    .search-results__list-item {
      margin: 2.5rem 0;
      border-bottom: 1px solid #e0e3e5;
      padding: 0 0 2.5rem;
      font-size: .875rem;
      line-height: 1.375rem; }
    .search-results__list hlword {
      font-weight: 600;
      background: rgba(60, 199, 197, 0.2); }
    .search-results__list-title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 1.5rem;
      display: block;
      font-weight: 600; }
      .search-results__list-title a, .search-results__list-title a:hover, .search-results__list-title a:visited {
        color: #20303c; }
      .search-results__list-title hlword {
        font-weight: 600;
        background: none; }
    .search-results__list-date {
      margin-top: .5rem;
      overflow: hidden;
      line-height: 1.375rem;
      font-size: .875rem;
      color: #a3abb0; }

.search__submit {
  padding: .563rem 1rem;
  font-size: 1rem;
  margin: 0;
  background: none;
  color: #a3abb0 !important;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none !important; }

.error {
  font-size: 1rem;
  line-height: 1.5rem; }
  .error-page__text {
    line-height: 1.5rem; }
    .error-page__text a {
      border-bottom: 1px solid #e0e3e5; }

.sentence {
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #e0e3e5;
  padding: 0 0 2.5rem; }
  .sentence a {
    border-bottom: 1px solid #e0e3e5; }
  .sentence__tags {
    color: #a3abb0;
    line-height: 1.375rem;
    font-size: .875rem;
    text-transform: lowercase; }
    .sentence__tags_top {
      margin: 1rem 0 0; }
    .sentence__tags_dark {
      text-transform: initial; }
      .sentence__tags_dark a {
        border-bottom: 1px solid #c2c7cb;
        text-transform: lowercase; }
  .sentence__body p {
    font-size: 1rem;
    margin: 0 0 1rem;
    line-height: 1.5rem; }
  .sentence__body ul, .sentence__body ol {
    margin: 0 0 1rem 2rem; }
  .sentence__body li {
    margin: 0 0 .5rem;
    line-height: 1.5rem; }
    .sentence__body li:last-of-type {
      margin: 0; }
  .sentence__answer {
    background: #eff1f2;
    padding: 2rem 2rem 1rem;
    margin: 1.5rem 0 0; }
    .sentence__answer .sentence__body {
      display: flex; }
      .sentence__answer .sentence__body .far {
        line-height: 1.5rem; }
  .sentence__show-answer {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 -.5rem; }
    .sentence__show-answer a {
      border-bottom: 0;
      color: #a3abb0; }
      .sentence__show-answer a span {
        border-bottom: 1px solid #e0e3e5 !important;
        color: #65c888; }
  .sentence__author {
    margin: 0 0 .5rem;
    color: #a3abb0;
    line-height: 1.375rem;
    font-size: .875rem; }
  .sentence__quote {
    margin: 2.5rem 0 0;
    border: 0;
    padding: 2rem;
    background: #eff1f2; }
    .sentence__quote p {
      margin: 0; }
    .sentence__quote .sentence__author {
      margin: 1rem 0 0; }
    .sentence__quote a {
      border-bottom: 1px solid #c2c7cb; }

.calc {
  border: none;
  padding: 0;
  margin: 0 0 2.5rem; }
  .calc a {
    border-bottom: 1px solid #e0e3e5; }
  .calc .popover {
    color: #65c888;
    border-bottom: .75rem solid transparent; }
  .calc__content {
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid #c2c7cb;
    padding: 1rem 2rem; }
    .calc__content-dash {
      margin: 0 .25rem 0 0; }
      .calc__content-dash.calc__content-dash_medium, .calc__content-dash_medium {
        margin: 0 .5rem 0 0; }
      .calc__content-dash.calc__content-dash_large, .calc__content-dash_large {
        margin: 0 .75rem 0 .5rem; }
    .calc__content-title {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin: 1rem 0;
      font-weight: 600; }
    .calc__content-popover {
      color: #65c888;
      border-bottom: .5rem solid transparent;
      display: initial; }
    .calc__content-col_medium input, .calc__content-col_medium .calc__content-group_input {
      width: 75px; }
    .calc__content-group {
      padding: 1rem 0; }
      .calc__content-group .checkbox:not(checked) + label:before {
        top: 0; }
      .calc__content-group .checkbox:not(checked) + label:after {
        top: 0; }
      .calc__content-group_line_height {
        line-height: 0; }
      .calc__content-group_change {
        position: absolute;
        right: 70%; }
      .calc__content-group_textarea {
        position: relative;
        z-index: 10;
        min-height: 10rem !important;
        margin: 0;
        width: 100% !important;
        box-sizing: border-box;
        font-family: "Open Sans";
        font-weight: 400;
        border: 1px solid #c2c7cb;
        outline: 0;
        padding: 1rem;
        font-size: .875rem;
        line-height: 1.375rem;
        color: #43515c; }
        .calc__content-group_textarea_small {
          min-height: 5rem !important; }
      .calc__content-group_img, .calc__content-group.calc__content-group_img {
        vertical-align: top; }
        .calc__content-group_img:first-of-type, .calc__content-group.calc__content-group_img:first-of-type {
          margin: 0 2rem 0 0; }
        .calc__content-group_img:nth-child(2), .calc__content-group.calc__content-group_img:nth-child(2) {
          margin: 0 2rem 0 0; }
        .calc__content-group_img:nth-child(3), .calc__content-group.calc__content-group_img:nth-child(3) {
          margin: 2rem 2rem 0 0; }
        .calc__content-group_img:nth-child(4), .calc__content-group.calc__content-group_img:nth-child(4) {
          margin: 2rem 2rem 0 0; }
      .calc__content-group_settings, .calc__content-group.calc__content-group_settings {
        font-size: .875rem;
        color: #a3abb0; }
      .calc__content-group_bottom_small, .calc__content-group.calc__content-group_bottom_small {
        padding: 1rem 0 .5rem; }
      .calc__content-group_top, .calc__content-group.calc__content-group_top {
        padding: 1rem 0 0; }
        .calc__content-group_top_large, .calc__content-group.calc__content-group_top_large {
          padding: 2rem 0 0; }
      .calc__content-group_bottom, .calc__content-group.calc__content-group_bottom {
        padding: 0 0 1rem; }
      .calc__content-group_commision, .calc__content-group.calc__content-group_commision {
        padding: 0;
        margin: 1rem 0; }
      .calc__content-group_checkbox input, .calc__content-group.calc__content-group_checkbox input {
        border: 1px solid #e0e3e5;
        padding: 4px 8px;
        font-size: .875rem;
        line-height: 1;
        color: #a3abb0;
        font-family: "Open Sans";
        margin: 0 2px; }
      .calc__content-group_compare {
        margin: -1rem 0;
        padding: 0; }
      .calc__content-group_capitalize {
        text-transform: capitalize; }
      .calc__content-group_input {
        border: 1px solid #c2c7cb;
        margin: 0 .25rem 0 0;
        font-family: "Open Sans";
        font-size: .875rem;
        height: 30px;
        width: 10rem;
        padding: .25rem 1rem;
        z-index: 1000;
        color: #43515c; }
        .calc__content-group_input.calc__content-group_input_medium, .calc__content-group_input_medium {
          width: 75px; }
        .calc__content-group_input.calc__content-group_input_short, .calc__content-group_input_short {
          width: 5rem; }
        .calc__content-group_input_url {
          width: 100%;
          box-sizing: border-box;
          height: 40px; }
      .calc__content-group_title {
        font-weight: 600;
        margin-bottom: 1rem;
        display: block; }
        .calc__content-group_title_bottom {
          margin-bottom: .5rem;
          border-bottom: .5rem solid transparent; }
      .calc__content-group_subtitle {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin: 1rem 0;
        font-style: italic;
        font-weight: 600; }
      .calc__content-group_description {
        display: block;
        padding: 0 0 1rem;
        margin-top: -.375rem;
        color: #a3abb0;
        font-size: .875rem;
        line-height: 1.375rem; }
    .calc__content-results {
      margin: 2rem 0 1rem; }
      .calc__content-results_line_height {
        line-height: 0; }
      .calc__content-results_top_small, .calc__content-results.calc__content-results_top_small {
        margin: 1rem 0 !important; }
      .calc__content-results_title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 600;
        margin: 0 0 1.5rem; }
      .calc__content-results_subtitle {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin: 1rem 0;
        font-style: italic;
        font-weight: 600; }
      .calc__content-results_list {
        margin: 0;
        list-style: none; }
        .calc__content-results_list li {
          margin: .5rem 0 0;
          line-height: 1.5rem; }
        .calc__content-results_list.calc__content-results_reset, .calc__content-results_list_reset {
          padding: 0;
          margin: 0; }
          .calc__content-results_list.calc__content-results_reset li, .calc__content-results_list_reset li {
            list-style: none; }
            .calc__content-results_list.calc__content-results_reset li:first-of-type, .calc__content-results_list_reset li:first-of-type {
              margin-top: 5px; }
            .calc__content-results_list.calc__content-results_reset li:last-of-type, .calc__content-results_list_reset li:last-of-type {
              margin-bottom: 0; }
              .calc__content-results_list.calc__content-results_reset li:last-of-type input, .calc__content-results_list_reset li:last-of-type input {
                margin-bottom: 0; }
        .calc__content-results_list.calc__content-results_disc, .calc__content-results_list_disc {
          list-style: disc !important;
          margin: 1rem 0 0 2rem !important; }
      .calc__content-results_small, .calc__content-results.calc__content-results_list_small {
        margin: 1rem 0 !important; }
    .calc__content-list {
      margin: 0;
      list-style: none; }
      .calc__content-list .radio:not(checked) + label:before {
        margin-top: 0; }
      .calc__content-list .radio:not(checked) + label:after {
        margin-top: 0; }
      .calc__content-list_reset {
        padding: 0;
        margin: 0;
        list-style: none; }
        .calc__content-list_reset li:first-of-type {
          margin-bottom: 1rem; }
        .calc__content-list_reset li:last-of-type {
          margin-bottom: 0; }
          .calc__content-list_reset li:last-of-type input {
            margin-bottom: 0; }
        .calc__content-list_reset_top li:first-of-type, .calc__content-list_reset.calc__content-list_reset_top li:first-of-type {
          margin-top: 0; }
        .calc__content-list_reset_all, .calc__content-list_reset.calc__content-list_reset_all {
          margin: 0; }
      .calc__content-list_align {
        display: flex;
        flex-wrap: wrap; }
        .calc__content-list_align .calc__content-dash {
          margin: 0 .5rem 0 0; }
        .calc__content-list_align li:first-of-type {
          margin: 0 0 .5rem; }
        .calc__content-list_align li {
          margin: 0 0 .5rem; }
      .calc__content-list_row {
        display: flex; }
        .calc__content-list_row input {
          display: block;
          margin: 0 .25rem 0 0; }
        .calc__content-list_row .calc__content-group {
          margin: 0 .75rem 0 0; }
          .calc__content-list_row .calc__content-group_title {
            border-bottom: none; }
            .calc__content-list_row .calc__content-group_title_bottom {
              margin-bottom: .5rem;
              border-bottom: .5rem solid transparent; }
          .calc__content-list_row .calc__content-group:last-of-type input {
            display: inline-block; }
        .calc__content-list_row .commission input {
          display: inline-block; }
    .calc__content-table_both {
      margin: 1rem 0; }
    .calc__content-table_margin_bottom {
      margin-bottom: 0; }
    .calc__content-table .radio:not(checked) + label:before {
      margin-top: 0; }
    .calc__content-table .radio:not(checked) + label:after {
      margin-top: 0; }
    .calc__content .btn {
      padding: .5rem 1rem;
      font-size: 1rem;
      vertical-align: top;
      margin: 0 .75rem 0 0;
      border-bottom: 1px solid; }
      .calc__content .btn:focus {
        outline: none; }
  .calc__list {
    margin: 0;
    list-style-type: none; }
    .calc__list-body {
      border-bottom: 1px solid #edeff0;
      padding-bottom: 2.5rem;
      margin: 0 0 2.5rem; }
      .calc__list-body_title {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0;
        padding: 0; }
        .calc__list-body_title a {
          color: #20303c; }
  .calc__tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem; }
    .calc__tags a {
      color: #a3abb0;
      margin-right: .25rem; }

/* CALC 15 & 16 */
.inputDisplay {
  border: 1px solid #e0e3e5 !important;
  min-height: 10rem !important;
  margin: 0 0 2rem !important;
  padding: 1rem !important;
  color: #20303c !important; }

.calcBtn {
  width: 116.8px !important;
  margin: 0 1rem 1rem 0 !important;
  font-size: 1rem !important; }

.calcBtn:active {
  top: 0 !important; }

.calcBtn:nth-child(6n) {
  margin: 0 0 1rem 0 !important; }

.calc .enterBtn {
  height: 96px;
  margin: 0 0 -40px 0 !important; }

.calc .gray, .calc .red {
  color: #20303c !important;
  border: 1px solid #eff3f6 !important;
  background: #fff; }

.calc .white {
  color: #20303c !important;
  border: 1px solid #edeff0 !important; }

.calculator {
  margin-bottom: -1rem !important; }

.outOld {
  padding: .5rem 0 !important;
  margin-bottom: .5rem !important;
  border-bottom: 1px solid #edeff0 !important; }

.inOld {
  padding-top: 0 !important; }

.mathquill-rendered-math {
  font-size: 1rem !important; }

.lineId {
  color: #65c888; }

.calcBtn[disabled] {
  opacity: .1 !important; }

.btn {
  text-align: center;
  font-family: "Open Sans";
  cursor: pointer;
  border: 1px solid #65c888;
  color: #65c888;
  background: #fff;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: top;
  margin: 0 1rem 0 0;
  display: inline-block;
  outline: 0;
  box-shadow: none; }
  .btn.btn_fire {
    font-size: 1rem; }
  .btn.btn_clean {
    color: #a3abb0 !important;
    padding: .5rem 0 0;
    border: 0;
    font-size: 1rem;
    box-shadow: none; }
    .btn.btn_clean:hover, .btn.btn_clean:focus {
      background: #fff; }
  .btn_org, .btn.btn_org {
    border: 1px solid;
    padding: .4375rem 1rem;
    margin-top: 1rem;
    margin-right: .75rem;
    display: inline-block; }
  .btn.btn_auto {
    width: auto; }
  .btn_left_small {
    margin: 0 !important;
    margin-left: .25rem !important;
    background: #fff !important;
    color: #20303c !important;
    box-shadow: none;
    padding: 0 !important;
    border: 0 !important;
    letter-spacing: 0; }
    .btn_left_small:hover {
      background: #fff !important; }
  .btn_right_small {
    margin-right: -.25rem !important;
    background: #fff !important;
    color: #20303c !important;
    margin-left: .5rem !important;
    box-shadow: none;
    line-height: 40px;
    padding: 0 !important;
    border: 0 !important;
    letter-spacing: 0; }
    .btn_right_small:hover {
      background: #fff !important; }
  .btn_top_large {
    padding: .6875rem 1rem; }

.stars {
  color: #faad4d; }

.star_names {
  color: #fff;
  margin: 0 0 0 .25rem;
  font-weight: 300;
  text-transform: lowercase;
  font-size: .875rem;
  line-height: .875rem; }

.star_empty {
  color: #c2c7cb !important; }

.star__name {
  display: none; }
  .star__name.active {
    display: inline-block; }

.calc__content tr:hover > td:nth-child(odd),
.calc__content tr:hover > td:nth-child(even) {
  background: #eff1f2; }

.calc__content-table_top {
  margin: 2rem 0 1rem !important; }

.calc__content-table_top_small {
  margin: 1rem 0 !important; }

.calc__content-results_top_small {
  margin: 1rem 0 !important; }

.calc__content-table {
  padding: 0;
  line-height: 1.5rem; }
  .calc__content-table_border, .calc__content-table.calc__content-table_border {
    margin: 0 !important;
    width: 0; }
    .calc__content-table_border th, .calc__content-table.calc__content-table_border th {
      border: 0 !important;
      padding: 0 0 1.5rem;
      line-height: 0; }
    .calc__content-table_border td, .calc__content-table.calc__content-table_border td {
      padding: 0;
      border-bottom: 0;
      border-right: 0; }
    .calc__content-table_border tr:last-of-type td, .calc__content-table.calc__content-table_border tr:last-of-type td {
      border-bottom: 0 !important; }
    .calc__content-table_border tr:last-of-type td input, .calc__content-table.calc__content-table_border tr:last-of-type td input {
      margin: 0 !important; }
    .calc__content-table_border input, .calc__content-table.calc__content-table_border input {
      margin: 0 1rem 1rem 0 !important; }
    .calc__content-table_border tr:hover > td:nth-child(odd),
    .calc__content-table_border tr:hover > td:nth-child(even), .calc__content-table.calc__content-table_border tr:hover > td:nth-child(odd),
    .calc__content-table.calc__content-table_border tr:hover > td:nth-child(even) {
      background: #fff !important; }
  .calc__content-table_hover, .calc__content-table.calc__content-table_hover {
    margin: 0 0 2rem !important; }
    .calc__content-table_hover tr:first-of-type td, .calc__content-table.calc__content-table_hover tr:first-of-type td {
      border-top: 1px solid #edeff0; }
    .calc__content-table_hover tr:hover > td:nth-child(odd),
    .calc__content-table_hover tr:hover > td:nth-child(even), .calc__content-table.calc__content-table_hover tr:hover > td:nth-child(odd),
    .calc__content-table.calc__content-table_hover tr:hover > td:nth-child(even) {
      background: #fff !important; }
  .calc__content-table_border_bottom tr:last-of-type td, .calc__content-table.calc__content-table_border_bottom tr:last-of-type td {
    border-bottom: 0 !important; }
  .calc__content-table_border_bottom tr:last-of-type td:first-of-type, .calc__content-table.calc__content-table_border_bottom tr:last-of-type td:first-of-type {
    border-radius: 0 0 0 3px; }
  .calc__content-table_border_bottom tr:last-of-type td:last-of-type, .calc__content-table.calc__content-table_border_bottom tr:last-of-type td:last-of-type {
    border-radius: 0 0 3px 0; }
  .calc__content-table_full, .calc__content-table.calc__content-table_full {
    width: 100%; }
    .calc__content-table_full .cap, .calc__content-table.calc__content-table_full .cap {
      text-transform: capitalize; }
    .calc__content-table_full input, .calc__content-table.calc__content-table_full input {
      margin: 0 !important; }
  .calc__content-table_auto, .calc__content-table.calc__content-table_auto {
    width: 100%;
    overflow: auto;
    display: block;
    cursor: ew-resize; }
  .calc__content-table_top, .calc__content-table.calc__content-table_top {
    margin: 2rem 0 1rem !important; }
  .calc__content-table_translit tr:hover > td:nth-child(odd),
  .calc__content-table_translit tr:hover > td:nth-child(even) {
    background: #fff !important; }
  .calc__content-table_margin_bottom, .calc__content-table.calc__content-table_margin_bottom {
    margin-bottom: 0 !important; }
  .calc__content-table_white_space {
    white-space: nowrap; }

.calc-list {
  margin: 0;
  list-style-type: none; }
  .calc-list__body {
    border-bottom: 1px solid #e0e3e5;
    padding: 0 0 2.5rem;
    margin: 0 0 2.5rem; }
  .calc-list__title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    display: block;
    font-weight: 600; }
    .calc-list__title a {
      color: #20303c; }
  .calc-list__tags {
    margin: 0 0 .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.375rem;
    font-size: .875rem; }
    .calc-list__tags a {
      color: #a3abb0 !important;
      margin: 0 .25rem 0 0; }

.compare {
  margin: 0;
  list-style: none;
  position: static;
  font-weight: 400;
  right: initial; }
  .compare__review-count a:first-of-type {
    margin-right: .75rem;
    color: #28c75d; }
  .compare__review-count a:last-of-type {
    color: #f2564d; }
  .compare a {
    margin: 0; }
  .compare__block {
    line-height: 1.5rem;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    overflow: hidden; }
  .compare__error {
    margin: 1.5rem 0;
    font-size: 1rem;
    line-height: 1.5rem; }
    .compare__error a {
      border-bottom: 1px solid #e0e3e5; }
  .compare__table td:first-of-type {
    font-weight: 600;
    width: 15rem; }
  .compare__table .btn {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid; }
    .compare__table .btn.btn_auto {
      width: auto; }
  .compare__table th a {
    color: #20303c; }
  .compare__table td a {
    border-bottom: 1px solid #e0e3e5; }

.broker__data, .insurance__data, .bank__data {
  position: relative; }

.broker__info, .insurance__info, .bank__info {
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
  padding: 2rem;
  border: 1px solid #e0e3e5;
  color: #a3abb0; }
  .broker__info a, .insurance__info a, .bank__info a {
    border-bottom: 1px solid #e0e3e5; }

.broker__table, .insurance__table, .bank__table {
  margin: 2rem 0 0;
  line-height: 1.5rem; }
  .broker__table td:last-of-type, .insurance__table td:last-of-type, .bank__table td:last-of-type {
    text-align: left; }
  .broker__table td:first-of-type, .insurance__table td:first-of-type, .bank__table td:first-of-type {
    vertical-align: top;
    width: 42%; }
  .broker__table th:last-of-type, .insurance__table th:last-of-type, .bank__table th:last-of-type {
    text-align: left; }
  .broker__table a, .insurance__table a, .bank__table a {
    border-bottom: 1px solid #e0e3e5; }

.broker__content_overflow, .insurance__content_overflow, .bank__content_overflow {
  overflow: auto;
  margin: 0 0 2.5rem; }

.broker__group, .insurance__group, .bank__group {
  margin: 0 0 2.5rem; }
  .broker__group-title, .insurance__group-title, .bank__group-title {
    margin: 0 0 2rem; }

.broker__compare, .insurance__compare, .bank__compare {
  margin: 0;
  list-style: none;
  position: absolute;
  right: 1rem;
  line-height: 49px;
  font-size: .875rem; }
  .broker__compare li, .insurance__compare li, .bank__compare li {
    float: left; }
  .broker__compare a, .insurance__compare a, .bank__compare a {
    margin: 0 0 0 1rem;
    color: #65c888; }
  .broker__compare-add, .insurance__compare-add, .bank__compare-add {
    float: right; }
  .broker__compare-main a, .insurance__compare-main a, .bank__compare-main a {
    font-size: .875rem;
    font-weight: 300;
    display: inline-block;
    margin-top: .25rem;
    color: #a3abb0; }

.broker__invoices, .insurance__invoices, .bank__invoices {
  float: left;
  position: relative; }
  .broker__invoices-title, .insurance__invoices-title, .bank__invoices-title {
    margin: 0 0 2rem; }
  .broker__invoices .broker__table, .insurance__invoices .broker__table, .bank__invoices .broker__table {
    margin: 0 0 2.5rem !important; }

.broker__invoice-add, .insurance__invoice-add, .bank__invoice-add {
  margin: 0;
  list-style: none;
  right: 1rem;
  line-height: 49px;
  position: absolute;
  font-size: .875rem; }
  .broker__invoice-add a, .insurance__invoice-add a, .bank__invoice-add a {
    margin: 0 0 0 1rem; }

.broker__review-title, .insurance__review-title, .bank__review-title {
  margin-top: 0; }

.broker__review-list, .insurance__review-list, .bank__review-list {
  list-style: none;
  margin: 0 0 45px;
  display: inline-block; }

.broker__review li, .insurance__review li, .bank__review li {
  float: left;
  margin: 0 5px 0 0; }

.broker__review .comments ul.root li, .insurance__review .comments ul.root li, .bank__review .comments ul.root li {
  margin: 0 0 2rem; }

.broker-title_top, .insurance-title_top, .bank-title_top {
  margin-top: 0; }

.broker__list, .insurance__list, .bank__list {
  margin: 0;
  list-style: none; }
  .broker__list-title, .broker__list li, .broker__list h2, .insurance__list-title, .insurance__list li, .insurance__list h2, .bank__list-title, .bank__list li, .bank__list h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #e0e3e5;
    padding-bottom: 2.5rem;
    font-weight: 600; }
  .broker__list a, .insurance__list a, .bank__list a {
    color: #20303c;
    border-bottom: 0; }
  .broker__list .tags, .insurance__list .tags, .bank__list .tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem; }
    .broker__list .tags a, .insurance__list .tags a, .bank__list .tags a {
      color: #a3abb0 !important;
      margin-right: .25rem; }

.broker__review, .insurance__review, .bank__review {
  width: 750px;
  float: left;
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0;
  box-sizing: border-box; }
  .broker__review ul, .insurance__review ul, .bank__review ul {
    list-style: none;
    margin: 0; }
  .broker__review small, .insurance__review small, .bank__review small {
    display: block;
    text-transform: none;
    padding: 0 0 .5rem;
    font-weight: 300;
    color: #a3abb0;
    font-size: .875rem;
    line-height: 1.375rem; }
  .broker__review-count a:first-of-type, .insurance__review-count a:first-of-type, .bank__review-count a:first-of-type {
    margin-right: .75rem;
    color: #28c75d; }
  .broker__review-count a:last-of-type, .insurance__review-count a:last-of-type, .bank__review-count a:last-of-type {
    color: #f2564d; }

.broker__rating-table, .insurance__rating-table, .bank__rating-table {
  cursor: ew-resize;
  overflow: auto;
  margin: 0;
  line-height: 1.25rem; }
  .broker__rating-table td, .insurance__rating-table td, .bank__rating-table td {
    vertical-align: middle; }
    .broker__rating-table td:nth-of-type(5), .insurance__rating-table td:nth-of-type(5), .bank__rating-table td:nth-of-type(5) {
      white-space: nowrap; }
  .broker__rating-table th a, .insurance__rating-table th a, .bank__rating-table th a {
    color: #20303c; }

.broker__rating_underline, .insurance__rating_underline, .bank__rating_underline {
  border-bottom: 1px solid #e0e3e5; }

.broker__rating_white_space, .insurance__rating_white_space, .bank__rating_white_space {
  white-space: nowrap; }

.bank__rating-table td:nth-of-type(4), .insurance__rating-table td:nth-of-type(4) {
  white-space: nowrap; }

.broker__box {
  width: 320px;
  display: inline-block;
  margin: 0 0 20px; }
  .broker__box-title {
    display: block;
    margin: 0 0 5px 2px; }
  .broker__box-select select {
    width: 31rem; }
  .broker__box-item {
    box-sizing: border-box; }
    .broker__box-item:first-of-type {
      margin: 0 0 2rem 0; }
    .broker__box-item label {
      color: #20303c;
      border-bottom: .5rem solid transparent;
      z-index: 100;
      position: relative;
      font-weight: 600; }

.text {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 2.5rem 0 0; }
  .text.text_border {
    border: 1px solid #e0e3e5;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem 2rem 2rem; }
    .text.text_border p {
      margin: 1rem 0 0; }
    .text.text_border ul {
      margin: 1rem 0 0 2rem; }
    .text.text_border li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 0 .5rem; }
      .text.text_border li:last-of-type {
        margin: 0; }
  .text_top {
    margin: 2rem 0 0; }
  .text p {
    margin: 2rem 0 0; }
  .text li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }
  .text a {
    border-bottom: 1px solid #e0e3e5; }
  .text tr:hover > td:nth-child(odd),
  .text tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }
  .text table {
    display: block;
    overflow: auto; }
    .text table td {
      padding: .75rem 1rem; }
      .text table td:last-of-type {
        border-right: 0; }
    .text table th {
      font-weight: 600; }
      .text table th:nth-of-type(2),
      .text table th :nth-of-type(3) {
        width: 50%; }
    .text table ul {
      margin: 1rem 0 0 2rem; }
    .text table li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: .5rem 0; }
      .text table li:last-of-type {
        margin: 0; }
  .text h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 2rem 0 0;
    font-weight: 400; }
    .text h5:before {
      display: block;
      width: 50%;
      height: 1px;
      background: #e0e3e5;
      content: '';
      margin-bottom: 1rem; }

.comment__logo {
  position: absolute;
  z-index: 10; }

.comment__message {
  box-sizing: border-box;
  position: relative;
  padding: 0 0 0  4.125rem; }
  .comment__message-text {
    min-height: 2.5rem;
    margin-top: .5rem;
    font-size: .875rem;
    line-height: 1.375rem;
    margin-bottom: 1rem; }
  .comment__message p {
    font-size: .875rem;
    line-height: 1.375rem;
    margin: 0 0 1rem; }
  .comment__message-children .text, .comment__message-children .comment__message-text {
    margin-bottom: 0; }
  .comment__message-left {
    float: left; }
  .comment__message-right {
    float: right; }
  .comment__message-vote {
    padding: 0 0 0  10px;
    padding-right: 10px;
    display: none;
    opacity: .35; }
    .comment__message-vote_up {
      font-size: 13px;
      color: #f2564d;
      border: 1px solid #e0e3e5;
      padding: 0 5px; }
    .comment__message-vote_number {
      padding: 0 0 0  0;
      padding-right: 0; }
    .comment__message-vote_item {
      font-size: 13px;
      width: 70px;
      color: #28c75d;
      border: 1px solid #e0e3e5;
      padding: 0 5px; }
  .comment__message-edited {
    color: #a3abb0;
    margin: 0 0 0 6px; }

.comment__date {
  color: #a3abb0;
  margin: 0 0 0 .5rem;
  font-size: .875rem;
  font-weight: 300; }
  .comment__date:active, .comment__date:hover, .comment__date:focus {
    color: #757575; }

.comment__form textarea, .comment__form-message {
  width: 100%;
  min-height: 90px;
  border: 1px solid #c2c7cb;
  padding: 4px 8px;
  margin: 10px 0;
  outline: 0;
  font-size: .875rem;
  color: #a3abb0;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.comment__form-button {
  display: inline-block;
  width: 100%; }
  .comment__form-button_submit {
    z-index: 9999;
    position: relative;
    margin: 5px 0;
    border: 0;
    background: #65c888;
    color: #fff;
    font-family: "Open Sans";
    float: left; }
    .comment__form-button_submit:hover {
      background: #65c888; }
  .comment__form-button_cancel {
    margin: 6px 5px;
    float: left; }
  .comment__form-button span {
    margin: 6px 0 0 8px;
    float: left; }

.comment__reply {
  font-size: 13px;
  color: #a3abb0; }
  .comment__reply:hover {
    color: #757575; }

.insurance__content_overflow {
  overflow: auto; }

.insurance__list {
  margin: 0;
  list-style: none; }
  .insurance__list-title, .insurance__list li, .insurance__list h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #edeff0;
    padding-bottom: 2.5rem; }
  .insurance__list a {
    color: #20303c; }
  .insurance__list .tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem; }
    .insurance__list .tags a {
      color: #a3abb0 !important;
      margin: 0 .25rem 0 0; }

.insurance__info {
  line-height: 1.5rem;
  margin-bottom: 2.5rem; }

.insurance__table {
  margin: 2rem 0 0;
  line-height: 1.5rem; }
  .insurance__table td:last-of-type {
    text-align: left; }
  .insurance__table td:first-of-type {
    vertical-align: top;
    width: 42%; }
  .insurance__table th:last-of-type {
    text-align: left; }

.insurance__text {
  font-size: 1.125rem; }
  .insurance__text_top {
    margin: 2.5rem 0 0; }
  .insurance__text p:last-of-type {
    margin: 2rem 0 0; }
  .insurance__text li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }
  .insurance__text p a, .insurance__text li a {
    border-bottom: 1px solid #e0e3e5; }
  .insurance__text tr:hover > td:nth-child(odd),
  .insurance__text tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }
  .insurance__text table {
    text-align: left; }
    .insurance__text table td {
      padding: .5rem 1rem;
      background: #fff; }
      .insurance__text table td:last-of-type {
        border-right: 0; }
    .insurance__text table th {
      padding: .5rem 1rem;
      color: inherit;
      text-transform: inherit; }
      .insurance__text table th:nth-of-type(2),
      .insurance__text table th :nth-of-type(3) {
        width: 50%; }

.insurance__group {
  margin: 0 0 2.5rem; }
  .insurance__group tr:hover span {
    color: #a3abb0; }

.calendar__content {
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid #c2c7cb;
  padding: 2rem;
  margin: 0 0 2.5rem;
  box-sizing: border-box; }
  .calendar__content .ui-datepicker-title {
    display: none; }
  .calendar__content .ui-datepicker-calendar {
    border: 0 !important; }
    .calendar__content .ui-datepicker-calendar th {
      border-left: 0 !important; }
      .calendar__content .ui-datepicker-calendar th:last-of-type {
        border-right: 0 !important; }
  .calendar__content .calendar__date {
    line-height: 1rem; }

.calendar__date {
  font-size: 1rem;
  margin: 1rem 0 0;
  display: block; }
  .calendar__date-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 2rem 0 1rem;
    display: block; }

.calendar__year_prev {
  margin: 0 1rem 0 0; }
  .calendar__year_prev_small {
    margin: 0 .75rem 0 0; }

.calendar__controls {
  margin: 0 0 2rem; }
  .calendar__controls span {
    border-bottom: 1px solid #e0e3e5; }

.calendar__detail {
  margin: 2rem 0 0; }

.calendar__title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem 0;
  font-weight: 600; }
  .calendar__title_top {
    margin: 0 0 2rem; }

.calendar p {
  margin: 2rem 0 0;
  font-size: 1rem;
  line-height: 1.5rem; }

.calendar__total {
  margin: 0 0 2.5rem; }
  .calendar__total-table {
    text-align: left !important; }
    .calendar__total-table td, .calendar__total-table th {
      padding: .75rem 1rem !important; }
    .calendar__total-table .half-year {
      color: #f2564d; }
  .calendar__total-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 2rem;
    font-weight: 600; }

.calendar__table {
  text-align: left !important;
  font-size: 1rem; }
  .calendar__table_top_small {
    margin: 1rem 0 0; }
  .calendar__table td:first-of-type {
    width: 50%; }
  .calendar__table td, .calendar__table th {
    padding: .75rem 1rem !important; }
  .calendar__table tr:hover > td:nth-child(odd),
  .calendar__table tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }

.calendar__text {
  font-size: 1.125rem; }
  .calendar__text_top {
    margin: 2.5rem 0 0; }
  .calendar__text p:last-of-type {
    margin: 2rem 0 0; }
  .calendar__text li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }
  .calendar__text p a, .calendar__text li a {
    border-bottom: 1px solid #e0e3e5; }
  .calendar__text tr:hover > td:nth-child(odd),
  .calendar__text tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }
  .calendar__text table {
    text-align: left; }
    .calendar__text table td {
      padding: .5rem 1rem;
      vertical-align: top;
      background: #fff; }
      .calendar__text table td:last-of-type {
        border-right: 0; }
    .calendar__text table th {
      padding: .5rem 1rem;
      color: inherit;
      text-transform: inherit; }
      .calendar__text table th:nth-of-type(2),
      .calendar__text table th :nth-of-type(3) {
        width: 50%; }

.calendar__quarter-title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0 0 2rem;
  font-weight: 600; }

.calendar .ui-datepicker-calendar {
  text-align: center;
  margin: 0; }
  .calendar .ui-datepicker-calendar th {
    padding: .75rem .5rem; }
  .calendar .ui-datepicker-calendar td {
    padding: .75rem .5rem;
    background: #fff; }

.calendar .calendar__total-table tr:hover > td:nth-child(odd),
.calendar .calendar__total-table tr:hover > td:nth-child(even) {
  background: #fdfdfd !important; }

.calendar tr:hover > td:nth-child(odd), .calendar tr:hover > td:nth-child(even) {
  background: #fff; }

.calendar .ui-datepicker-calendar tr:hover > td:nth-child(odd),
.calendar .ui-datepicker-calendar tr:hover > td:nth-child(even) {
  background: #fff !important; }

.calendar .ui-datepicker-calendar td.ui-datepicker-week-end a,
.calendar .ui-datepicker-calendar td.ui-datepicker-week-end span {
  color: #f2564d; }

.calendar .ui-datepicker-inline {
  overflow: hidden;
  width: 100% !important; }

.calendar .ui-datepicker-year {
  display: none !important; }

.calendar .ui-datepicker-group {
  display: inline-block;
  width: 30%;
  margin: 0 0 2.5rem; }
  .calendar .ui-datepicker-group-middle {
    padding: 0 2.5rem; }

.calendar .ui-datepicker-prev, .calendar .ui-datepicker-next {
  display: none; }

.calendar .ui-datepicker-calendar td.ui-datepicker-week-col {
  color: #a3abb0;
  opacity: .5; }

.calendar .ui-datepicker-other-month span {
  background: #fff !important;
  opacity: .1; }

.calendar .russian-holiday a, .calendar .russian-holiday span {
  cursor: default;
  color: #f2564d !important; }

.calendar .russian-nalog a, .calendar .russian-nalog span {
  cursor: pointer;
  color: #009075 !important; }

.calendar .ui-datepicker-calendar td.russian-short span {
  cursor: default;
  color: #faad4d !important;
  color: inherit; }

.calendar .ui-datepicker-calendar td.ui-datepicker-today span {
  color: #28c75d !important; }

.calendar .ui-datepicker-calendar td.ui-datepicker-today a {
  color: #28c75d; }

.calendar .ui-datepicker-title {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 1.5rem; }
  .calendar .ui-datepicker-title a {
    border-bottom: 1px solid #e0e3e5; }

.content .ui-datepicker-calendar {
  font-size: 1rem !important;
  width: 100% !important;
  border: 1px solid #e0e3e5; }
  .content .ui-datepicker-calendar a {
    color: #65c888; }

.ui-datepicker-week-end {
  color: #f2564d; }

table.ui-datepicker-calendar tr:hover td.ui-datepicker-other-month {
  background: #fff !important; }
  table.ui-datepicker-calendar tr:hover td.ui-datepicker-other-month.russian-holiday, table.ui-datepicker-calendar tr:hover td.ui-datepicker-other-month.russian-short, table.ui-datepicker-calendar tr:hover td.ui-datepicker-other-month.ui-datepicker-week-end, table.ui-datepicker-calendar tr:hover td.ui-datepicker-other-month.ui-datepicker-today {
    background: #fff !important; }

table.ui-datepicker-calendar td.ui-datepicker-other-month {
  opacity: .1;
  background: #fff !important; }
  table.ui-datepicker-calendar td.ui-datepicker-other-month span, table.ui-datepicker-calendar td.ui-datepicker-other-month a {
    color: #20303c !important; }
  table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-holiday, table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-short, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-week-end, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-today {
    background: #fff !important; }
    table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-holiday span, table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-holiday a, table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-short span, table.ui-datepicker-calendar td.ui-datepicker-other-month.russian-short a, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-week-end span, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-week-end a, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-today span, table.ui-datepicker-calendar td.ui-datepicker-other-month.ui-datepicker-today a {
      color: #20303c !important; }

.ui-datepicker-calendar .ui-datepicker-other-month span {
  background: transparent !important;
  opacity: 1 !important;
  color: #20303c !important; }

.content table.ui-datepicker-calendar td.ui-datepicker-today.ui-datepicker-other-month span, .content table.ui-datepicker-calendar td.ui-datepicker-today.ui-datepicker-other-month a {
  color: #20303c !important; }

.currency__table {
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin: 2rem 0 0;
  font-size: 1rem;
  line-height: 1.5rem; }
  .currency__table .header {
    width: auto;
    height: auto !important;
    box-shadow: none;
    border-bottom: 1px solid #e0e3e5;
    cursor: pointer; }
  .currency__table_full {
    line-height: 1.5rem;
    margin: 0;
    display: block;
    overflow: auto; }
    .currency__table_full a {
      border-bottom: 1px solid #e0e3e5; }
  .currency__table_white_space td:last-of-type {
    white-space: nowrap; }

.currency__content-small {
  text-transform: lowercase; }

.currency__title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin: 0 0 2rem; }
  .currency__title_top {
    margin: 2rem 0; }

.currency__description {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
  font-weight: 600; }

.currency__text {
  font-size: 1.125rem; }
  .currency__text p:last-of-type {
    margin: 2rem 0 0; }
  .currency__text li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }
  .currency__text p a, .currency__text li a {
    border-bottom: 1px solid #e0e3e5; }
  .currency__text tr:hover > td:nth-child(odd),
  .currency__text tr:hover > td:nth-child(even) {
    background: #fdfdfd !important; }
  .currency__text table {
    text-align: left; }
    .currency__text table td {
      padding: .5rem 1rem;
      vertical-align: top;
      background: #fff; }
      .currency__text table td:last-of-type {
        border-right: 0; }
    .currency__text table th {
      padding: .5rem 1rem;
      color: inherit;
      text-transform: inherit; }
      .currency__text table th:nth-of-type(2),
      .currency__text table th :nth-of-type(3) {
        width: 50%; }

.currency__group {
  padding: 1rem 0; }
  .currency__group_bottom_small, .currency__group.currency__group_bottom_small {
    padding: 1rem 0 .5rem; }
  .currency__group_top, .currency__group.currency__group_top {
    padding: 1rem 0 0; }
    .currency__group_top_large, .currency__group.currency__group_top_large {
      padding: 2rem 0 0; }
  .currency__group_bottom, .currency__group.currency__group_bottom {
    padding: 0 0 1rem; }

.currency__popup {
  width: 250px;
  padding: .125rem 1rem;
  height: 350px;
  overflow: auto; }
  .currency__popup-search {
    padding: .59375rem 1rem;
    font-weight: 300;
    color: #a3abb0;
    font-size: .875rem;
    font-family: "Open Sans";
    width: 100%;
    margin: .5rem 0 .75rem;
    box-sizing: border-box;
    border: 1px solid #c2c7cb; }
  .currency__popup ul {
    padding: 0 !important; }
    .currency__popup ul li {
      list-style-type: none; }
      .currency__popup ul li a {
        padding: .125rem 0 !important;
        line-height: 1.25rem; }

.currency__converter {
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0 0 2.5rem;
  border: 1px solid #e0e3e5; }
  .currency__converter label {
    display: block;
    font-weight: 600;
    border-bottom: .75rem solid transparent;
    margin-bottom: .25rem;
    line-height: 1.5rem; }
  .currency__converter .currency__group {
    padding: 1rem 0;
    float: left;
    margin: 0 .75rem 0 0; }
  .currency__converter .currency__group_top {
    padding: 1rem 0 2.5rem;
    float: left;
    margin: 0 .75rem 0 0; }
  .currency__converter-input, .currency__converter input {
    margin: 0 .25rem 0 0;
    font-family: "Open Sans";
    width: 10rem;
    height: 30px;
    border: 1px solid #c2c7cb;
    padding: .25rem 1rem;
    color: #20303c; }
  .currency__converter table, .currency__converter .currency__table {
    margin: 0;
    padding: 0;
    border: 0;
    display: table; }
    .currency__converter table th, .currency__converter .currency__table th {
      background: #fff; }
    .currency__converter table tr:last-of-type td, .currency__converter .currency__table tr:last-of-type td {
      border-bottom: 0; }
      .currency__converter table tr:last-of-type td:first-of-type, .currency__converter .currency__table tr:last-of-type td:first-of-type {
        border-radius: 0 0 0 3px; }
      .currency__converter table tr:last-of-type td:last-of-type, .currency__converter .currency__table tr:last-of-type td:last-of-type {
        border-radius: 0 0 3px 0; }

.currency__item-reset .positive {
  font-weight: normal !important;
  padding: 0 !important;
  color: #20303c !important;
  background-color: #fff !important; }

.currency__item-reset .negative {
  font-weight: normal !important;
  padding: 0 !important;
  color: #20303c !important;
  background-color: #fff !important; }

.currency__chart {
  margin: 2rem 0 !important; }
  .currency__chart-description {
    font-size: .875rem;
    line-height: 1.375rem;
    color: #a3abb0;
    margin: -1rem 0 0; }

.popover_translate {
  color: #20303c;
  line-height: 1.5rem;
  z-index: 100;
  position: relative;
  font-weight: 600;
  float: left;
  display: block;
  border-bottom: .75rem solid transparent; }

.translate {
  border: 1px solid #c2c7cb;
  padding: 2rem;
  margin: 0 0 2.5rem; }
  .translate a {
    border-bottom: 1px solid #e0e3e5; }
  .translate__item {
    box-sizing: border-box;
    line-height: 0; }
    .translate__item:first-of-type {
      margin: 0 0 2rem 0; }
    .translate__item-popover {
      color: #20303c;
      border-bottom: .5rem solid transparent;
      z-index: 100;
      position: relative;
      font-weight: 600; }
  .translate__size {
    font-size: .875rem;
    line-height: 1.375rem;
    color: #a3abb0;
    float: right; }
  .translate__textarea {
    border: 1px solid #c2c7cb;
    position: relative;
    z-index: 10;
    width: 100% !important;
    min-height: 10rem !important;
    margin: .25rem 0 0 !important;
    outline: 0;
    box-sizing: border-box;
    padding: 1rem;
    font-family: "Open Sans";
    font-size: .875rem;
    line-height: 1.375rem;
    color: #43515c; }
  .translate__settings {
    font-size: .875rem;
    color: #a3abb0;
    margin-top: 2rem; }
  .translate__text {
    font-size: 1.125rem; }
    .translate__text p:last-of-type {
      margin: 2rem 0 0; }
    .translate__text li {
      margin: 1rem 0;
      font-size: 1.125rem;
      line-height: 1.625rem; }
    .translate__text p a, .translate__text li a {
      border-bottom: 1px solid #e0e3e5; }
    .translate__text tr:hover > td:nth-child(odd),
    .translate__text tr:hover > td:nth-child(even) {
      background: #fdfdfd !important; }
    .translate__text table {
      text-align: left; }
      .translate__text table td {
        padding: .5rem 1rem;
        vertical-align: top;
        background: #fff; }
        .translate__text table td:last-of-type {
          border-right: 0; }
      .translate__text table th {
        padding: .5rem 1rem;
        color: inherit;
        text-transform: inherit; }
        .translate__text table th:nth-of-type(2),
        .translate__text table th :nth-of-type(3) {
          width: 50%; }

.commission {
  margin: 1rem 0; }
  .commission__short {
    width: 5rem; }

.attention {
  width: 300px;
  float: right;
  box-sizing: border-box; }
  .attention ul, .attention ol {
    margin: 0 0 0 2rem; }
  .attention li {
    line-height: 1.5rem;
    margin: 0 0 1rem; }

.review {
  width: 750px;
  float: left;
  font-size: 1rem;
  padding: 2rem;
  margin: 0;
  border: 1px solid #c2c7cb;
  box-sizing: border-box; }
  .review__form-stars {
    display: inline-block; }
    .review__form-stars li {
      display: inline-block;
      margin-bottom: 0 !important; }
  .review__form-description {
    text-transform: lowercase;
    display: block;
    text-transform: none;
    padding: 0 0 .5rem;
    font-weight: 300;
    color: #a3abb0;
    font-size: .875rem;
    line-height: 1.375rem; }
  .review__form-item {
    margin-bottom: .5rem; }
    .review__form-item_list {
      margin: 0 0 2rem; }
    .review__form-item_title {
      padding: 0;
      display: block;
      font-weight: 600;
      margin: 0 0 .5rem; }
  .review__form-list {
    list-style: none;
    margin: 0 0 2rem !important; }
    .review__form-list li {
      margin-bottom: .5rem; }
  .review__form-criteria {
    margin-bottom: .5rem; }
  .review__form-title {
    padding: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    display: block;
    margin: 0 0 1.5rem; }
  .review__form-buttons {
    list-style: none;
    display: inline-block;
    padding: 0 0 0 5px; }
    .review__form-buttons_item {
      display: inline-block;
      padding: 0;
      margin: 0; }
      .review__form-buttons_item button {
        border-left: 1px solid #e8eaeb;
        color: #a3abb0 !important;
        background: #e0e3e5;
        box-shadow: none;
        margin: 0;
        outline: 0; }
        .review__form-buttons_item button:last-child {
          border-right: none; }
        .review__form-buttons_item button:hover, .review__form-buttons_item button.active {
          background-color: #e8eaeb; }
      .review__form-buttons_item:first-child button {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-left: none; }
      .review__form-buttons_item:last-child button {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
  .review__form-message textarea {
    width: 100% !important;
    min-height: 90px;
    border: 1px solid #c2c7cb;
    padding: 4px 8px;
    margin: 10px 0;
    font-size: .875rem;
    line-height: 1;
    color: #a3abb0;
    box-sizing: border-box;
    font-family: "Open Sans";
    outline: 0; }
  .review__form-btn {
    position: relative;
    margin: 2rem 0 0; }
  .review__list-item {
    width: 750px;
    float: left;
    font-size: 1rem;
    box-shadow: inset 0 0 0 1px #e0e3e5;
    padding: 1rem 2rem;
    margin: 0 0 2.5rem;
    box-sizing: border-box; }
    .review__list-item ul {
      list-style: none;
      margin: 0; }

/* READER */
.reader .search__results-submit {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0; }

.reader .dropdown__menu a {
  color: #28c75d; }
  .reader .dropdown__menu a:hover {
    color: #a3abb0 !important; }

.reader > ul {
  margin: 0 0 -5px 0;
  list-style: none;
  display: inline-block; }

.reader ul .dropdown__menu {
  padding: .75rem 0 !important;
  margin: 0;
  top: 100%;
  z-index: 10000;
  min-width: inherit;
  border: 1px solid #c2c7cb;
  box-shadow: 0.15rem 0.15rem 0 0 #eff1f2; }
  .reader ul .dropdown__menu > li {
    margin: 0;
    float: none;
    display: block;
    padding: 0; }
    .reader ul .dropdown__menu > li a {
      padding: 2px 10px;
      color: #757575; }
      .reader ul .dropdown__menu > li a:active, .reader ul .dropdown__menu > li a:focus, .reader ul .dropdown__menu > li a:visited {
        background-color: #fff; }
      .reader ul .dropdown__menu > li a:hover {
        background: #fff;
        color: #757575; }
    .reader ul .dropdown__menu > li:first-child a:hover {
      border-radius: 0 5px 0 0; }
    .reader ul .dropdown__menu > li:last-child {
      border-bottom: none; }
      .reader ul .dropdown__menu > li:last-child a {
        border-bottom: none; }
        .reader ul .dropdown__menu > li:last-child a:hover {
          border-radius: 0 0 5px 5px; }

.reader ul > li > a {
  display: block; }

.reader li {
  display: inline-block;
  margin: 0 11px 0 0; }

.reader__list {
  background: #eff1f2;
  padding: 10px;
  list-style: none;
  margin: 0 0 20px;
  height: 250px;
  overflow: auto; }

.reader__source {
  color: #a3abb0;
  margin-bottom: .5rem;
  text-transform: lowercase;
  line-height: 1.375rem;
  font-size: .875rem; }
  .reader__source a {
    color: #a3abb0; }

.reader__date {
  color: #a3abb0;
  margin: 0 1rem 0 0;
  font-size: .875rem;
  line-height: 1.375rem; }

.reader__views {
  color: #a3abb0;
  font-size: .875rem;
  line-height: 1.375rem; }

.reader table td a:visited, .reader__table td a:visited {
  color: #80CBC4; }

.reader__header {
  margin: 1rem auto 0;
  position: relative;
  width: 1100px; }

.reader__share {
  top: 0;
  position: fixed;
  margin: 230px 0 0 5%; }
  .reader__share .ya-share2__list_direction_horizontal > .ya-share2__item {
    display: block !important;
    margin-bottom: .5rem !important;
    opacity: 1; }

.reader__filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem; }
  .reader__filters_right, .reader__filters_left {
    display: flex;
    align-items: center; }
  .reader__filters .search__remove {
    border-bottom: .5rem solid transparent; }
  .reader__filters ul {
    margin: 0; }
    .reader__filters ul .dropdown {
      padding-bottom: 0;
      border-bottom: none; }
      .reader__filters ul .dropdown__toggle {
        line-height: 1.375rem;
        border-bottom: .75rem solid transparent;
        padding-bottom: 0; }
      .reader__filters ul .dropdown li {
        border-bottom: none; }

.reader__content-header {
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #e0e3e5;
  padding: 0 0 1rem; }
  .reader__content-header_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .reader__content-header_right {
    display: flex;
    align-items: center; }
  .reader__content-header_group {
    margin: 0 15px 0 0; }
    .reader__content-header_group:last-child {
      margin: 0; }

.reader__content-item {
  border-bottom: 1px solid #e0e3e5;
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem; }
  .reader__content-item_title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: block;
    font-weight: 600; }
    .reader__content-item_title a {
      color: #20303c; }
  .reader__content-item_text {
    font-size: .875rem;
    line-height: 1.375rem; }
  .reader__content-item_bottom {
    margin: .5rem 0 0; }

.reader__tn {
  overflow: hidden; }
  .reader__tn .wrapper__fixed .header {
    width: 1100px;
    margin: 0 0 0 auto;
    margin-right: auto;
    position: relative; }
  .reader__tn #link {
    margin-top: 90px;
    border-top: 1px solid #e0e0e0; }

.wrapper__fixed {
  position: fixed;
  width: 100%;
  margin: auto;
  z-index: 15; }

.business {
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid #c2c7cb;
  position: relative;
  padding: 2rem;
  margin: 0 0 2.5rem; }
  .business__kind {
    margin-bottom: 1rem; }
    .business__kind-title {
      color: #65c888;
      display: initial !important;
      border-bottom: .8125rem solid transparent; }
      .business__kind-title span {
        text-transform: lowercase;
        font-size: inherit; }
  .business ul, .business__list {
    margin: 0;
    display: inline; }
    .business ul li, .business__list li {
      list-style: none;
      display: inline-block; }
    .business ul-title, .business__list-title {
      margin: 0 .75rem 0 0; }
  .business__result {
    padding: 10px 0;
    margin: 10px 0 30px; }
    .business__result input, .business__result-input {
      margin: 0 5px 0 0;
      width: inherit; }
  .business__popup {
    width: 250px;
    padding: 0 1rem .25rem;
    overflow: auto;
    height: 350px; }
    .business__popup li {
      padding-bottom: .25rem; }
      .business__popup li:last-of-type {
        padding-bottom: 0 !important; }
    .business__popup-all {
      text-transform: none; }
    .business__popup-search {
      padding: .59375rem 1rem;
      font-weight: 300;
      font-size: .875rem;
      border: 1px solid #c2c7cb;
      box-sizing: border-box;
      font-family: "Open Sans";
      color: #a3abb0;
      margin: -.5rem 0 0; }
    .business__popup-list {
      margin: 0 !important;
      padding: 0 !important; }
      .business__popup-list li label {
        line-height: 1.25rem; }
    .business__popup .business__popup-search {
      width: 100%;
      margin: .5rem 0 1rem;
      box-sizing: border-box;
      border: 1px solid #c2c7cb; }
  .business__sort {
    right: 2rem;
    bottom: 2.625rem;
    position: absolute;
    font-size: .875rem;
    line-height: 1.375rem;
    color: #a3abb0; }
  .business__input {
    border: 1px solid #c2c7cb;
    margin: 0 .25rem 0 0;
    font-weight: 300;
    font-family: "Open Sans";
    height: 30px;
    width: 10rem;
    padding: .25rem 1rem;
    z-index: 1000;
    color: #20303c; }
    .business__input[type=radio] {
      margin: -2px 2px 0 2px;
      width: initial; }
    .business__input[type=checkbox] {
      float: left;
      width: 1rem;
      height: 1rem;
      margin: 0; }
  .business__group {
    padding: 1rem 0; }
    .business__group_top, .business__group.business__group_top {
      padding: 1rem 0 0; }
    .business__group-title {
      font-weight: 600;
      margin-bottom: 1rem;
      display: block; }
    .business__group-dash {
      margin: 0 .25rem 0 0; }
      .business__group-dash.business__group-dash_medium, .business__group-dash_medium {
        margin: 0 .5rem 0 0; }
      .business__group-dash.business__group-dash_large, .business__group-dash_large {
        margin: 0 .75rem 0 .5rem; }
    .business__group-input {
      border: 1px solid #c2c7cb;
      margin: 0 .25rem 0 0;
      font-weight: 300;
      font-family: "Open Sans";
      font-size: .875rem;
      height: 30px;
      width: 10rem;
      padding: .25rem 1rem;
      z-index: 1000;
      color: #20303c; }
      .business__group-input[type=radio] {
        margin: -2px 2px 0 2px;
        width: initial; }
      .business__group-input[type=checkbox] {
        float: left;
        width: 1rem;
        height: 1rem;
        margin: 0; }
      .business__group-input_medium, .business__group-input.business__group-input_medium {
        width: 75px; }
      .business__group-input_short, .business__group-input.business__group-input_short {
        width: 5rem; }

.franchises .article__info_bottom {
  margin: 2rem 0 2.5rem; }

.converter__group-list_item {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #edeff0;
  padding-bottom: 2.5rem; }
  .converter__group-list_item a {
    color: #20303c; }

.converter__group-title {
  margin: 2.5rem 0 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
  font-weight: 600;
  padding: 0; }

.converter__list {
  margin: 0;
  list-style: none; }
  .converter__list-title, .converter__list li {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #e0e3e5;
    padding: 0 0 2.5rem;
    font-weight: 600; }
  .converter__list a {
    color: #20303c; }
  .converter__list .tags {
    margin-bottom: .5rem;
    overflow: hidden;
    text-transform: lowercase;
    line-height: 1.5rem;
    font-size: .875rem; }
    .converter__list .tags a {
      color: #a3abb0 !important;
      margin: 0 .25rem 0 0; }

.converter .ns-popover-list-theme ul {
  padding: 0 !important;
  margin: .75rem 0;
  display: block;
  list-style: none; }

.converter__content {
  font-size: 1rem;
  border: 1px solid #c2c7cb;
  padding: 1rem 2rem;
  margin: 0 0 2.5rem; }
  .converter__content-popover {
    color: #65c888;
    border-bottom: .75rem solid transparent;
    display: initial; }
  .converter__content-list {
    padding: 0;
    margin: 0;
    list-style: none; }
    .converter__content-list_items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .converter__content-list_items .converter__content-group {
        width: 50%; }
      .converter__content-list_items .converter__content-group_full {
        width: 100%; }
  .converter__content-title {
    font-size: 1.375rem;
    line-height: 1.875rem;
    font-weight: 600;
    width: 100%; }
  .converter__content-group {
    padding: 1rem 0;
    list-style: none; }
    .converter__content-group_inline input {
      display: inline-block; }
    .converter__content-group_settings, .converter__content-group.converter__content-group_settings {
      font-size: .875rem;
      line-height: 1.375rem;
      color: #a3abb0; }
    .converter__content-group_title {
      display: inline-block;
      font-weight: 600;
      margin: 0 0 1rem;
      line-height: 1.5rem; }
      .converter__content-group_title a {
        display: inline-block; }
      .converter__content-group_title::first-letter {
        text-transform: capitalize; }
    .converter__content-group_input {
      border: 1px solid #c2c7cb;
      margin: 0 .25rem 0 0;
      font-family: "Open Sans";
      height: 30px;
      width: 10rem;
      font-size: .875rem;
      padding: .25rem 1rem;
      z-index: 1000;
      display: block;
      color: #20303c; }
    .converter__content-group_text {
      margin: 1rem 0 0;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #a3abb0; }
  .converter__content-results {
    margin: 1rem 0; }
    .converter__content-results_top_small, .converter__content-results.converter__content-results_top_small {
      margin: 1rem 0 !important; }
    .converter__content-results_title {
      font-size: 1.375rem;
      line-height: 1.875rem;
      font-weight: 600; }
    .converter__content-results_subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 1rem 0;
      font-weight: 600; }
    .converter__content-results_list, .converter__content-results ul {
      margin: 0;
      list-style: none; }
      .converter__content-results_list.converter__content-results_reset, .converter__content-results_list_reset, .converter__content-results ul.converter__content-results_reset, .converter__content-results ul_reset {
        padding: 0;
        margin: 0; }
        .converter__content-results_list.converter__content-results_reset li, .converter__content-results_list_reset li, .converter__content-results ul.converter__content-results_reset li, .converter__content-results ul_reset li {
          list-style: none; }
          .converter__content-results_list.converter__content-results_reset li:first-of-type, .converter__content-results_list_reset li:first-of-type, .converter__content-results ul.converter__content-results_reset li:first-of-type, .converter__content-results ul_reset li:first-of-type {
            margin-top: 5px; }
          .converter__content-results_list.converter__content-results_reset li:last-of-type, .converter__content-results_list_reset li:last-of-type, .converter__content-results ul.converter__content-results_reset li:last-of-type, .converter__content-results ul_reset li:last-of-type {
            margin-bottom: 0; }
            .converter__content-results_list.converter__content-results_reset li:last-of-type input, .converter__content-results_list_reset li:last-of-type input, .converter__content-results ul.converter__content-results_reset li:last-of-type input, .converter__content-results ul_reset li:last-of-type input {
              margin-bottom: 0; }
      .converter__content-results_list.converter__content-results_disc, .converter__content-results_list_disc, .converter__content-results ul.converter__content-results_disc, .converter__content-results ul_disc {
        list-style: disc !important;
        margin: 1rem 0 0 2rem !important; }
    .converter__content-results li, .converter__content-results .converter__content-result {
      line-height: 1.5rem;
      margin: .5rem 0; }
    .converter__content-results_small, .converter__content-results.converter__content-results_list_small {
      margin: 1rem 0 !important; }

/*
************
*** TABS ***
************
*/
.tab-content {
  position: relative;
  overflow: auto; }
  .tab-content a {
    border-bottom: 1px solid #e0e3e5; }
  .tab-content li {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem; }

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none; }

.tab-content > .active, .pill-content > .active {
  display: block; }

.nav-tabs > li {
  list-style: none; }

.nav-tabs li a {
  text-transform: uppercase;
  line-height: 1;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  margin: 0 1rem 0 0;
  padding: 0;
  font-weight: 300; }

.nav-tabs li.active a {
  background: transparent;
  color: #a3abb0;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  font-weight: 300; }

.nav-tabs > li a:hover {
  background: transparent; }

.nav-tabs {
  border-bottom: 0;
  padding-top: 0;
  border-top: none;
  font-size: .875rem;
  margin: 0; }

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #a3abb0;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  background: transparent;
  border-bottom-color: transparent;
  cursor: default; }

/*
***********************
*** GALLERY OVERLAY ***
***********************
*/
img[data-gallery] {
  cursor: pointer; }

#galleryOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 100000;
  background: rgba(55, 91, 116, 0.9);
  overflow: hidden;
  display: none;
  transition: opacity .4s ease; }

/* This class will trigger the animation */
#galleryOverlay.visible {
  opacity: 1; }

#gallerySlider {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  white-space: nowrap;
  position: absolute;
  transition: left .4s ease; }

#gallerySlider .placeholder {
  background: url(/static/images/preloader.gif) no-repeat center center;
  height: 100%;
  line-height: 1px;
  text-align: center;
  width: 100%;
  display: inline-block; }

/* The before element moves the image halfway from the top */
#gallerySlider .placeholder:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

#gallerySlider .placeholder img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  opacity: 1; }

#gallerySlider.rightSpring {
  -moz-animation: rightSpring .3s;
  -webkit-animation: rightSpring .3s; }

#gallerySlider.leftSpring {
  -moz-animation: leftSpring .3s;
  -webkit-animation: leftSpring .3s; }

/* Firefox Keyframe Animations */
@-moz-keyframes rightSpring {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@-moz-keyframes leftSpring {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

/* Safari and Chrome Keyframe Animations */
@-webkit-keyframes rightSpring {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@-webkit-keyframes leftSpring {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

/* Arrows */
#prevArrow, #nextArrow {
  border: none;
  text-decoration: none;
  background: url(/static/images/arrows.png) no-repeat;
  opacity: .85;
  cursor: pointer;
  position: absolute;
  width: 43px;
  height: 58px;
  top: 50%;
  margin-top: -29px; }

#prevArrow:hover, #nextArrow:hover {
  opacity: .85; }

#prevArrow {
  background-position: left top;
  left: 5%; }

#nextArrow {
  background-position: right top;
  right: 5%; }

/*
**************
*** ADVERT ***
**************
*/
.ad__category {
  border-bottom: 1px solid #e0e3e5;
  margin: 0 0 2.5rem;
  padding: 0 0 2.5rem; }

.ad__aside {
  text-align: center; }

.ad__sticky {
  background: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0 auto;
  text-align: center;
  width: 300px; }

.ad__chart {
  text-align: center;
  padding: 2.5rem 0 0; }

.ad__calendar {
  margin: 0 0 2.5rem; }

.ad__converter {
  margin: 0 0 2.5rem; }

.ad__article {
  margin: 0 0 2.5rem; }

.ad__calculator {
  margin: 0 0 2.5rem; }

.ad__word {
  margin: 0 0 2.5rem; }

.ad__translate {
  margin: 0 0 2.5rem; }

.ad__test {
  margin: 0 0 2.5rem; }

.ad__currency {
  margin: 0 0 2.5rem; }

.ad__download {
  margin: 2.5rem 0 0; }

.ad__index {
  margin: 2.5rem 0;
  text-align: center; }

.ad__category tr:hover > td:nth-child(odd), .ad__category tr:hover > td:nth-child(even),
.ad__article tr:hover > td:nth-child(odd),
.ad__article tr:hover > td:nth-child(even),
.ad__translate tr:hover > td:nth-child(odd),
.ad__translate tr:hover > td:nth-child(even),
.ad__calculator tr:hover > td:nth-child(odd),
.ad__calculator tr:hover > td:nth-child(even),
.ad__currency tr:hover > td:nth-child(odd),
.ad__currency tr:hover > td:nth-child(even),
.ad__test tr:hover > td:nth-child(odd),
.ad__test tr:hover > td:nth-child(even),
.ad__calendar tr:hover > td:nth-child(odd),
.ad__calendar tr:hover > td:nth-child(even),
.ad__word tr:hover > td:nth-child(odd),
.ad__word tr:hover > td:nth-child(even),
.ad__download tr:hover > td:nth-child(odd),
.ad__download tr:hover > td:nth-child(even),
.ad__converter tr:hover > td:nth-child(odd),
.ad__converter tr:hover > td:nth-child(even) {
  background: #fff !important; }

.ad__rating, .ad__rating tr:hover td {
  background-color: transparent !important; }

.a-cat {
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #edeff0;
  padding-bottom: 2.5rem; }

.a-ndx {
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #edeff0;
  padding-bottom: 2.5rem;
  text-align: center; }

.a-art {
  margin: 0 0 0 -11px;
  position: relative; }

.a-header {
  float: right;
  width: 728px;
  height: 90px;
  display: none; }

.a-chart a {
  display: inline-block;
  width: 100%; }

/*
****************
*** COMMENTS ***
****************
*/
.auth {
  padding: 10px;
  margin: 0;
  text-align: center;
  background-color: #fffde7; }

.a-cat .auth {
  padding: 10px;
  margin: 25px 0 25px 11px;
  text-align: center;
  background-color: #eff1f2; }

.comment-form textarea {
  width: 100%;
  border: 1px solid #c2c7cb;
  resize: vertical;
  min-height: 90px;
  padding: 5px; }

.comment-form .button-wrap {
  display: inline-block;
  width: 100%; }

.comment-form .button-wrap .submit {
  z-index: 9999;
  position: relative;
  float: right;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px; }

ul.social {
  list-style: none; }

ul.social li {
  display: inline; }

ul.children {
  margin-left: 50px; }

ul.children li {
  box-sizing: border-box; }

.comments ul.root {
  list-style: none;
  margin: 0;
  margin-bottom: 1rem; }

.comments ul.root li {
  display: block;
  width: 100%;
  margin: 25px 0;
  font-size: 1rem;
  line-height: 1.5rem; }

.org-review .comments ul.root li {
  margin: 0 0 2rem; }

.comments ul.root li ul.children li:last-of-type {
  margin: 0; }

.comments ul.root li .msg {
  box-sizing: border-box;
  position: relative;
  padding: 0 0 0  4.125rem; }

.comments ul.root li .msg .text {
  min-height: 2.5rem;
  margin-top: .5rem;
  font-size: .875rem;
  line-height: 1.375rem;
  margin-bottom: 1rem; }

.comments ul.root li .msg p {
  font-size: .875rem;
  line-height: 1.375rem;
  margin: 0 0 1rem; }

.comments ul.root li .replay {
  font-size: 13px;
  color: #a3abb0; }

.comments ul.root li .replay:hover {
  color: #757575; }

.comments ul.root li .complaint {
  font-size: 13px;
  opacity: 0.35;
  display: none; }

.comments ul.root li .vote-container {
  padding: 0 0 0 10px;
  padding-right: 10px; }

.comments ul.root li .vote {
  font-size: 13px;
  width: 70px;
  color: #28c75d;
  border: 1px solid #e0e3e5;
  padding: 0 5px; }

.comments ul.root li .vote-up {
  font-size: 13px;
  color: #f2564d;
  border: 1px solid #e0e3e5;
  padding: 0 5px; }

.comments ul.root li .number {
  padding: 0 0 0  0;
  padding-right: 0; }

.comments ul.root li .comment-logo {
  position: absolute;
  z-index: 10; }

.complaintPopover {
  padding: 10px; }

.complaintPopover h3 {
  margin-bottom: 10px; }

.complaintPopover textarea {
  margin-top: 10px;
  width: 100%;
  height: 60px; }

.comments {
  position: relative;
  width: 750px; }

.comment-form textarea {
  width: 100%;
  min-height: 90px;
  border: 1px solid #c2c7cb;
  padding: 4px 8px;
  margin: 10px 0;
  outline: 0;
  font-size: .875rem;
  color: #a3abb0;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.comment-form .button-wrap {
  display: inline-block;
  width: 100%; }

.comment-form .button-wrap .submit {
  z-index: 9999;
  position: relative;
  margin: 5px 0;
  border: 0;
  background: #65c888;
  color: #fff;
  font-family: "Open Sans";
  float: left; }

.comment-form .button-wrap .submit:hover {
  background: #65c888; }

.comment-form .button-wrap .cancel {
  margin: 6px 5px;
  float: left; }

.comment-form .button-wrap span {
  margin: 6px 0 0 8px;
  float: left; }

.vote-container {
  display: none;
  opacity: 0.35; }

.input-p {
  width: 100%;
  border: 1px solid #c2c7cb;
  padding: 4px 8px !important;
  margin: 10px 0;
  font-size: .875rem;
  line-height: 1;
  color: #a3abb0;
  box-sizing: border-box;
  font-family: "Open Sans";
  display: inline-block;
  white-space: pre-line; }

ul.social {
  list-style: none; }

ul.social li {
  display: inline; }

ul.children {
  margin: 2rem 0 0 2.0625rem; }

ul.children li {
  box-sizing: border-box; }

.comments ul.root li ul.children .msg .text {
  margin-bottom: 0; }

.complaintPopover {
  padding: 10px; }

.complaintPopover h3 {
  margin-bottom: 10px; }

.complaintPopover textarea {
  margin-top: 10px;
  height: 60px;
  padding: 4px 8px;
  line-height: 1;
  border: 1px solid #c2c7cb;
  width: 100%;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.complaintPopover input[type="submit"] {
  z-index: 9999;
  position: relative;
  margin: 5px 0;
  border: 0;
  background: #65c888;
  color: #fff;
  font-family: "Open Sans"; }

.complaintPopover input[type="submit"]:hover {
  background: #65c888; }

.was-edit {
  color: #a3abb0;
  margin-left: 6px; }

.number.negative {
  color: #f2564d;
  font-weight: normal; }

.number {
  color: #28c75d;
  font-weight: normal; }

.comment-date, .user-status {
  color: #a3abb0;
  margin-left: .5rem;
  font-size: .875rem; }

.user-status a {
  color: #a3abb0; }

.user-status a:hover {
  color: #757575; }

.comment-date:active,
.comment-date:hover,
.comment-date:focus {
  color: #757575; }

/*
*************
*** FORUM ***
*************
*/
.forum {
  position: relative;
  display: block;
  margin: 0 0 2.5rem; }

.forum-stats {
  margin: 0;
  list-style: none; }

.forum-stats li:first-of-type {
  margin-bottom: .5rem; }

.forum td, .forum-topics td {
  vertical-align: top;
  padding: 1rem;
  color: #a3abb0; }

.forum th, .forum-topics th {
  padding: 1rem; }

.forum .text p, .forum-topics .text p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1.5rem 0; }

.forum .text ul {
  font-size: 1rem;
  line-height: 1.5rem;
  list-style: none;
  margin: 0; }

.forum .text li {
  line-height: 1.5rem; }

.forum .text strong {
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin: 0 0 1.375rem;
  font-weight: 400; }

.forum td:first-of-type {
  text-align: left;
  width: 55%;
  box-sizing: border-box; }

.forum td:last-of-type, .forum-topics td:last-of-type {
  text-align: left;
  width: 25%;
  line-height: 1.5rem; }

.forum-topics .text strong {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 0 0 1.625rem;
  font-weight: 400; }

.forum-topics td:nth-of-type(2) {
  text-align: left;
  box-sizing: border-box;
  width: 55%; }

.forum-topics td:last-of-type {
  text-align: left;
  width: 25%; }

.forum-topics td {
  vertical-align: top; }

.forum-topics .info {
  color: #a3abb0; }

.forum-topics .info a {
  color: #a3abb0; }

.forum-topics .info a:hover {
  color: #757575; }

.forum-topics {
  margin: 0 0 2.5rem; }

.highlight td {
  background: #fffde7; }

.forum-filter {
  display: block;
  list-style: none;
  margin: 0 0 2.5rem;
  color: #a3abb0; }

.forum-filter li {
  display: inline; }

.forum-filter a {
  display: inline-block;
  color: #a3abb0;
  font-size: .875rem;
  padding: .5rem;
  border: 1px solid #e0e3e5; }

.user-name {
  text-transform: capitalize; }

.forum-avatar {
  max-width: 2.5rem; }

.add-topic {
  margin: .5rem 0 0 .75rem; }

.add-topic a {
  background: #65c888;
  padding: .5625rem 1rem !important;
  border: 0;
  font-weight: 400;
  color: #fff; }

.add-topic a:hover {
  background: #65c888; }

.edit-topic {
  margin: 10px 0 30px;
  right: 0;
  top: 0;
  position: absolute; }

.edit-topic a {
  padding: 2px 4px;
  color: #fff;
  background: #65c888; }

.edit-topic a:hover {
  background: #65c888; }

.topic-finish {
  color: #f2564d; }

.topic-highlight {
  color: #8a6d3b; }

.form-group {
  width: 100%; }

.form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600; }

.form-group textarea {
  width: 100%;
  min-height: 90px;
  border: 1px solid #c2c7cb;
  padding: 4px 8px;
  margin: 10px 0;
  font-size: .875rem;
  line-height: 1;
  color: #a3abb0;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.form-group input {
  width: 100%;
  border: 1px solid #c2c7cb;
  padding: 4px 8px;
  margin: 10px 0;
  font-size: .875rem;
  line-height: 1;
  color: #a3abb0;
  height: 29px !important;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.form-group select {
  display: block;
  border: 1px solid #e0e3e5;
  padding: 4px 8px;
  margin: 10px 0;
  font-size: .875rem;
  line-height: 1;
  color: #a3abb0;
  box-sizing: border-box;
  font-family: "Open Sans"; }

.form-submit input {
  position: relative;
  margin: 5px 0;
  border: 0;
  background: #65c888;
  color: #fff;
  font-family: "Open Sans"; }

.form-submit input:hover {
  background: #65c888; }

/*
***************
*** PROFILE ***
***************
*/
.user {
  width: 100%; }

.user h2 {
  margin: 17px 0; }

.user h2:first-of-type {
  margin: 0 0 17px; }

.user h3 {
  margin: 17px 0; }

.user ul {
  margin: 0;
  list-style: none; }

.user ul ul {
  margin: 10px 0 10px 25px;
  list-style: initial; }

.user .left {
  width: 250px;
  float: left;
  margin: 0 30px 0 0;
  position: relative; }

.user .date {
  font-size: .875rem;
  color: #a3abb0;
  padding: 5px 5px 0;
  box-sizing: border-box;
  margin: 0 0 1rem; }

.user .date .online {
  font-size: 12px;
  color: #f2564d;
  font-weight: 600;
  padding: 5px 5px 0;
  box-sizing: border-box;
  margin: 0 0 1rem; }

.user .right {
  width: 430px;
  float: right; }

.user .status {
  width: 100%;
  margin: 20px 0 5px;
  padding: 2px 5px 3px;
  text-align: center;
  font-size: .875rem;
  box-sizing: border-box;
  background: #65c888;
  color: #fff; }

.user .status:after {
  border: solid;
  border-color: #28c75d transparent;
  border-width: 0 6px 6px 6px;
  content: "";
  top: 214px;
  left: 96px;
  position: absolute;
  z-index: 99; }

.msg {
  /*word-break:break-all;*/ }

.user .note {
  margin: 17px 0; }

.user .note input {
  border: 1px solid #c2c7cb;
  background: #fff;
  cursor: pointer;
  font-size: .875rem;
  line-height: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 8px;
  font-family: "Open Sans"; }

/* REG & AUTH */
.password {
  display: block;
  margin-top: 2rem; }

.access p {
  margin: 0 0 2rem; }

.access label {
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  font-size: 1rem;
  line-height: 1; }

.access input {
  border: 1px solid #c2c7cb;
  margin: 0 .25rem 0 0;
  font-weight: 300;
  font-family: "Open Sans";
  height: 30px;
  width: 10rem;
  padding: .25rem 1rem;
  color: #20303c; }

.access .helptext {
  display: block;
  text-transform: none;
  padding: 0 0 1rem;
  margin-top: -.5rem;
  font-weight: 300;
  color: #a3abb0;
  font-size: .875rem;
  line-height: 1.375rem; }

.access input[type="submit"] {
  background: #65c888;
  color: #fff;
  border: 0;
  padding: .5rem 1rem;
  font-size: 1.125rem;
  vertical-align: top;
  margin: 0 .75rem 0 0;
  font-weight: 400;
  height: 2.5rem;
  width: auto; }

.access input[type="submit"]:hover {
  background: #65c888; }

.errorlist {
  color: #f2564d; }

.recaptchatable input {
  border: 1px solid #e0e3e5 !important;
  width: 10rem !important; }

.access .recaptchatable {
  border: 1px solid #eff3f6 !important; }

.access-info {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0; }

/* USER SEARCH */
.search-users {
  width: 100%; }

.search-users .left {
  background-color: #eff1f2;
  padding: 10px;
  width: 200px;
  float: left;
  margin: 0;
  position: relative; }

.search-users .right {
  box-sizing: border-box;
  width: 470px;
  float: right; }

.search-users .search-item {
  padding-bottom: 10px; }

.search-users .search-item[disabled] {
  opacity: 0.4;
  pointer-events: none; }

.search-selected {
  padding-bottom: 10px; }

.search-selected span {
  display: inline-block;
  width: 100%; }

.user-result {
  list-style: none;
  margin: 0; }

.user-result .user-result-item p {
  display: inline-block;
  position: relative; }

.select-multiply {
  width: 250px;
  padding: 10px;
  height: 350px;
  overflow: auto; }

.select-multiply input {
  padding: 4px 8px;
  border: 1px solid #c2c7cb;
  box-sizing: border-box;
  font-family: "Open Sans";
  color: #a3abb0;
  line-height: 1;
  margin: -3px 3px 0; }

.select-multiply .search {
  width: 100%;
  margin: 0; }

.select-multiply ul {
  margin: 10px 0 0 !important;
  padding: 0 !important; }

.select-multiply2 input {
  padding: 4px 8px;
  border: 1px solid #c2c7cb;
  box-sizing: border-box;
  font-family: "Open Sans";
  color: #a3abb0;
  line-height: 1;
  margin: -5px 5px 0 0; }

.wdd_mark {
  box-sizing: content-box;
  width: 6px;
  height: 6px;
  border: 1px solid #e0e3e5;
  background: #8FA9C7;
  position: absolute !important;
  bottom: 1px;
  right: 1px; }

.deactive {
  color: #e0e3e5 !important;
  cursor: default; }

.dropdown-menu .fa {
  margin-right: .5rem; }

.table-rating-pamm {
  cursor: ew-resize; }

.pamm {
  background: #eff1f2;
  padding: 20px 1rem;
  margin: 0 0 25px; }

.pamm .input {
  padding: 4px 8px;
  border: 1px solid #e0e3e5;
  box-sizing: border-box;
  font-family: "Open Sans";
  color: #a3abb0;
  margin: 0 2px;
  width: 150px; }

.pamm .sources {
  background: #fff;
  padding: 10px 5px 10px 1rem;
  margin: 0 0 20px;
  display: inline-block; }

.pamm .sources ul {
  list-style: none;
  margin: 0; }

.pamm .sources li {
  float: left;
  padding-right: 10px; }

.pamm .btn {
  background: #65c888;
  color: #fff;
  border: 0;
  padding: 5px 10px;
  font-size: .875rem;
  vertical-align: top;
  margin: 0 5px 0 0; }

.pamm .btn:hover, .pamm .btn:active, .pamm .btn:focus {
  background: #65c888; }

.pamm .row {
  padding: 10px 0; }

details {
  margin: 0; }

summary {
  cursor: pointer;
  font-size: .875rem;
  color: #a3abb0; }

summary::-webkit-details-marker {
  display: none; }

summary:focus {
  outline: 0; }

.ns-popover-tooltip-theme .ns-popover-tooltip {
  box-shadow: none !important;
  border: 1px solid #e0e3e5 !important;
  margin: .5rem 0 0;
  font-size: 1rem;
  padding: 0;
  width: 250px;
  height: 350px;
  overflow: auto; }

.ns-popover-tooltip-theme .ns-popover-tooltip ul {
  padding: 0;
  margin: .75rem 0; }

.ns-popover-tooltip-theme .ns-popover-tooltip li {
  line-height: 1.25rem; }

.ns-popover-tooltip-theme .ns-popover-tooltip li a {
  padding: .25rem 1rem;
  display: block; }

.ns-popover-tooltip-theme .ns-popover-tooltip li a:hover {
  background-color: #fff; }

.ns-popover-list-theme li a:hover,
.ns-popover-list-theme .list-item a:hover {
  background-color: #fff;
  border-radius: 0; }

.ns-popover-list-theme ul, .ns-popover-list-theme .list {
  padding: .75rem 0 !important;
  margin: 0;
  display: block; }

.ns-popover-list-theme li a, .ns-popover-list-theme .list-item a {
  padding: .25rem 1rem !important;
  margin: 0 0 0 0 !important;
  display: block; }

.ns-popover-list-theme li.active {
  font-weight: normal; }

.ns-popover-list-theme li.active a {
  color: #a3abb0; }

.ns-popover-list-theme {
  box-sizing: border-box !important;
  border: 1px solid #e0e3e5 !important;
  z-index: 1001 !important;
  background-color: #fff;
  box-shadow: 0.15rem 0.15rem 0 0 #eff1f2; }
  .ns-popover-list-theme .business__popup-all a {
    padding: .25rem 0 .75rem !important; }

.selectKind li {
  list-style-type: none;
  margin-bottom: .5rem; }

.selectKind li a {
  padding: 0 1rem !important;
  line-height: 1.25rem;
  text-transform: none;
  margin: .5rem 0 0 -1rem !important; }

.table-compare td:first-of-type {
  font-weight: 600;
  width: 15rem; }

.table-compare .btn {
  display: inline-block;
  width: 100%;
  box-sizing: border-box; }

/*
*************
*** OTHER ***
*************
*/
.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0; }

.no-border tr:last-of-type td {
  border-bottom: 0; }

.no-radius {
  border-radius: 0; }

.no-height {
  margin: 0 0 -.375rem; }

.underline {
  border-bottom: none !important; }
  .underline span {
    border-bottom: 1px solid #e0e3e5; }

.positive {
  color: #28c75d; }

.negative {
  color: #f2564d; }

.bbf-book {
  display: inline-block;
  border: 0;
  box-sizing: border-box;
  text-align: center;
  font-family: "Open Sans";
  cursor: pointer;
  background: #65c888;
  color: #fff !important;
  border-bottom: 0 !important;
  padding: .5rem 1rem;
  font-size: 1.125rem; }

#chart-container {
  margin: 0;
  padding: 2rem;
  border: 1px solid #e0e3e5; }

/* Font awesome */
.fa_right {
  margin-right: .5rem; }
  .fa_right_small {
    margin-right: .25rem; }

.fa_left {
  margin-left: .5rem; }
  .fa_left_small {
    margin-left: .25rem; }
  .fa_left_medium {
    margin-left: .75rem; }

.fa_both {
  margin: 0 .75rem 0 .5rem; }
  .fa_both_large {
    margin: 0 .5rem; }
  .fa_both_number {
    margin: 0 .25rem; }

/* CHECKBOX & RADIO */
/* Cначала обозначаем стили для IE8 и более старых версий
т.е. здесь мы немного облагораживаем стандартный чекбокс. */
.checkbox {
  vertical-align: top;
  margin: 0;
  width: 1rem;
  height: 1rem; }

/* Это для всех браузеров, кроме совсем старых, которые не поддерживают
селекторы с плюсом. Показываем, что label кликабелен. */
.checkbox + label {
  cursor: pointer; }

/* Далее идет оформление чекбокса в современных браузерах, а также IE9 и выше.
Благодаря тому, что старые браузеры не поддерживают селекторы :not и :checked,
в них все нижеследующие стили не сработают. */
/* Прячем оригинальный чекбокс. */
.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
  outline: 0; }

.checkbox:not(checked) + label {
  position: relative;
  /* будем позиционировать псевдочекбокс относительно label */
  padding: 0 0 0 1.5rem;
  /* оставляем слева от label место под псевдочекбокс */
  display: block; }

.checkbox_small:not(checked) + label {
  padding: 0 0 0 1.375rem !important; }

/* Оформление первой части чекбокса в выключенном состоянии (фон). */
.checkbox:not(checked) + label:before {
  position: absolute;
  left: 0;
  content: '\f0c8';
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.checkbox_small:not(checked) + label:before {
  width: .875rem !important;
  height: .875rem !important;
  top: 0; }

/* Оформление второй части чекбокса в выключенном состоянии (переключатель). */
.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.checkbox_small:not(checked) + label:after {
  width: .875rem !important;
  height: .875rem !important;
  top: 0; }

/* Меняем фон чекбокса, когда он включен. */
/* Сдвигаем переключатель чекбокса, когда он включен. */
.checkbox:checked + label:after {
  content: '\f14a'; }

/* Показываем получение фокуса. */
.radio {
  vertical-align: top;
  width: 1rem;
  height: 1rem;
  margin: 0 5px 0 0; }

.radio + label {
  cursor: pointer; }

.radio:not(checked) {
  position: absolute;
  opacity: 0; }

.radio:not(checked) + label {
  position: relative;
  padding: 0 0 0 1.5rem;
  display: block; }

.radio:not(checked) + label:before {
  position: absolute;
  margin-top: -.25rem;
  left: 0;
  content: '\f111';
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.radio:not(checked) + label:after {
  position: absolute;
  margin-top: -.25rem;
  left: 0;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.radio:not(checked) + label:hover:after {
  background: none; }

.radio:checked + label:hover:after, .radio:checked + label:after {
  content: '\f058'; }

th.header.headerSortDown:after {
  content: '\f33d';
  display: inline-block;
  margin-left: .5rem;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

th.header.headerSortUp:after {
  content: '\f341';
  display: inline-block;
  margin-left: .5rem;
  font-family: 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.words__results-form {
  background: #eff1f2;
  padding: 2rem; }
  .words__results-form_content {
    border: 1px solid #c2c7cb;
    display: flex;
    box-sizing: border-box;
    height: 2.5rem; }
  .words__results-form input {
    border: none;
    font-family: "Open Sans";
    width: 100%;
    box-sizing: border-box;
    height: 2.375rem;
    padding: .25rem 2.5rem .25rem 1rem;
    color: #43515c;
    font-size: .875rem; }
  .words__results-form_tips {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    margin: .5rem 0 0;
    color: #a3abb0; }
    .words__results-form_tips a {
      border-bottom: 1px solid #c2c7cb; }
  .words__results-form_tip {
    font-size: .875rem;
    line-height: 1.375rem; }
    .words__results-form_tip_mask {
      flex-basis: 33%; }
    .words__results-form_tip_def {
      flex-basis: 33%; }
    .words__results-form_tip_img {
      flex-basis: 33%; }
      .words__results-form_tip_img input {
        width: auto !important; }

.words__results-input_mask {
  flex-basis: 35%;
  border-right: 1px solid #e0e3e5 !important; }

.words__results-input_def {
  flex-basis: calc(70% - 45px); }

.words__results-btn {
  font-size: 1rem;
  padding: .6875rem 1rem;
  color: #858f96 !important;
  border: none;
  background: #fff;
  margin: 0; }
  .words__results-btn:hover {
    cursor: pointer; }
  .words__results-btn:focus {
    outline: none; }

.words__results-content_title {
  font-size: 1rem;
  margin: 2.5rem 0;
  background: #eff1f2;
  padding: 1rem; }

.words__results-info {
  color: #a3abb0;
  margin: .5rem 0 2.5rem;
  font-size: .875rem;
  line-height: 1.375rem; }

.words__result {
  margin: 0 0 2.5rem;
  padding: 0 0 2.5rem;
  border-bottom: 1px solid #e0e3e5;
  position: relative; }
  .words__result_bottom {
    margin: 0;
    padding: 0;
    border-bottom: none; }
  .words__result-title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 1.5rem;
    display: inline-block;
    font-weight: 600; }
    .words__result-title a {
      color: #20303c; }
    .words__result-title_top {
      margin-top: 2rem; }
    .words__result-title_capitalize {
      text-transform: capitalize; }
    .words__result-title_moderation {
      font-weight: 400;
      font-size: .875rem;
      color: #eff1f2 !important;
      position: absolute;
      right: 0;
      top: 0; }
  .words__result-content_list {
    margin: 0 0 0 2rem; }
    .words__result-content_list li {
      margin: 0 0 0.5rem;
      line-height: 1.5rem; }
      .words__result-content_list li:last-of-type {
        margin: 0; }
      .words__result-content_list li a {
        border-bottom: 1px solid #e0e3e5; }
  .words__result-content_error {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 2.5rem 0 0; }
    .words__result-content_error a {
      border-bottom: 1px solid #e0e3e5; }
  .words__result-images {
    margin: 2rem 0 0;
    line-height: 0; }
    .words__result-images_title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 .5rem;
      display: inline-block;
      font-weight: 600; }
    .words__result-images_list {
      margin: 0 0 0 1rem; }
      .words__result-images_list_left {
        margin: -1rem 0 0 2rem; }
    .words__result-images_item {
      margin: 1rem .75rem 0 0; }

.words__content {
  margin: 2.5rem 0 0;
  display: flex;
  justify-content: space-between; }
  .words__content-block {
    width: calc(33.333% - 10px);
    max-width: calc(33.333% - 10px); }
    .words__content-block_title {
      color: #20303c;
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 1rem !important;
      display: block;
      font-weight: 600; }
    .words__content-block_list {
      list-style-type: none;
      padding: 0;
      margin: 0 !important; }
      .words__content-block_list li {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 0 .5rem; }
        .words__content-block_list li:last-of-type {
          margin: 0; }
      .words__content-block_list a {
        border-bottom: 1px solid #e0e3e5; }

.content__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 2.5rem; }
  .content__container.content__container_masonry {
    max-height: 3400px;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(100% + 32px); }
    .content__container.content__container_masonry .content__col {
      width: calc(50% - 32px); }
      .content__container.content__container_masonry .content__col:first-of-type, .content__container.content__container_masonry .content__col:nth-child(2) {
        margin: 2.5rem 0 0; }
  .content__container_bottom {
    margin-bottom: 0; }

.content__col {
  box-sizing: border-box;
  width: calc(50% - 16px);
  margin: 2.5rem 0 0; }
  .content__col:first-of-type, .content__col:nth-child(2) {
    margin: 0; }

.content__index {
  margin: 2.5rem 0 0;
  padding: 2rem;
  border: 1px solid #c2c7cb; }
  .content__index a {
    border-bottom: 1px solid #e0e3e5; }
  .content__index_title {
    font-size: 1.375rem;
    line-height: 1.875rem;
    margin: 0 0 1.375rem; }
  .content__index_text {
    line-height: 1.375;
    font-size: .875rem;
    color: #a3abb0;
    border-top: 1px solid #c2c7cb;
    padding-top: 1rem;
    margin-top: 2rem; }
    .content__index_text .content__index_tags a {
      color: #a3abb0; }
  .content__index_list {
    margin: 0;
    list-style: none; }
    .content__index_list li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: .5rem 0; }
      .content__index_list li i {
        color: #a3abb0; }
  .content__index_date {
    color: #a3abb0;
    text-transform: lowercase;
    white-space: nowrap; }
  .content__index_tags {
    list-style-type: none;
    margin: 0;
    color: #a3abb0;
    line-height: 1.375rem;
    font-size: .875rem;
    text-transform: lowercase; }
    .content__index_tags li {
      display: inline-block; }
    .content__index_tags_top {
      margin-top: .75rem; }
    .content__index_tags_large {
      line-height: 1.5rem;
      font-size: 1rem; }
    .content__index_tags_mag {
      line-height: 1.5rem;
      font-size: 1rem; }
      .content__index_tags_mag:first-of-type {
        margin-bottom: 1rem; }
        .content__index_tags_mag:first-of-type a {
          border-bottom: 1px solid #e0e3e5; }
      .content__index_tags_mag .tags {
        display: initial; }
        .content__index_tags_mag .tags li a {
          line-height: 1.5;
          font-size: 1rem;
          display: initial;
          border-bottom: 1px solid #e0e3e5 !important; }
    .content__index_tags .tags {
      margin: 0; }
      .content__index_tags .tags li {
        display: inline-block;
        line-height: 1.5; }
        .content__index_tags .tags li a {
          padding: 0;
          border: none;
          color: #65c888; }
  .content__index-sentence_body p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 1rem; }
  .content__index-sentence_body ul, .content__index-sentence_body ol {
    margin: 0 0 1rem 2rem; }
  .content__index-sentence_body li {
    line-height: 1.5rem;
    margin-bottom: .5rem; }
    .content__index-sentence_body li:last-of-type {
      margin-bottom: 0; }
  .content__index-sentence_body_answer {
    margin: 1rem 0 0; }
  .content__index-sentence_answer {
    color: #a3abb0 !important;
    font-size: .875rem;
    border-bottom: none !important; }
    .content__index-sentence_answer span {
      border-bottom: 1px solid #e0e3e5 !important;
      color: #65c888; }

.share {
  position: fixed;
  display: none;
  top: 5rem;
  width: 2.5rem;
  margin: 0 0 0 -5.5rem; }
  .share li {
    margin: 0 0 1rem 0 !important; }
  .share .ya-share2__container_size_m .ya-share2__icon {
    height: 1.5rem;
    width: 2rem;
    padding: .5rem;
    background: rgba(239, 241, 242, 0.5) !important; }

.ya-share2__item_service_vkontakte .ya-share2__icon {
  background: none !important; }

.ya-share2__item_service_pinterest .ya-share2__icon {
  background: none !important; }

.ya-share2__item_service_facebook .ya-share2__icon {
  background: none !important; }

.ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background: none !important; }

.ya-share2__item_service_vkontakte .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center; }

.ya-share2__item_service_pinterest .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center; }

.ya-share2__item_service_facebook .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center; }

.ya-share2__item_service_odnoklassniki .ya-share2__badge {
  background-color: transparent !important;
  text-align: -webkit-center; }

.ya-share2__item_service_vkontakte .ya-share2__icon::after {
  content: "\f189";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: #20303c; }

.ya-share2__item_service_pinterest .ya-share2__icon::after {
  content: "\f231";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: #20303c; }

.ya-share2__item_service_facebook .ya-share2__icon::after {
  content: "\f39e";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: #20303c; }

.ya-share2__item_service_odnoklassniki .ya-share2__icon::after {
  content: "\f263";
  font-family: "Font Awesome 5 Brands";
  font-size: 1.5rem;
  color: #20303c; }

.sudoku__form {
  position: relative; }
  .sudoku__form-table_wrap {
    position: relative;
    margin: 0 0 2rem; }
    .sudoku__form-table_wrap table.sudoku__table {
      margin: 0; }
  .sudoku__form loader {
    display: none; }
  .sudoku__form.paused loader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 15;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
    .sudoku__form.paused loader .holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(55, 91, 116, 0.9); }
    .sudoku__form.paused loader .play {
      position: absolute;
      z-index: 999;
      color: #fff;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }

table.sudoku__table {
  width: 506px;
  border: none !important;
  font-size: 2rem;
  font-weight: 300;
  color: #43515c;
  background: #fff;
  margin: 0 0 2rem; }
  table.sudoku__table tr:hover > td:nth-child(odd),
  table.sudoku__table tr:hover > td:nth-child(even) {
    background: #fff !important; }
  table.sudoku__table tr {
    padding: 0;
    border: none !important; }
    table.sudoku__table tr.header-number td {
      border: none !important;
      text-align: center;
      padding: 1rem 0; }
      table.sudoku__table tr.header-number td:last-of-type {
        border: none !important; }
    table.sudoku__table tr:last-of-type td, table.sudoku__table tr:hover td {
      border-bottom: none !important; }
    table.sudoku__table tr.third {
      border-top: 2px solid #858f96 !important; }
    table.sudoku__table tr:last-of-type {
      border-bottom: 2px solid #858f96 !important; }
    table.sudoku__table tr td {
      background: none;
      width: 11.1111%;
      padding: 0;
      padding-bottom: 11.1111%;
      height: 0;
      position: relative;
      border: 1px solid #a3abb0 !important; }
      table.sudoku__table tr td:hover {
        background: none; }
      table.sudoku__table tr td.third {
        border-left: 2px solid #858f96 !important; }
      table.sudoku__table tr td.tr_error sudoku-cell.highlight_number .has_initial, table.sudoku__table tr td.td_error sudoku-cell.highlight_number .has_initial {
        background: #f7cfd6 !important; }
      table.sudoku__table tr td.tr_error.td_error sudoku-cell.highlight_number .has_initial {
        background: #f7cfd6 !important; }
      table.sudoku__table tr td:last-of-type {
        border-right: 2px solid #858f96 !important; }
      table.sudoku__table tr td sudoku-cell {
        position: absolute;
        width: 100%;
        height: 100%; }

.disabled, .disabled a {
  cursor: not-allowed;
  color: #c2c7cb !important; }
  .disabled a, .disabled a a {
    color: currentColor;
    display: inline-block;
    pointer-events: none;
    text-decoration: none; }

sudoku-cell {
  text-align: center; }
  sudoku-cell:focus, sudoku-cell.focus {
    outline: none; }
  sudoku-cell.highlight_number:focus, sudoku-cell.highlight_number.focus {
    background: #c9f1d7 !important;
    outline: none; }
  sudoku-cell.highlight_number .has_initial {
    background: #fef5e9 !important; }
  sudoku-cell.highlight_number.focus .has_initial {
    background: #c9f1d7 !important; }
  sudoku-cell.highlight_rules {
    background: rgba(239, 241, 242, 0.5); }
  sudoku-cell.highlight_rules2 {
    background: rgba(239, 241, 242, 0.5); }
  sudoku-cell:hover {
    background: #fef5e9;
    cursor: pointer; }
  sudoku-cell.editable {
    color: #28c75d; }
  sudoku-cell .has_initial {
    width: 100%;
    height: 100%; }
  sudoku-cell .cell-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  sudoku-cell .note-grid {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    line-height: 0; }
  sudoku-cell .note-grid-cell {
    position: relative;
    width: 33.3333%;
    height: 33.3333%;
    font-size: .75rem;
    line-height: 1.15rem;
    color: #f27052;
    display: inline-block;
    visibility: hidden; }
    sudoku-cell .note-grid-cell.show {
      visibility: visible; }
  sudoku-cell.show {
    visibility: visible; }

.print-actions li {
  display: block; }

.print-wrapper {
  display: none; }

.selectLevel, .selectOptions {
  width: 200px; }

.ngdialog-theme-default .ngdialog-overlay {
  background: rgba(55, 91, 116, 0.9); }

.ngdialog-theme-default .ngdialog-content {
  background: none !important; }

.ngdialog-theme-default.ngdialog-congratulations {
  padding-top: 200px; }
  .ngdialog-theme-default.ngdialog-congratulations #message {
    text-align: center;
    font-size: 24px;
    color: white; }
  .ngdialog-theme-default.ngdialog-congratulations .congratulations__message {
    position: relative;
    z-index: 15; }
    .ngdialog-theme-default.ngdialog-congratulations .congratulations__message-title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 2rem; }
    .ngdialog-theme-default.ngdialog-congratulations .congratulations__message-text {
      font-size: 1.125rem;
      line-height: 1.625rem;
      color: rgba(255, 255, 255, 0.5); }
    .ngdialog-theme-default.ngdialog-congratulations .congratulations__message-new {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin: 1.125rem 0 0; }
      .ngdialog-theme-default.ngdialog-congratulations .congratulations__message-new a {
        color: #fff; }
  .ngdialog-theme-default.ngdialog-congratulations #fireworks-canvas {
    width: 800px;
    height: 766px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0); }

.ngdialog-theme-default.ngdialog-setcell .ngdialog-content {
  width: 400px; }

.ngdialog-theme-default.ngdialog-setcell li {
  background: white;
  padding: 4px;
  margin: 2px; }

.sudoku {
  margin-bottom: 2.5rem;
  padding: 2rem;
  line-height: 1.5rem;
  border: 1px solid #c2c7cb; }
  .sudoku_bottom {
    padding: 2rem 2rem 0; }
  .sudoku li {
    display: inline-block; }
  .sudoku__btn {
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    font-size: 1rem;
    color: #65c888;
    background: transparent;
    cursor: pointer; }
    .sudoku__btn span {
      border-bottom: 1px solid #e0e3e5; }
  .sudoku button.sudoku__btn {
    display: inline-flex;
    line-height: 1.375rem; }
    .sudoku button.sudoku__btn i {
      line-height: 1.375rem; }
  .sudoku__content-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 1.25rem; }
    .sudoku__content-top_solver {
      margin: 0 0 2rem; }
      .sudoku__content-top_solver .sudoku__content-controls a {
        display: inline-flex;
        line-height: 1.375rem; }
        .sudoku__content-top_solver .sudoku__content-controls a i {
          line-height: 1.375rem; }
    .sudoku__content-top .sudoku__content-controls {
      margin: 0; }
    .sudoku__content-top a span {
      border-bottom: 1px solid #e0e3e5; }
    .sudoku__content-top_right {
      margin-top: 1px; }
  .sudoku__content-new .sudoku__btn {
    border-bottom: .75rem solid transparent; }
  .sudoku__content-controls {
    list-style-type: none;
    margin: 0 0 1rem; }
    .sudoku__content-controls li {
      display: block; }
      .sudoku__content-controls li .btn {
        margin: 0 0 0.5rem; }
      .sudoku__content-controls li::last-of-type .btn {
        margin: 0; }
  .sudoku__content ul.sudoku__content-controls {
    display: flex; }
    .sudoku__content ul.sudoku__content-controls li {
      margin: 0 1rem 0 0; }
      .sudoku__content ul.sudoku__content-controls li:last-of-type {
        margin: 0; }
  .sudoku__form {
    position: relative; }
    .sudoku__form-top_timer {
      display: flex;
      justify-content: flex-end; }
      .sudoku__form-top_timer .sudoku__btn {
        background: transparent;
        border: none;
        border-bottom: .75rem solid transparent;
        padding: 0;
        line-height: 1.5rem;
        cursor: pointer; }
        .sudoku__form-top_timer .sudoku__btn:focus, .sudoku__form-top_timer .sudoku__btn:active {
          outline: none; }
    .sudoku__form-top .btn {
      background: #65c888;
      color: #fff; }
    .sudoku__form-table {
      display: flex; }
    .sudoku__form-controls {
      margin: 0 0 0 2rem; }
      .sudoku__form-controls_numbers {
        display: flex;
        justify-content: flex-end;
        font-weight: 300; }
      .sudoku__form-controls .btn {
        margin: 0 0 1rem; }
    .sudoku__form-title {
      display: block;
      border-bottom: 1px solid #c2c7cb;
      text-align: center;
      color: #66737c;
      line-height: 49px; }
    .sudoku__form-numbers {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      justify-content: space-between; }
      .sudoku__form-numbers li {
        width: calc(33.333% - 5px);
        margin: 0;
        padding: 0; }
        .sudoku__form-numbers li a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem; }
          .sudoku__form-numbers li a:hover {
            background: #fef5e9;
            cursor: pointer; }
      .sudoku__form-numbers_small {
        width: 50px;
        border: 1px solid #c2c7cb;
        background: #fff;
        flex-direction: column; }
        .sudoku__form-numbers_small:last-child {
          margin-left: 1rem; }
        .sudoku__form-numbers_small:first-child {
          margin-left: 0; }
        .sudoku__form-numbers_small li a {
          height: 49px;
          border: none;
          border-bottom: 1px solid #c2c7cb;
          color: #66737c; }
        .sudoku__form-numbers_small li {
          width: 100%;
          margin: 0; }
          .sudoku__form-numbers_small li:last-child a {
            border-bottom: none; }
    .sudoku__form-timer {
      display: flex;
      align-items: center;
      border-bottom: .75rem solid transparent; }
      .sudoku__form-timer a {
        color: #a3abb0; }
    .sudoku__form-actions {
      display: flex;
      align-items: center; }
      .sudoku__form-actions a {
        margin: 0 1rem 0 0; }
        .sudoku__form-actions a span {
          border-bottom: 1px solid #e0e3e5; }
    .sudoku__form-info {
      font-size: .875rem;
      color: #a3abb0;
      margin-top: 2rem;
      line-height: 1.375rem;
      border-top: 1px solid #e0e3e5;
      padding-top: 1rem; }
  .sudoku__timer {
    margin: 0 1rem 0 0;
    color: #a3abb0; }
  .sudoku__actions {
    display: flex;
    align-items: center; }
    .sudoku__actions a {
      margin: 0 5px 0 0; }
    .sudoku__actions-controls {
      display: flex;
      align-items: center; }
      .sudoku__actions-controls a {
        margin-right: 8px; }
  .sudoku__calendar {
    margin: 0; }
    .sudoku__calendar-control_left {
      margin: 0 0.75rem 0 0; }
    .sudoku__calendar-control span {
      border-bottom: 1px solid #e0e3e5; }

.ns-popover-list-theme ul.sudoku__settings li {
  padding: .25rem 1rem !important; }
  .ns-popover-list-theme ul.sudoku__settings li .checkbox:not(checked) + label:after {
    top: 0; }

.sudoku-list__archive {
  margin: 0;
  list-style: none; }
  .sudoku-list__archive li {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #e0e3e5;
    border-top: 0 !important;
    padding: 0 0 2.5rem;
    font-weight: 600; }
  .sudoku-list__archive a {
    color: #20303c; }

/*==========  Desktop First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box; }
  .nav__pills > li > a {
    font-size: 1rem;
    padding: 0 0 .475rem; }
  .header__search.search {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px; }
  .header__links {
    right: 10px; }
  .header__toggle {
    margin: 0 0 0 10px;
    cursor: pointer; }
    .header__toggle-line {
      display: block;
      height: 2px;
      width: 20px;
      background-color: rgba(0, 0, 0, 0.54);
      margin: 0 0 4px; }
      .header__toggle-line:last-of-type {
        margin: 0; }
  .header__logo {
    margin: 2.3rem 0 0;
    width: auto; }
    .header__logo img, .header__logo svg {
      height: 2.4rem; }
  .header__nav-sub a {
    font-size: 13px; }
  .content {
    padding-top: 2rem; }
    .content__block {
      width: calc(100% - 330px); }
      .content__block .article__figure {
        width: 100%; }
        .content__block .article__figure-image {
          width: 100%;
          height: auto; }
          .content__block .article__figure-image:before {
            max-width: 750px; }
  body.asidemenu-opened {
    overflow: hidden; }
  .asidemenu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -340px;
    z-index: 102;
    width: 320px;
    background-color: #fff;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    display: block; }
    .asidemenu__close {
      cursor: pointer; }
    .asidemenu.show-asidemenu {
      right: 0; }
      .asidemenu.show-asidemenu:before {
        opacity: 1;
        visibility: visible;
        pointer-events: all; }
    .asidemenu:before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.9);
      position: fixed;
      z-index: 3;
      user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: all;
      -webkit-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      pointer-events: none;
      visibility: hidden;
      opacity: 0; }
    .asidemenu__header {
      padding: 2rem;
      margin: 0 0 2rem;
      border-bottom: 1px solid #b2b2b2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff; }
      .asidemenu__header svg {
        max-width: 80px;
        height: auto; }
    .asidemenu__content {
      height: calc(100% - 140px);
      padding: 0 0 1rem;
      overflow-y: scroll; }
    .asidemenu__wrap {
      height: 100%;
      background: #fff;
      position: relative;
      z-index: 5;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.54); }
      .asidemenu__wrap ul {
        list-style-type: none; }
    .asidemenu__list {
      padding: 0 2rem;
      margin: 0;
      list-style-type: none; }
      .asidemenu__list li {
        margin: 0 0 30px; }
        .asidemenu__list li.has-child > a:after {
          content: "\f107";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1;
          font-weight: 900;
          font-size: 16px;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0%, -50%); }
        .asidemenu__list li.is-opened > a:after {
          transform: translate(0%, -50%) rotate(90deg); }
        .asidemenu__list li.is-opened > ul {
          display: block; }
      .asidemenu__list a {
        text-transform: uppercase;
        font-size: 12px;
        color: #000000;
        position: relative;
        display: block;
        font-weight: 600; }
      .asidemenu__list-inner {
        display: none;
        margin: 2rem 0 0; }
        .asidemenu__list-inner a {
          padding: 0 15px; }
  .featured__post {
    width: 50%; }
    .featured__post_large .featured__thumb {
      min-height: 100%;
      width: 100%; }
  .featured__list {
    width: 50%; }
  .featured__thumb {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .featured__thumb img {
      min-height: 100%;
      min-width: 100%; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .aside {
    display: none; }
  .content {
    padding-top: 2rem; }
    .content__block {
      width: 100%;
      float: none; }
  .featured {
    margin: 0 -15px 1rem; }
    .featured__list {
      display: none; }
    .featured__content {
      flex-direction: column; }
    .featured__thumb {
      max-height: 205px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .featured__thumb img {
        width: 100%; }
    .featured__post {
      width: 50%; }
      .featured__post img {
        width: 100%; }
      .featured__post .featured__thumb {
        width: 100%;
        height: auto;
        max-height: 205px; }
      .featured__post_large, .featured__post.feature__post_large {
        width: 100%; }
        .featured__post_large .featured__thumb, .featured__post.feature__post_large .featured__thumb {
          width: 100%;
          max-height: 420px; }
          .featured__post_large .featured__thumb img, .featured__post.feature__post_large .featured__thumb img {
            width: 100%; }
  .title {
    font-size: 1.6rem;
    line-height: 2rem; }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header__logo {
    margin: 0;
    height: auto;
    position: static; }
  .header__links {
    position: static; }
  .header__nav {
    margin: 0;
    position: static; }
    .header__nav-sub {
      display: none; }
  .header__currency {
    display: none; }
  .footer__container {
    flex-direction: column; }
  .footer__nav {
    margin: 0 0 15px; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .title {
    margin: 0 0 2rem; }
  .comments {
    width: 100%; }
  .header {
    height: 5rem !important; }
    .header__logo svg, .header__logo img {
      height: 2rem; }
    .header__nav-main {
      display: none; }
  .footer {
    padding: 1.5rem 0; }
    .footer__nav a {
      font-size: 0.8rem; }
    .footer__nav-list {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center; }
      .footer__nav-list li {
        margin: 0 1rem 0.5rem 0; }
    .footer__copy {
      font-size: 0.8rem; }
  .post {
    padding: 0 0 2rem; }
    .post__body img {
      width: 100%;
      height: auto; }
    .post__thumb {
      float: none;
      width: 100%;
      max-height: 240px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 2rem; }
    .post__tags {
      width: 100%;
      margin: 0 0 1rem; }
    .post__header {
      margin: 0;
      width: 100%; }
    .post__text {
      width: 100%;
      margin: 0; }
    .post__title {
      font-size: 1.25rem; }
  .featured__post_large .featured__post-content_title {
    font-size: 0.9rem;
    line-height: 1.3rem; }
  .featured__post-content_title {
    font-size: 0.8rem;
    line-height: 1.2rem; }
  .article__intro {
    font-size: 1rem; }
  .article p {
    font-size: 1rem;
    word-break: break-all; }
  .article li {
    font-size: 1rem; }
  .article h2 {
    font-size: 1.6rem;
    line-height: 2rem; }
  .article h3 {
    font-size: 1.3rem;
    margin: 1rem 0; }
  .article figcaption {
    font-size: 1rem;
    line-height: 1.5rem; }
  .article blockquote {
    font-size: 1rem !important;
    line-height: 1.625rem !important; }
  .article__figure {
    width: 100%; }
    .article__figure-image {
      width: 100%;
      height: auto; }
      .article__figure-image:before {
        max-width: 750px; }
  .text p {
    font-size: 1rem;
    word-break: break-all; }
  .text li {
    font-size: 1rem; }
  .text h2 {
    font-size: 1.6rem;
    line-height: 2rem; }
  .text h3 {
    font-size: 1.3rem;
    margin: 1rem 0; }
  .additional__title {
    font-size: 1.6rem;
    line-height: 2rem; }
  .test__figure-image {
    width: 100%;
    height: auto; }
    .test__figure-image:before {
      max-width: 750px; }
  .calc__content {
    padding: 1rem; }
  .converter__content {
    padding: 1rem; }
    .converter__content-list_items {
      display: block; }
      .converter__content-list_items .converter__content-group {
        width: 100%; }
  .translate {
    padding: 1rem; }
    .translate__settings {
      line-height: 1.2; }
  .sentence_answer {
    padding: 1rem 1rem 0.1rem; } }

/* Extra Small Devices, Phones */
/* Custom, iPhone Retina */
