.sudoku__form {
  position: relative;
  &-table_wrap {
    position: relative;
    margin: 0 0 2rem;
    table.sudoku__table {
      margin: 0;
    }
  }
  loader {
    display: none;
  }
  &.paused {
    loader {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;
      z-index: 15;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color-twenty-eight, .9);
      }
      .play {
        position: absolute;
        z-index: 999;
        color: $white;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

table.sudoku__table {
  width: 506px;
  border: none !important;
  font-size: 2rem;
  font-weight: 300;
  color: $color-twenty-three;
  background: $white;
  margin: 0 0 2rem;
  tr:hover > td:nth-child(odd), 
  tr:hover > td:nth-child(even) {
    background: $white-important;
  }
  tr {
    padding: 0;
    border: none !important;
    &.header-number {
      td {
        border: none !important;
        text-align: center;
        padding: 1rem 0;
        &:last-of-type {
          border: none !important;
        }
      }
    }
    &:last-of-type, &:hover {
      td {
        border-bottom: none !important;
      }
    }
    &.third {
      border-top: 2px solid $color-thirty-important;
    }
    &:last-of-type {
      border-bottom: 2px solid $color-thirty-important;
    }
    td {
      background: none;
      width: 11.1111%;
      padding: 0;
      padding-bottom: 11.1111%;
      height: 0;
      position: relative;
      border: 1px solid $color-three-important;
      &:hover {
        background: none;
      }
      &.third {
        border-left: 2px solid $color-thirty-important;
      }
      &.tr_error, &.td_error {
        sudoku-cell.highlight_number {
          .has_initial {
            background: $color-thirty-five-important; // ячейка с ошибкой
          }
        }
      }
      &.tr_error.td_error {
        sudoku-cell.highlight_number {
          .has_initial {
            background: $color-thirty-five-important; // ячейка с ошибкой
          }
        }
      }
      &:last-of-type {
        border-right: 2px solid $color-thirty-important;
      }
      sudoku-cell {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.disabled, .disabled a {
  cursor: not-allowed;
  color: $color-four-important;
  a {
    color: currentColor;
    display: inline-block;
    pointer-events: none;
    text-decoration: none;
  }
}

sudoku-cell {
  text-align: center;
  &:focus, &.focus {
    outline: none;
  }

  &.highlight_number {
    &:focus, &.focus {
      background: $color-thirty-four-important; // нажатая пустая ячейка
      outline: none;
    }
    .has_initial {
      background: $color-thirty-three-important; // ячейка с такой же цифрой
    }
    &.focus {
      .has_initial {
        background: $color-thirty-four-important; // нажатая ячейка с цифрой
      }
    }
  }
  &.highlight_rules {
    background: rgba($color-nine, .5);
  }
  &.highlight_rules2 {
    background: rgba($color-nine, .5);
  }
  &:hover {
    background: $color-thirty-three; // при наведении
    cursor: pointer;
  }
  &.editable {
    color: $color-twenty;
  }
  .has_initial {
    width: 100%;
    height: 100%;
  }
  .cell-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .note-grid {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    line-height: 0;
  }
  .note-grid-cell {
    position: relative;
    width: 33.3333%;
    height: 33.3333%;
    font-size: .75rem;
    line-height: 1.15rem;
    color: $color-two;
    display: inline-block;
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
  &.show {
    visibility: visible;
  }
}

.print-actions {
  li {
    display: block;
  }
}

.print-wrapper {
  display: none;
}
.selectLevel, .selectOptions {
  width: 200px;
}

.ngdialog-theme-default {
  .ngdialog-overlay {
    background: rgba($color-twenty-eight, .9);
  }

  .ngdialog-content {
    background: none !important;
  }
}

.ngdialog-theme-default.ngdialog-congratulations {
  padding-top: 200px;
  #message {
    text-align: center;
    font-size: 24px;
    color: white;
  }
  .congratulations__message {
    position: relative;
    z-index: 15;
    &-title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 2rem;
    }
    &-text {
      font-size: 1.125rem;
      line-height: 1.625rem;
      color: rgba($white, .5);
    }
    &-new {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin: 1.125rem 0 0;
      a {
        color: $white;
      }
    }
  }
  #fireworks-canvas {
    width: 800px;
    height: 766px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    //display: none;
    //z-index: 9999;
  }
}

.ngdialog-theme-default.ngdialog-setcell {
  .ngdialog-content {
    width: 400px;
  }
  li {
    background: white;
    padding: 4px;
    margin: 2px;
  }
}

.sudoku {
  margin-bottom: 2.5rem;
  padding: 2rem;
  line-height: 1.5rem;
  //background: $color-nine;
  border: 1px solid $color-ten;
  &_bottom {
    padding: 2rem 2rem 0;
  }
  li {
    display: inline-block;
  }
  &__btn {
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    font-size: 1rem;
    color: $color-one;
    background: transparent;
    cursor: pointer;
    span {
      border-bottom: $underline;
    } 
  }
  button.sudoku__btn {
    display: inline-flex;
    line-height: 1.375rem;
    i {
      line-height: 1.375rem;
    }
  }
  &__content {
    &-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 1.25rem;
      &_solver {
        margin: 0 0 2rem;
        .sudoku__content-controls {
          a {
            display: inline-flex;
            line-height: 1.375rem;
            i {
              line-height: 1.375rem;
            }
          }
        }
      }
      .sudoku__content-controls {
        margin: 0;
      }
      a span {
        border-bottom: $underline;
      }
      &_right {
        margin-top: 1px;
      }
    }
    &-new {
      // margin: 0 0 1.25rem;
      .sudoku__btn {
        border-bottom: .75rem solid transparent;
      }
    }
    &-controls {
      list-style-type: none;
      margin: 0 0 1rem;
      li {
        display: block;
        .btn {
          margin: 0 0 0.5rem;
        }
        &::last-of-type .btn {
          margin: 0;
        }
      }
    }
    ul.sudoku__content-controls {
      display: flex;
      li {
        margin: 0 1rem 0 0;
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
  &__form {
    // display: flex;
    position: relative;
    &-top {
      &_timer {
        display: flex;
        // margin-bottom: 1.25rem;
        justify-content: flex-end;
        .sudoku__btn {
          background: transparent;
          border: none;
          border-bottom: .75rem solid transparent;
          padding: 0;
          line-height: 1.5rem;
          cursor: pointer;
          &:focus, &:active {
            outline: none;
          }
        }
      }
      .btn {
        background: $color-one;
        color: $white;
      }
    }
    &-table {
      display: flex;
    }
    &-controls {
      margin: 0 0 0 2rem;
      &_numbers {
        display: flex;
        justify-content: flex-end;
        font-weight: 300;
      }
      .btn {
        margin: 0 0 1rem;
      }
    }
    &-title {
      display: block;
      border-bottom: 1px solid $color-four;
      text-align: center;
      color: $color-twenty-nine;
      line-height: 49px;
    }
    &-numbers {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      justify-content: space-between;
      li {
        width: calc(33.333% - 5px);
        margin: 0;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          &:hover {
            background: $color-thirty-three;
            cursor: pointer;
          }
        }
      }
      &_small {
        width: 50px;
        border: 1px solid $color-four;
        background: $white;
        flex-direction: column;
        &:last-child {
          margin-left: 1rem;
        }
        &:first-child {
          margin-left: 0;
        }
        li a {
          height: 49px;
          border: none;
          border-bottom: 1px solid $color-four;
          color: $color-twenty-nine;
        }
        li {
          width: 100%;
          margin: 0;
          &:last-child a {
            border-bottom: none;
          }
        }
      }
    }
    &-timer {
      display: flex;
      align-items: center;
      border-bottom: .75rem solid transparent;
      // line-height: 1;
      a {
        color: $color-three;
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      a {
        margin: 0 1rem 0 0;
        span {
          border-bottom: $underline;
        }
      }
    }
    &-info {
      font-size: .875rem;
      color: $color-three;
      margin-top: 2rem;
      line-height: 1.375rem;
      border-top: 1px solid $color-eight;
      padding-top: 1rem;
    }
  }
  &__timer {
    margin: 0 1rem 0 0;
    color: $color-three;
  }
  &__actions {
    display: flex;
    align-items: center;
    a {
      margin: 0 5px 0 0;
    }
    &-controls {
      display: flex;
      align-items: center;
      a {
        margin-right: 8px;
      }
    }
  }
  &__calendar {
    margin: 0;
    &-control {
      &_left {
        margin: 0 0.75rem 0 0;
      }
      span {
        border-bottom: $underline;
      }
    }
  }
}

.ns-popover-list-theme ul.sudoku__settings li {
  padding: .25rem 1rem !important;

  .checkbox:not(checked) + label:after {
    top: 0;
  }
}

.sudoku-list {
  &__archive {
    margin: 0;
    list-style: none;
    li {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid $color-eight;
      border-top: 0 !important;
      padding: 0 0 2.5rem;
      font-weight: 600;
    }

    a {
      color: $black;
    }
  }
}